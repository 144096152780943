(function () {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionUserInviteController', SessionUserInviteController);

    /* @ngInject */
    function SessionUserInviteController($scope, $state, System, User, triSettings, allowMultiSelect, filter, userslist, ParseLinks, AlertService, $mdDialog, Principal, Feature, isAdmin, isSession, isScheduledSession, isConversationSession, isSmartScan, paginationConstants, schedulerUserInfo, isSessionSingleUserInvite, autoCompleteModel, selectedItemIds) {
        var vm = this;

        vm.loadingData = false;
        vm.view = "USERS";
        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.removeFilter = removeFilter;
        vm.changeSort = changeSort;
        vm.allowMultiSelect = allowMultiSelect;
        vm.userInviteFilter = angular.isDefined(selectedItemIds) && !selectedItemIds.length ? [] : selectedItemIds;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = "";
        vm.page = 1;
        vm.reverse = true;
        vm.userFilter = userslist.length == 0 ? [] : userslist;
        vm.isSession = isSession;
        vm.isAdmin = isAdmin;
        vm.isConversationSession = isConversationSession;
        vm.isSmartScan = isSmartScan;
        vm.illustartionEmptyScreenId = "no_users_session";
        vm.isInviteByEmail = false;
        vm.isInviteByPhone = false;
        vm.isInviteByDeviceId = false;
        vm.email = '';
        vm.code = '';
        vm.phoneNumber = '';
        vm.deviceId = '';
        vm.guestUsers = [];
        vm.phoneNumberPattern = /\b\d{5,20}\b/;
        vm.deviceIdPattern = /[a-zA-Z0-9]{1,10}/;
        vm.hasError = false;
        vm.promise;
        vm.countryCodes = [];
        vm.selectedCountryCode = '';
        vm.defaultCountryCode = {};
        vm.isScheduledSession = isScheduledSession;
        vm.isValidating = false;
        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;
        vm.isGuestFeature = Feature.isActive('feature_airsession_invite_guest_user') && !isSessionSingleUserInvite;
        vm.isSmsFeatureEnabled = Feature.isActive('feature_airsession_enable_sms_invite');
        vm.isEmailFeatureEnabled = Feature.isActive('feature_airsession_enable_email_invite');
        vm.isEmailGuestEnabled = Feature.isActive('feature_airsession_enable_email_invite');
        vm.isSmsGuestEnabled = Feature.isActive('feature_airsession_enable_sms_invite');
        vm.totalItems = null;
        vm.isSessionSingleUserInvite = isSessionSingleUserInvite;
        vm.isGuestButtonVisible = true;
        // For multi-select it will be array and for single select it will be object
        vm.currentSelectedUsers = autoCompleteModel
            ? Array.isArray(autoCompleteModel)
                ? angular.copy(autoCompleteModel)
                : [autoCompleteModel]
            : [];

        vm.isAddButtonDisabled = function() {
            if(vm.isSessionSingleUserInvite) {
                return vm.currentSelectedUsers.length <= 0    
            }
            return vm.currentSelectedUsers.length <= 0 && !vm.userInviteFilter.length
        }

        vm.languages = [
            { name: 'english_us', displayName: 'English (US)', value: 'en_US', serverKey: 'en_US' },
            { name: 'english_uk', displayName: 'English (UK)', value: 'en_GB', serverKey: 'en_GB' },
            { name: 'french', displayName: 'French', value: 'fr_FR', serverKey: 'fr_FR' },
            { name: 'german', displayName: 'German', value: 'de_DE', serverKey: 'de_DE' },
            { name: 'greek', displayName: 'Greek', value: 'el_GR', serverKey: 'el_GR' },
            { name: 'italian', displayName: 'Italian', value: 'it_IT', serverKey: 'it_IT' },
            { name: 'japanese', displayName: 'Japanese', value: 'ja_JP', serverKey: 'ja_JP' },
            { name: 'portuguese', displayName: 'Portuguese', value: 'pt_BR', serverKey: 'pt_BR' },
            { name: 'spanish', displayName: 'Spanish', value: 'es_CL', serverKey: 'es_CL' },
            { name: 'chinese', displayName: 'Chinese (Traditional)', value: 'zh_TW', serverKey: 'zh_TW' },
            { name: 'chinese_cn', displayName: 'Chinese (Simplified)', value: 'zh_CN', serverKey: 'zh_CN' },
            { name: 'dutch', displayName: 'Dutch', value: 'nl_NL', serverKey: 'nl_NL' },
            { name: 'danish', displayName: 'Danish (Denmark)', value: 'da_DK', serverKey: 'da_DK' },
            { name: 'norwegian', displayName: 'Norwegian', value: 'no_NO', serverKey: 'no_NO' },
            { name: 'polish', displayName: 'Polish (Poland)', value: 'pl_PL', serverKey: 'pl_PL' },
            { name: 'russian', displayName: 'Russian', value: 'ru_RU', serverKey: 'ru_RU' },
            { name: 'swedish', displayName: 'Swedish (Sweden)', value: 'sv_SE', serverKey: 'sv_SE' },
            { name: 'turkish', displayName: 'Turkish (Turkey)', value: 'tr_TR', serverKey: 'tr_TR' }
        ];

        vm.languages.sort(function (a, b) {
            if (a['displayName'] > b['displayName']) {
                return 1;
            } else if (a['displayName'] < b['displayName']) {
                return -1;
            }
            return 0;
        });

        vm.selectedLanguage = "en_US";

        if (isScheduledSession) {
            vm.isGuestButtonVisible = vm.isEmailGuestEnabled || vm.isSmsGuestEnabled;
        }

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-first_name',
            page: 1
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500
            }
        };

        vm.handleInviteByEmail = function () {
            vm.isInviteByDeviceId = false;
            vm.isInviteByEmail = true;
            vm.isInviteByPhone = false;
            reset();
        };

        vm.handleInviteByPhone = function () {
            vm.isInviteByDeviceId = false;
            vm.isInviteByEmail = false;
            vm.isInviteByPhone = true;
            reset();
        };

        vm.handleInviteByDeviceId = function () {
            vm.isInviteByDeviceId = true;
            vm.isInviteByEmail = false;
            vm.isInviteByPhone = false;
            reset();
        };

        vm.addGuestUser = function () {
            if (vm.isInviteByEmail) {
                if(!vm.currentSelectedUsers.find(function (user) { return user.guest_invite_value === vm.email})) {
                    vm.currentSelectedUsers.push({
                        is_guest: true,
                        guest_invite_type: 'EMAIL',
                        guest_invite_value: vm.email,
                        guest_invite_language: vm.selectedLanguage
                    });
                }
                vm.email = '';
            } else if (vm.isInviteByPhone) {
                var phone_no = vm.code + ' ' + vm.phoneNumber;
                if(!vm.currentSelectedUsers.find(function (user) { return user.guest_invite_value === phone_no})) {
                    vm.currentSelectedUsers.push({
                        is_guest: true,
                        guest_invite_type: 'PHONE',
                        guest_invite_value: phone_no,
                        guest_invite_language: vm.selectedLanguage
                    });
                }
                vm.code = vm.defaultCountryCode.code;
                vm.phoneNumber = '';
            } else if (vm.isInviteByDeviceId) {
                vm.hasError = false;
                vm.isValidating = true;
                User.validateDeviceId({ deviceId: vm.deviceId }, function (result) {
                    vm.currentSelectedUsers.push({
                        is_guest: true,
                        guest_invite_type: 'DEVICE_ID',
                        guest_invite_value: result.username,
                        guest_invite_language: vm.selectedLanguage
                    });
                    vm.deviceId = '';
                    vm.isValidating = false;
                }, function () {
                    vm.hasError = true;
                    vm.isValidating = false;
                });
            };
        };

        vm.updateParentObject = function () {
            $mdDialog.hide(vm.currentSelectedUsers);
        };

        vm.onSelectUser = function (user) {
            if(!allowMultiSelect) {
                vm.currentSelectedUsers = [user]
                return;
            }
            if(!vm.currentSelectedUsers.includes(user))
                vm.currentSelectedUsers.push(user);
        };

        vm.onDeselectUser = function (user) {
            var updatedSelectedUsers = vm.currentSelectedUsers.filter(function (value) {
                return value.id != user.id || value.guest_invite_value != user.guest_invite_value;
            });

            vm.currentSelectedUsers = updatedSelectedUsers;
        };

        vm.close = function () {
            $mdDialog.cancel();
        };

        function formQuery() {
            if (!vm.query.filter) {
                return;
            };

            var queryArr = vm.query.filter.split(" ");
            var searchQuery = "(";

            searchQuery += 'first_name=re="' + vm.query.filter + '"';
            searchQuery += ',last_name=re="' + vm.query.filter + '"';
            searchQuery += ',email=re="' + vm.query.filter + '"';

            searchQuery += ")";

            return searchQuery
        };

        function isMasterSchedulerPresent() {
            return schedulerUserInfo && schedulerUserInfo.hasOwnProperty('id');
        }

        function loadAll() {
            vm.loadingData = true;
            var query = null;
            if (vm.userFilter.length != 0) {
                query = 'id=out=(' + vm.userFilter.toString() + ')';
            } else if(vm.userInviteFilter.length) {                
                var usersList =
                    vm.userInviteFilter && vm.userInviteFilter.length
                        ? vm.userInviteFilter.toString() + ","
                        : "";
                query = 'id=out=(' + usersList + (isMasterSchedulerPresent() ? schedulerUserInfo.id : Principal.getUserId()) + ')';
            } else {
                if (!isSmartScan) {
                    query = 'id=out=(' + (isMasterSchedulerPresent() ? schedulerUserInfo.id : Principal.getUserId() ) + ')';
                }
            }

            if (vm.query.filter.length != 0) {
                if (query == null) {
                    query = formQuery();
                } else {
                    query = formQuery() + (!!query ? ';' + query : '');
                }
            }
            User.query({
                query: query,
                page: 0,
                size: vm.query.limit,
                sort: sort(),
                check_admin: vm.isAdmin
            }, onSuccess, onError);

            vm.promise = System.getCountryCodes().$promise;

            vm.promise.then(function (result) {
                if (vm.isEmailFeatureEnabled) {
                    vm.handleInviteByEmail();
                } else if (vm.isSmsFeatureEnabled) {
                    vm.handleInviteByPhone();
                } else if (!vm.isEmailFeatureEnabled && !vm.isSmsFeatureEnabled) {
                    vm.handleInviteByDeviceId();
                }
                vm.countryCodes = result;
                setOrUpdateCountryCode();
            });

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'first_name') {
                    result = ['first_name,' + (vm.reverse ? 'asc' : 'desc')];
                    vm.predicate = 'first_name';
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.users = data;
                vm.page = 1;
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function changeSort(newSort) {
            if (vm.predicate == newSort) {
                vm.reverse = !vm.reverse;
            } else {
                vm.reverse = true;
                vm.predicate = newSort;
            }
            transition();
        }

        function transition() {
            var query = null;
            if (vm.userFilter.length != 0) {
                query = 'id=out=(' + vm.userFilter.toString() + ')';
            } else if(vm.userInviteFilter.length) {
                var usersList =
                    vm.userInviteFilter && vm.userInviteFilter.length
                        ? vm.userInviteFilter.toString() + "," 
                        : "";
                query = 'id=out=(' + usersList + (isMasterSchedulerPresent() ? schedulerUserInfo.id : Principal.getUserId()) + ')';
            } else {
                if (!isSmartScan) {
                    query = 'id=out=(' + (isMasterSchedulerPresent() ? schedulerUserInfo.id : Principal.getUserId() ) + ')';
                }
            }

            if (vm.query.filter.length != 0) {
                if (query == null) {
                    query = formQuery();
                } else {
                    query = formQuery() + (!!query ? ';' + query : '');
                }
            }

            vm.promise = User.query({
                query: query,
                page: vm.page - 1,
                size: vm.query.limit,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                check_admin: vm.isAdmin
            }).$promise;

            vm.promise.then(function (data, headers) {
                vm.queryCount = vm.totalItems;
                vm.users = data;
            });
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            loadAll();

            if (vm.filter.form.$dirty) {
                vm.filter.form.$setPristine();
            }
        }

        function setOrUpdateCountryCode() {
            angular.forEach(vm.countryCodes, function (countryCode) {
                countryCode.flag = getCountryFlag(countryCode.c_code);
                if (countryCode.code == '+1' && countryCode.name == 'United States') {
                    vm.defaultCountryCode = countryCode;
                }
            });
        };

        function getCountryFlag(cc) {
            return convertCharToRegionalIndicator(cc[0]) + convertCharToRegionalIndicator(cc[1]);
        };

        // Converts char to Regional Indicator Symbol
        function convertCharToRegionalIndicator(chr) {
            return String.fromCodePoint(0x1F1E6 - 65 + chr.toUpperCase().charCodeAt(0));
        }

        function reset() {
            vm.hasError = false;
            vm.email = '';
            vm.code = '';
            vm.phoneNumber = '';
            vm.deviceId = '';
        };

        function resetModal() {
            loadAll();
        };
        vm.loadAll();
    }
})();
