(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionsUpcomingController', SessionsUpcomingController);

    /* @ngInject */
    function SessionsUpcomingController(
        $scope,
        $state,
        $q,
        $filter,
        Report,
        Principal,
        CONTENT_META,
        Pubnub,
        Session,
        ParseLinks,
        pagingParams,
        paginationConstants,
        AlertService,
        $mdDialog,
        $mdToast,
        $stateParams,
        Feature,
        Setting,
        User,
        triSettings,
        triTheming,
        $rootScope,
        ToastService,
        PermissionService,
        SessionsService
    ) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.createObject = createObject;
        vm.editObject = editObject;
        vm.deleteObject = deleteObject;
        vm.scheduleSession = Feature.isActive('feature_airsession_schedule_session');
        vm.templateSession = Feature.isActive('feature_airsession_session_template');
        vm.reOrder = reOrder;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.transition = transition;
        vm.uId = Principal.getUser().id;
        vm.pageSize = paginationConstants.itemsPerPage;
        vm.removeFilter = removeFilter;
        vm.loadData = loadData;
        vm.color = triSettings.getSkinModel().primary_color_hex;
        vm.personalSession = null;
        vm.backgroundColor = triTheming.rgba(
            triTheming.getPaletteColor(triSettings.getSkinModel().primary_color, '500').value
        );

        vm.query = {
            filter: '',
            limit: '10',
            order: '-id',
            page: 1
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };
        vm.filter.show = false;

        vm.illustartionEmptyScreenId = PermissionService.hasAccess('create-session') ?
            vm.scheduleSession ?
            'session_upcoming' :
            'upcoming_no_scheduled_session' :
            'upcoming_no_session';
        vm.illustartionEmptyScreenText = 'There are no sessions yet';

        vm.permissions = {
            create: 'create-session',
            update: 'update-session',
            delete: 'delete-session',
            updatePersonalSession: 'update-personal-session',
            manage: 'manage-session'
        };

        vm.isHost = function(session) {
            if (session.owner == vm.uId) {
                return true;
            }
            for (var i = 0; i < session.session_users.length; i++) {
                if (session.session_users[i].user_id == vm.uId) {
                    if (session.session_users[i].role == 'HOST') {
                        return true;
                    }
                }
            }
            return false;
        };

        vm.isParticipant = function (session) {
            for (var i = 0; i < session.session_users.length; i++) {
                if (session.session_users[i].user_id == vm.uId) {
                    if (session.session_users[i].role == "PARTICIPANT") {
                        return true;
                    }
                }
            }
            return false;
        };

        vm.isUserHostOrScheduler = function (session) {
            // if scheduler is present then check if current user is scheduler
            // if scheduler is not present then check if current user is owner
            return angular.isDefined(session.scheduler) &&
                angular.isDefined(session.scheduler.id)
                ? session.scheduler.id === vm.uId
                : session.owner === vm.uId;
        };

        vm.startSession = function(entity) {
            $state.go('storm.sessions.player', {
                session_no: entity.session_no
            });
        };

        vm.toggleFilter = function() {
            vm.filter.show = !vm.filter.show;
        };

        vm.saveAsTemplate = function(entity) {
            var template = angular.copy(entity);
            template.id = null;
            template.type = 'TEMPLATE';
            Session.save(template, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                ToastService.displayToast(
                    $filter('translate')('atheer.session.upcomingSessions.templateCreated'),
                    3000
                );
            }

            function onSaveError() {
                vm.isSaving = false;
            }
        };

        vm.copyInvitation = function (session) {
            $mdDialog.show({
                templateUrl: 'modules/session/session-copy-invitation-dialog.tmpl.html',
                controller: 'SessionCopyInvitationDialogController',
                clickOutsideToClose: true,
                controllerAs: 'vm',
                locals: {
                    session: session
                }
            }).then(function (result) {

            });
        };

        vm.startInstantSession = function() {
            SessionsService.startInstantSession();
        };

        vm.joinSession = function() {
            SessionsService.joinSession();
        };

        vm.scheduleSession = function() {
            $state.go('storm.sessions.upcoming-detail');
        };

        vm.endSession = function (session) {
            session.status = 'CLOSED';
            session.duration = 1;
            Session.update(session, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                ToastService.displayToast($filter('translate')('atheer.session.endSessionSuccess'), 3000);
                loadData();
            };

            function onSaveError() {
                ToastService.displayToast($filter('translate')('atheer.session.endSessionError'), 3000);
            };
        };

        vm.refresh = function() {
            loadData();
        };

        function reOrder(order) {
            vm.predicate = order;
            vm.transition();
        }

        function transition() {
            vm.promise = Session.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString(),
                scheduled: true
            }).$promise;

            function sort() {
                var result = [getSortOrder()];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.sessions = data;
            });
        }

        function getSortOrder() {
            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }
        }

        function createObject() {
            $state.go('storm.sessions.upcoming-detail');
        }

        function editObject(objectId) {
            $state.go('storm.sessions.upcoming-detail', {
                id: objectId
            });
        }

        function deleteObject(entity) {
            var confirm = $mdDialog
                .confirm()
                .title($filter('translate')('atheer.session.upcomingSessions.deleteSession'))
                .ariaLabel($filter('translate')('atheer.session.upcomingSessions.deleteSession'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Session.delete({
                        id: entity.id
                    },
                    function() {
                        resetModal();
                    }
                );
            });
        }

        function resetModal() {
            loadData();
            AlertService.displayToast($mdToast);
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            resetModal();
        }

        function getQueryString() {
            var query = '';
            if (vm.query.filter.length > 0) {
                query = query + '(text_search=' + vm.query.filter + ')';
            }
            return query;
        }

        function loadData() {
            vm.loadingData = true;
            if ($rootScope.isStartSession) {
                vm.startInstantSession();
                $rootScope.isStartSession = false;
            } else if ($rootScope.isJoinSession) {
                vm.joinSession();
                $rootScope.isJoinSession = false;
            }
            Session.query({
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort(),
                    query: getQueryString(),
                    scheduled: true
                },
                onSuccess,
                onError
            );

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.sessions = data;
                vm.page = pagingParams.page;
                $q.all([
                        User.getPersonalSession().$promise
                    ])
                    .then(function(results) {
                        vm.personalSession = results[0];
                        vm.loadingData = false;
                    });
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        }

        var refreshSessionListener = $rootScope.$on('refreshSessions', function(event, filter) {
            vm.query.filter = filter;
            loadData();
        });

        // Unregister
        $scope.$on('$destroy', function() {
            refreshSessionListener();
        });

        loadData();
    }
})();
