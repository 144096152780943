(function () {
    'use strict';

    angular
        .module('atheer.session')
        .directive('sessionJitsiPlayer', sessionJitsiPlayer);

    function sessionJitsiPlayer() {
        var directive = {
            scope: {
                sessionNo: '@',
                isGuest: '@'
            },
            bindToController: true,
            controller: sessionJitsiPlayerController,
            controllerAs: 'vm',
            templateUrl: 'modules/session/session-jitsi-player.tmpl.html',
            restrict: 'E'
        };
        return directive;
    };

    /* @ngInject */
    function sessionJitsiPlayerController($rootScope, $scope, $element, $mdDialog, $mdToast, $document, $mdPanel, $state, $timeout, $q, $filter, Report, ConversationModelService, Principal, Session, Conversation, Setting, SessionPlayerService, PubNubService, Experience, SessionAnnotationService, Feature, SessionConfigService, Auth, $cookies, triSettings, $location, $window, deviceDetector, NotificationHandleService, ToastService) {
        var vm = this;
        var annotationTypeEnum = {
            classic: 'CLASSIC',
            live: 'LIVE',
            ar: 'AR'
        };

        var closeAnnotationListener = null;
        var imageLoadedListener = null;
        var annotationImageSavedListener = null;
        var saveAnnotationImageListener = null;
        var freezeFrameListener = null;
        var pubNubSessionListener = null;
        var sessionMessagesCount = null;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.isFullScreen = false;
        vm.audioStatus = true;
        vm.videoStatus = true;
        vm.shareScreenLocked = false;
        vm.showPlayer = false;
        vm.showParticipants = false;
        vm.showConversation = false;
        vm.sessionModel = null;
        vm.jitsiUserId = null;
        vm.isHost = false;
        vm.waitingForHost = false;
        vm.userId = null;
        vm.userShortName = null;
        vm.userInfo = null;
        vm.sessionUsers = [];
        vm.sessionSettingOverwrite = null;
        vm.invitedUserIds = [];
        vm.jitsiRecorderStatus = null;
        vm.recordingStatus = false;
        vm.loadingRecorder = false;
        vm.convMessageBody = null;
        vm.messages = [];
        vm.convChannelName = null;
        vm.annotationLocked = false;
        vm.screenShotSrc = null;
        vm.isAnnotating = false;
        vm.annotationData = null;
        vm.isAnnotationView = false;
        vm.isAnnotationSaved = false;
        vm.isImageLoaded = false;
        vm.zoomMax = 2;
        vm.zoomMin = 1;
        vm.zoom = 1;
        vm.zoomStep = 0.01;
        vm.zoomX = 0.5;
        vm.zoomY = 0.5;
        vm.zoomSlider = 100;
        vm.enableZoom = false;
        vm.zoomValue = 100;
        vm.selectedUserJitsiId = null;
        vm.isZoomEnabled = true;
        vm.annotationType = annotationTypeEnum.classic;
        vm.isArAnnotating = false;
        vm.defaultSwitchUserId = null;
        vm.defaultAnnotationType = null;
        vm.canAnnotate = true;
        vm.emptyIllustration = "session-conv";
        vm.recordingFeature = Feature.isActive('feature_airsession_recording');
        vm.recordingContentFeature = Feature.isActive('feature_airsession_enable_recording_consent');
        vm.recordingContentDialogOpen = false;
        vm.isScheduledTimer = false;
        vm.isARLoaderRequired = true;
        vm.isARSupported = false;
        vm.isOpenAnnotationOptions = false;
        vm.hasSlam = false;
        vm.isLiveAnnotationFeatureEnabled = Feature.isActive('feature_airsession_live_annotation');
        vm.userName = null;
        vm.defaultZoom = 1;
        vm.isScreenSharing = false;
        vm.isCapturingScreenshot = false;
        //As per discussion, added by default true, because in future this featureflag removed from DB.
        vm.isNativeImageCaptureFeature = true; //Feature.isActive('feature_airsession_native_image_capture');
        vm.isRemoteControlEnabled = Feature.isActive('feature_airsession_enable_remote_control_support');
        vm.isCapturing = false;
        vm.isGuestWaitingForHost = false;
        vm.isMobileBrowser = deviceDetector.isMobile();
        vm.recordingTimer = null;
        vm.isTileView = false;
        vm.hasRecording = false;

        vm.messagesCount = 0;
        vm.isSharedZoomDisabled = false;
        vm.isZoomToolsEnabled = true;
        vm.isSharedZoomDisabledFeature = Feature.isActive('feature_airsession_enable_zoom_preference');
        vm.userHasRemoteControlSupport = false;
        vm.remoteControlAccessMap = {};
        vm.isRemoteControlPermissionRequested = false;
        vm.isRemoteControlPermissionGranted = false;
        vm.remoteControlledUser = null;
        vm.remoteRequestedUser = null;
        vm.isRemoteControlActive = false;
        vm.stream = null;
        vm.imageCapture = null;
        vm.isFakeParticipantOnStage = false;
        vm.isMergeRealityEnabled = Feature.isActive('feature_airsession_enable_mr_feature');
        vm.isMergeRealityCallInProgress = false;
        vm.baseFeedId = null;
        vm.overlayId = null;
        vm.isWaitingForOtherUserFeed = false;
        vm.permissions = {
            start_mr: 'start_mr_session-session',
            create: 'create-session'
        }

        vm.requestRemoteControl = function (participantId) {
            vm.isRemoteControlActive = true;
            vm.remoteControlledUser = getUserIdByJitsiId(participantId);
            vm.remoteRequestedUser = vm.userId;
            var remoteControlRequestMessage = {
                subject: 'SESSION',
                action: 'BROADCAST',
                type: 'REMOTE_CONTROL_REQUESTED',
                data: {
                    toUserId: vm.remoteControlledUser,
                    fromUserId: vm.userId
                }
            }
            publishMessage(remoteControlRequestMessage);
            createSessionActivity('REMOTE_CONTROL_ACCESS_REQUESTED', null);
            vm.isRemoteControlPermissionRequested = true;
            SessionPlayerService.requestRemoteControl(participantId);
        }

        vm.stopRemoteControl = function () {
            vm.remoteControlledUser = null;
            SessionPlayerService.stopRemoteControl();
        }

        vm.showSharedZoomDialog = function () {
            $mdDialog.show({
                templateUrl: 'modules/session/session-shared-zoom-dialog.tmpl.html',
                controller: 'SessionPlayerSharedZoomController',
                clickOutsideToClose: true,
                controllerAs: 'vm',
                locals: {
                    isSharedZoomDisabled: vm.isSharedZoomDisabled
                }
            }).then(function (result) {
                vm.isSharedZoomDisabled = result;
                SessionPlayerService.setSharedZoom(vm.isSharedZoomDisabled);

                publishSharedZoomDisabled();
                resetZoom();

                if (result) {
                    createSessionActivity('PRIVATE_ZOOM_DISABLED', null);
                } else {
                    createSessionActivity('PRIVATE_ZOOM_ENABLED', null);
                }
            });
        };

        //used in NON-Angular Async process
        var scopeApply = function (fn) {
            var phase = $scope.$root.$$phase;
            if (phase !== '$apply' && phase !== '$digest') {
                return $scope.$apply(fn);
            }
        };

        vm.toggleAudioOfUser = function (userId, publish, report) {
            if (userId == vm.userId) {
                vm.audioStatus = !vm.audioStatus;
                SessionPlayerService.toggleAudio();
                updateAudioStatus(userId, vm.audioStatus);
                if (publish) {
                    var toggleAudioUserMessage = {
                        subject: 'SESSION',
                        action: 'BROADCAST',
                        type: 'AUDIO_STATUS',
                        data: {
                            audioStatus: vm.audioStatus
                        }
                    };
                    publishMessage(toggleAudioUserMessage);
                };
                if (vm.isMobileBrowser) {
                    if (vm.audioStatus) {
                        displayToast($filter('translate')('atheer.session.sessionPlayer.microphoneOn'), 3000);
                    } else {
                        displayToast($filter('translate')('atheer.session.sessionPlayer.microphoneOff'), 3000);
                    }
                }
            } else {
                var toggleAudioUserMessage = {
                    subject: 'SESSION',
                    action: 'USER',
                    type: 'AUDIO_STATUS',
                    data: {
                        userId: userId
                    }
                };
                publishMessage(toggleAudioUserMessage);
            };

            if (report) {
                if (vm.audioStatus == true) {
                    createSessionActivity('AUDIO_ON', null);
                } else {
                    createSessionActivity('AUDIO_OFF', null);
                };
            };
        };

        vm.toggleAudioAll = function (audioStatus) {
            var muteAllMessage = {
                subject: 'SESSION',
                action: 'BROADCAST',
                type: 'TOGGLE_AUDIO_ALL',
                data: {
                    audioStatus: audioStatus
                }
            };
            publishMessage(muteAllMessage);
        };

        vm.toggleVideoOfUser = function (userId, publish, report) {
            if (userId == vm.userId) {
                vm.videoStatus = !vm.videoStatus;
                SessionPlayerService.toggleVideo();
                updateVideoStatus(userId, vm.videoStatus);
                if (publish) {
                    var toggleVideoUserMessage = {
                        subject: 'SESSION',
                        action: 'BROADCAST',
                        type: 'VIDEO_STATUS',
                        data: {
                            videoStatus: vm.videoStatus
                        }
                    };
                    publishMessage(toggleVideoUserMessage);
                };
                if (vm.isMobileBrowser) {
                    if (vm.videoStatus) {
                        displayToast($filter('translate')('atheer.session.sessionPlayer.videoIsOn'), 3000);
                    } else {
                        displayToast($filter('translate')('atheer.session.sessionPlayer.videoIsOff'), 3000);
                    }
                }
            } else {
                var toggleVideoUserMessage = {
                    subject: 'SESSION',
                    action: 'USER',
                    type: 'VIDEO_STATUS',
                    data: {
                        userId: userId
                    }
                };
                publishMessage(toggleVideoUserMessage);
            };

            if (report) {
                if (vm.videoStatus == true) {
                    createSessionActivity('VIDEO_ON', null);
                } else {
                    createSessionActivity('VIDEO_OFF', null);
                };
            };
        };

        vm.toggleFlashOfUser = function (userId) {
            var toggleFlashUserMessage = {
                subject: 'SESSION',
                action: 'USER',
                type: 'FLASH_LIGHT_STATUS',
                data: {
                    userId: userId
                }
            };
            publishMessage(toggleFlashUserMessage);
        };

        vm.toggleTileView = function () {
            SessionPlayerService.toggleTileView();
        };

        vm.zoomIn = function () {
            createSessionActivity('ZOOM_IN', null);
            vm.zoom += vm.zoomStep;
            vm.zoom = vm.zoom >= vm.zoomMax ? vm.zoomMax : vm.zoom;
            if (vm.zoom > 1) {
                vm.enableZoom = !vm.isSharedZoomDisabled ? true : (vm.userId == getUserId() ? true : false);
            }
            vm.zoomSlider = vm.zoom * 100;
        };

        vm.zoomOut = function () {
            createSessionActivity('ZOOM_OUT', null);
            vm.zoom -= vm.zoomStep;
            vm.zoom = vm.zoom <= vm.zoomMin ? vm.zoomMin : vm.zoom;
            vm.zoomSlider = vm.zoom * 100;
        };

        vm.setZoomValue = function (x, y, z) {
            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (vm.selectedUserJitsiId == sessionUser.jitsiUserId) {
                    sessionUser.tx = x;
                    sessionUser.ty = y;
                    sessionUser.scale = z;
                }
            });
        };

        vm.sendZoomPositiontoJitsi = function (x, y) {
            SessionPlayerService.applyZoom(x, y, vm.zoom, vm.selectedUserJitsiId);
            vm.setZoomValue(x, y, vm.zoom);
            vm.zoomValue = Math.floor(vm.zoom * 100);
            if (vm.zoom == 1) {
                vm.enableZoom = false;
            }
        };

        vm.sendZoomPosition = function () {
            publishParticipantZoomData();
        }

        vm.toggleRecording = function () {
            if (!vm.recordingStatus) {
                vm.loadingRecorder = true;
                SessionPlayerService.startRecording();

                vm.recordingTimer = $timeout(function () {
                    resetRecordingState()
                }, 240000);

            } else {
                SessionPlayerService.stopRecording();
            }
        };

        vm.toggleConversation = function () {
            if (vm.showParticipants) {
                vm.toggleUsers();
            };
            vm.showConversation = !vm.showConversation;

            if (vm.showConversation) {
                getReadMessagesCount();
            }
        };

        vm.toggleUsers = function (componentId) {
            if (vm.showConversation) {
                vm.toggleConversation();
            };

            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (sessionUser.userId == vm.userId) {
                    vm.sessionUsers.splice(vm.sessionUsers.indexOf(sessionUser), 1);
                    vm.sessionUsers.unshift(sessionUser);
                };
            });

            vm.showParticipants = !vm.showParticipants;
        };

        vm.toggleShareScreen = function () {
            SessionPlayerService.toggleShareScreen();
        };

        vm.showCapturedImages = function () {
            $mdDialog.show({
                templateUrl: 'modules/session/session-player-images-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: 'SessionPlayerImagesController',
                controllerAs: 'vm',
                locals: {
                    sessionModel: vm.sessionModel,
                    userId: vm.userId,
                    isMobileBrowser: vm.isMobileBrowser,
                    hasRecording: vm.hasRecording
                }
            }).then(function () {

            });
        }

        vm.finalizeSession = function (isEnd) {
            if (isEnd && vm.recordingStatus) {
                $mdDialog.show(
                    $mdDialog.alert()
                        .clickOutsideToClose(true)
                        .title($filter('translate')('atheer.session.sessionPlayer.recordingIsOn'))
                        .textContent($filter('translate')('atheer.session.sessionPlayer.stopIt'))
                        .ariaLabel($filter('translate')('atheer.session.sessionPlayer.stopIt'))
                        .ok($filter('translate')('atheer.session.sessionPlayer.gotIt'))
                );
            } else if (vm.isAnnotating && vm.isAnnotationView && !vm.isAnnotationSaved) {
                $mdDialog.show(
                    $mdDialog.alert()
                        .clickOutsideToClose(true)
                        .title($filter('translate')('atheer.session.sessionPlayer.annotationIsOn'))
                        .textContent($filter('translate')('atheer.session.sessionPlayer.saveAnnotation'))
                        .ariaLabel($filter('translate')('atheer.session.sessionPlayer.saveAnnotation'))
                        .ok($filter('translate')('atheer.session.sessionPlayer.gotIt'))
                );
            } else if (vm.isArAnnotating && vm.annotationType !== annotationTypeEnum.classic) {
                if (vm.annotationLocked) {
                    closeSession(isEnd);
                } else {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .clickOutsideToClose(true)
                            .title($filter('translate')('atheer.session.sessionPlayer.annotationIsOn'))
                            .textContent($filter('translate')('atheer.session.sessionPlayer.closeAnnotation'))
                            .ariaLabel($filter('translate')('atheer.session.sessionPlayer.closeAnnotation'))
                            .ok($filter('translate')('atheer.session.sessionPlayer.gotIt'))
                    );
                }
            } else {
                closeSession(isEnd);
            };

            function closeSession(isEnd) {
                $mdDialog.show({
                    templateUrl: 'modules/session/session-player-close-dialog.tmpl.html',
                    clickOutsideToClose: true,
                    controller: 'SessionPlayerCloseController',
                    controllerAs: 'vm',
                    locals: {
                        sessionModel: vm.sessionModel,
                        userId: vm.userId,
                        isEnd: isEnd,
                        isMobileBrowser: vm.isMobileBrowser,
                        hasRecording: vm.hasRecording
                    }
                }).then(function (sessionActivities) {
                    if (sessionActivities != null) {
                        Experience.createSessionActivity(sessionActivities);
                    }

                    if (isEnd) {
                        checkForGuestUsers();

                        var sessionEndMessage = {
                            subject: 'SESSION',
                            action: 'BROADCAST',
                            type: 'END'
                        };

                        vm.sessionModel.status = 'CLOSED';
                        Session.update(vm.sessionModel, function (result) {
                            publishMessage(sessionEndMessage);
                            closeSessionPlayer();
                        });
                        Conversation.archive({
                            id: vm.sessionModel.conversation_info.id
                        });
                    } else {
                        createSessionActivity('PARTICIPANT_LEFT', null);
                        closeSessionPlayer();
                    };
                });
            }
        };

        vm.showSessionInfo = function (ev) {
            var orientation = window.innerWidth > window.innerHeight ? 'Landscape' : 'Portrait';
            var position = $mdPanel.newPanelPosition()
                .relativeTo('#session-player-toolbar')
                .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.ABOVE);

            var config = {
                attachTo: angular.element(document.body),
                controller: 'SessionInfoPanelController',
                controllerAs: 'vm',
                templateUrl: 'modules/session/session-info-panel.tmpl.html',
                position: position,
                locals: {
                    sessionModel: vm.sessionModel,
                    workspaceCode: Principal.getWorkspaceCode()
                },
                openFrom: ev,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: false,
                zIndex: 2,
                onCloseSuccess: function (panelRef, reason) {

                }
            };
            $mdPanel.open(config);
        };

        vm.showSessionInfoDialog = function () {
            $mdDialog.show({
                templateUrl: 'modules/session/session-info-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: 'SessionInfoDialogController',
                controllerAs: 'vm',
                locals: {
                    sessionModel: vm.sessionModel,
                    workspaceCode: Principal.getWorkspaceCode(),
                    isMobileBrowser: vm.isMobileBrowser
                }
            }).then(function () {

            });
        };

        vm.toggleFullScreen = function () {
            vm.isFullScreen = !vm.isFullScreen;
            // more info here: https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
            var doc = $document[0];
            if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
                if (doc.documentElement.requestFullscreen) {
                    doc.documentElement.requestFullscreen();
                } else if (doc.documentElement.msRequestFullscreen) {
                    doc.documentElement.msRequestFullscreen();
                } else if (doc.documentElement.mozRequestFullScreen) {
                    doc.documentElement.mozRequestFullScreen();
                } else if (doc.documentElement.webkitRequestFullscreen) {
                    doc.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (doc.exitFullscreen) {
                    doc.exitFullscreen();
                } else if (doc.msExitFullscreen) {
                    doc.msExitFullscreen();
                } else if (doc.mozCancelFullScreen) {
                    doc.mozCancelFullScreen();
                } else if (doc.webkitExitFullscreen) {
                    doc.webkitExitFullscreen();
                }
            }
        };

        vm.showShortCuts = function () {
            SessionPlayerService.showKeyboardShortcuts();
        };

        vm.captureFeeback = function () {
            $mdDialog.show({
                templateUrl: 'modules/session/session-feedback-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: 'SessionFeedbackController',
                controllerAs: 'vm',
                locals: {
                    sessionModel: vm.sessionModel,
                    userId: vm.userId,
                    isMobileBrowser: vm.isMobileBrowser
                }
            }).then(function (result) {

            });
        };

        vm.changeSettings = function () {
            SessionPlayerService.showSettings();
        };

        vm.changeVideoQuality = function () {
            SessionPlayerService.showVideoQuality();
        };

        vm.toggleCamera = function () {
            SessionPlayerService.toggleCamera();
        };

        vm.inviteUsers = function () {
            var isConversation = false;
            if (vm.sessionModel.type == 'GROUP_CONVERSATION_INSTANCE') {
                isConversation = true;
            }
            $mdDialog.show({
                templateUrl: 'modules/session/session-user-invite.tmpl.html',
                controller: 'SessionUserInviteController',
                controllerAs: 'vm',
                locals: {
                    filter: [],
                    allowMultiSelect: true,
                    userslist: [],
                    isSession: true,
                    isScheduledSession: false,
                    isAdmin: false,
                    isConversationSession: isConversation,
                    isSmartScan: false,
                    selectedItemIds: [],
                    schedulerUserInfo: null,
                    isSessionSingleUserInvite: false,
                    autoCompleteModel: []
                }
            })
                .then(function (selectedItems) {
                    inviteUsers(selectedItems);
                });
        };

        vm.annotate = function (annotationType) {
            if (annotationType == annotationTypeEnum.classic && vm.isNativeImageCaptureFeature) {
                vm.sendAnnotationLockMessage();
                if (vm.userId === getUserId() || vm.isFreezeFrame) {
                    SessionPlayerService.annotate(true);
                    vm.isCapturing = true;
                }
            } else if (annotationType == annotationTypeEnum.classic && !vm.isNativeImageCaptureFeature) {
                vm.sendAnnotationLockMessage();
                SessionPlayerService.annotate(true);
                vm.isCapturing = true;
            } else {
                vm.annotationType = vm.hasSlam ? annotationTypeEnum.ar : annotationTypeEnum.live;
                SessionPlayerService.annotateAR();
            }
            vm.isOpenAnnotationOptions = false;
        };

        vm.openAnnotationOption = function () {
            vm.isOpenAnnotationOptions = !vm.isOpenAnnotationOptions;
        };

        vm.captureScreenShot = function () {
            vm.isCapturingScreenshot = true;
            if (vm.isNativeImageCaptureFeature && vm.userId !== getUserId()) {
                var screenshotCaptureData = {
                    userId: vm.userId,
                    targetUserId: getUserId()
                };
                publishCaptureMessage('CAPTURE_SCREEN_SHOT', screenshotCaptureData);
            } else {
                SessionPlayerService.captureScreenShot(false);
            }

        };

        vm.toggleMergeReality = function () {
            vm.isMergeRealityCallInProgress = !vm.isMergeRealityCallInProgress;
            if (vm.isMergeRealityCallInProgress) {
                var remoteUser = vm.sessionUsers.filter(function (value) {
                    return value.jitsiUserId != vm.jitsiUserId;
                })[0];

                if (!remoteUser.videoStatus) {
                    vm.isWaitingForOtherUserFeed = true;
                }
                vm.overlayId = vm.jitsiUserId;
                vm.baseId = remoteUser.jitsiUserId;
                SessionPlayerService.enableMergeReality(vm.isMergeRealityCallInProgress, vm.jitsiUserId, remoteUser.jitsiUserId);
                createSessionActivity('MR_STARTED', null);
            } else {
                vm.overlayId = null;
                vm.baseId = null;
                SessionPlayerService.disableMergeReality();
                createSessionActivity('MR_ENDED', null);
            }
        }

        vm.onGoBack = function () {
            //for localhost
            // if (!$location.host().startsWith('app') && !$location.host().startsWith('localhost')) {
            if (!$location.host().startsWith('app')) {
                var hostUrl = $location.absUrl();
                var path = $location.path();
                var domain = $window.location.host.split('.')[0];

                var url = hostUrl.replace(domain, "app").replace(path, "/signin");
                $window.location.href = url;
            } else {
                $rootScope.isAppWorkspace = true;
                $state.go('account.signin');
            }
        };

        vm.showAnnotationModeDialog = function () {
            $mdDialog.show({
                templateUrl: 'modules/session/session-annotation-mode-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: 'SessionAnnotationModeDialogController',
                controllerAs: 'vm',
                locals: { mainData: vm },
                bindToController: true,
            }).then(function (result) {
                return result;
            });
        };

        vm.sendAnnotationLockMessage = function () {
            if (!vm.isFreezeFrame) {
                var annotationData = {
                    lockState: true,
                    targetAspectRatio: 1.0,
                    type: 'CLASSIC',
                    targetUser: getUserId(),
                };

                var annotationLockMessage = {
                    subject: 'SESSION',
                    action: 'BROADCAST',
                    type: 'ANNOTATION_LOCK',
                    data: annotationData
                };
                publishMessage(annotationLockMessage);
                createSessionActivity('ANNOTATION_STARTED', null);
                vm.annotationType = annotationTypeEnum.classic;
                vm.isAnnotationView = true;
                vm.isAnnotationSaved = false;
            }
        };

        function updateAnnotationStatus(event, isSaved) {
            vm.isAnnotationSaved = !isSaved;
        };

        function saveAnnotation(event, data) {
            vm.isAnnotationSaved = data.isAnnotationSaved;
            var annotationCaptureData = {
                userId: vm.userId
            };
            publishCaptureMessage('ANNOTATION_CAPTURED', annotationCaptureData);
            var saveAnnotationProperties = {
                file_name: data.annotationImageData.data.name,
                media_file_name: data.annotationImageData.data.properties.file_name,
                media_id: data.annotationImageData.data.id
            }
            createSessionActivity('ANNOTATION_SAVED', saveAnnotationProperties);
        };

        function imageLoaded(event, isLoaded) {
            vm.isImageLoaded = isLoaded;
        };

        function closeAnnotation(event, isSaved) {
            var closeAnnotationMessage = {
                subject: 'SESSION',
                action: 'BROADCAST',
                data: {
                    lockState: false
                }
            };
            if (vm.annotationType == 'CLASSIC' && vm.isArAnnotating) {
                closeAnnotationMessage.type = 'FREEZE_FRAME_LOCK';
                if (vm.isEditEnabled) {
                    SessionPlayerService.annotateAR();
                } else {
                    SessionPlayerService.lockArAnnotation(vm.selectedUserJitsiId, true);
                }
                vm.annotationType = vm.defaultAnnotationType;
            } else {
                closeAnnotationMessage.type = 'ANNOTATION_LOCK';
                if (!vm.isMobileBrowser) {
                    SessionPlayerService.hideThumbnails(false);
                }
                SessionPlayerService.setAnnotationMode(false);
                vm.isARLoaderRequired = true;
            }
            resetVariables();
            publishMessage(closeAnnotationMessage);
            createSessionActivity('ANNOTATION_ENDED', null);
        };

        function onFreezeFrame(event) {
            if (vm.annotationType == annotationTypeEnum.ar) {
                vm.isCapturingScreenshot = true;
                var screenshotCaptureData = {
                    userId: vm.userId,
                    targetUserId: getUserId()
                };
                publishCaptureMessage('CAPTURE_AR_SCREEN_SHOT', screenshotCaptureData);
            } else {
                $scope.$broadcast('show-loader', true);
                vm.isFreezeFrame = true;
                vm.annotate(annotationTypeEnum.classic);
            }

        }

        function inviteUsers(invitingUsers) {
            var sessionId = vm.sessionModel.id;
            var sessionInviteObject = {
                user_ids: [],
                guest_users: []
            };

            for (var i = 0; i < invitingUsers.length; i++) {
                if (invitingUsers[i].is_guest) {
                    sessionInviteObject.guest_users.push(invitingUsers[i]);
                    vm.invitedUserIds.push(invitingUsers[i].guest_invite_value);
                } else {
                    sessionInviteObject.user_ids.push(invitingUsers[i].id);
                    vm.invitedUserIds.push(invitingUsers[i].id);
                }
            }
            Session.invite({
                id: vm.sessionModel.id,
            }, sessionInviteObject, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                for (var i = 0; i < invitingUsers.length; i++) {
                    if (!invitingUsers[i].is_guest) {
                        var obj = {
                            user_id: invitingUsers[i].id,
                            role: 'PARTICIPANT'
                        };

                        vm.sessionModel.session_users.push(obj);
                    }
                }
                displayToast($filter('translate')('atheer.session.sessionPlayer.participantsInvited'), 3000);
            };

            function onSaveError() {
                AlertService.displayToast($mdToast);
            };
        };

        function loadData() {
            vm.loadingData = true;
            vm.userId = Principal.getUserId();
            vm.userInfo = Principal.getUserInfo();
            vm.userShortName = Principal.getUserShortName();
            //to set theme for guest join loader
            setTimeout(function () {
                var spinners = document.getElementsByClassName("spinner");
                angular.forEach(spinners, function (spinner) {
                    spinner.style.backgroundColor = triSettings.getSkinModel().primary_color_hex;
                });
            }, 200);
            $q.all([
                Session.get({
                    id: vm.sessionNo,
                    is_session_no: true,
                    for_launch: true,
                    is_web: true
                }).$promise,
                Setting.getSessionSettings().$promise
            ])
                .then(function (results) {
                    if (!results[0].id && results[0].result === 'NOT_FOUND') {
                        displayToast($filter('translate')('atheer.session.sessionPlayer.sessionExpired'), 3000);
                        navigateBack();
                    } else {

                        vm.sessionModel = results[0];
                        if (!vm.sessionModel.settings) {
                            vm.sessionModel.settings = {};
                            setSettingSessionModel(results[1]);
                        }

                        setSettingsForSurvey(results[1]);

                        for (var i = 0; i < vm.sessionModel.session_users.length; i++) {
                            vm.invitedUserIds.push(vm.sessionModel.session_users[i].user_id);
                        }
                        if (vm.sessionModel.guest_users) {
                            for (var i = 0; i < vm.sessionModel.guest_users.length; i++) {
                                vm.invitedUserIds.push(vm.sessionModel.guest_users[i].guest_id);
                            }
                        }
                        vm.invitedUserIds.push(vm.sessionModel.owner);
                        if (vm.sessionModel.type == "SCHEDULED") {
                            vm.isScheduledTimer = true;
                        }
                        vm.loadingData = false;
                        initSessionPlayer();
                        initFullScreenHandler();
                        getUnReadMessageCount();
                    }
                });
        };

        function setSettingSessionModel(settings) {
            angular.forEach(settings, function (setting) {
                if (setting.category == 'session_setting') {
                    vm.sessionSetting = setting;

                    angular.forEach(setting.fields, function (settingField) {
                        if (settingField.name == 'host_video') {
                            vm.sessionModel.settings.host_video = settingField.value == 'true' ? true : false;
                        } else if (settingField.name == 'participant_video') {
                            vm.sessionModel.settings.participant_video = settingField.value == 'true' ? true : false;
                        } else if (settingField.name == 'join_before_host') {
                            vm.sessionModel.settings.join_before_host = settingField.value == 'true' ? true : false;
                        } else if (settingField.name == 'mute_upon_entry') {
                            vm.sessionModel.settings.mute_upon_entry = settingField.value == 'true' ? true : false;
                        } else if (settingField.name == 'sign_in_required') {
                            vm.sessionModel.settings.sign_in_required = settingField.value == 'true' ? true : false;
                        } else if (settingField.name == 'enable_waiting_room') {
                            vm.sessionModel.settings.enable_waiting_room = settingField.value == 'true' ? true : false;
                        } else if (settingField.name == 'record_session') {
                            vm.sessionModel.settings.record_session = settingField.value == 'true' ? true : false;
                        } else if (settingField.name == 'recurring_Session') {
                            vm.sessionModel.settings.recurring_Session = settingField.value == 'true' ? true : false;
                        } else if (settingField.name == 'timezone_id') {
                            vm.sessionModel.settings.timezone = settingField.value;
                        } else if (settingField.name == 'enable_external_survey') {
                            vm.sessionModel.settings.enable_external_survey = settingField.value == 'true' ? true : false;
                        } else if (settingField.name == 'external_survey_url') {
                            vm.sessionModel.settings.external_survey_url = settingField.value;
                        };
                    });
                }
            });
        };

        function setSettingsForSurvey(settings) {
            angular.forEach(settings, function (setting) {
                if (setting.category == 'session_setting') {
                    vm.sessionSetting = setting;

                    angular.forEach(setting.fields, function (settingField) {
                        if (settingField.name == 'enable_external_survey') {
                            vm.sessionModel.settings.enable_external_survey = settingField.value == 'true' ? true : false;
                        } else if (settingField.name == 'external_survey_url') {
                            vm.sessionModel.settings.external_survey_url = settingField.value;
                        };
                    });
                }
            });
        };

        function initSessionPlayer() {
            updateSessionSettingsFromOverwrite();
            vm.isHost = isUserHost();

            if (vm.isHost) {
                vm.videoStatus = vm.sessionModel.settings.host_video;
            } else {
                vm.videoStatus = vm.sessionModel.settings.participant_video;
                vm.audioStatus = !vm.sessionModel.settings.mute_upon_entry;
            };

            vm.isZoomEnabled = vm.videoStatus ? true : false;
            vm.canAnnotate = vm.videoStatus ? true : false;

            if (!isBrowserSupported()) {
                $mdDialog.show({
                    templateUrl: 'modules/session/session-player-unsupported-browser.tmpl.html',
                    clickOutsideToClose: false,
                    controller: 'SessionPlayerUnsupportedBrowserController',
                    controllerAs: 'vm',
                    locals: {
                        sessionModel: vm.sessionModel
                    }
                }).then(function (launchPlayer) {
                    if (launchPlayer) {
                        startSessionPlayer();
                    } else {
                        navigateBack();
                    };
                });
            } else {
                if (!vm.isHost && vm.sessionModel.use_passcode) {
                    $mdDialog.show({
                        templateUrl: 'modules/session/session-player-passcode-dialog.tmpl.html',
                        clickOutsideToClose: false,
                        controller: 'SessionPlayerPasscodeController',
                        controllerAs: 'vm',
                        locals: {
                            sessionModel: vm.sessionModel
                        }
                    }).then(function (launchPlayer) {
                        if (launchPlayer) {
                            startSessionPlayer();
                        } else {
                            navigateBack();
                        };
                    });
                } else {
                    startSessionPlayer();
                };
            }

        };

        function startSessionPlayer() {
            initPubNubHandler();
            $rootScope.isSessionStarted = true;
            if (vm.isHost) {
                vm.updateWaitingForHost = false;
            } else {
                if (!vm.sessionModel.settings.join_before_host && vm.sessionModel.type == 'SCHEDULED') {
                    vm.waitingForHost = true;
                    vm.isGuestWaitingForHost = vm.isGuest ? true : false;
                } else {
                    vm.waitingForHost = false;
                };
            };
            startJitsiPlayer();
        };

        function startJitsiPlayer() {
            var defaultLanguage = getDefaultLanaguage();
            var configOverWrite = {
                startWithVideoMuted: !vm.videoStatus,
                startWithAudioMuted: !vm.audioStatus,
                defaultLanguage: defaultLanguage
            };

            var options = {
                roomName: vm.sessionModel.jitsi_room_no,
                width: '100%',
                height: '100%',
                parentNode: document.querySelector('#jitsi-session-player'),
                onload: jitsiOnLoad,
                configOverwrite: configOverWrite,
                interfaceConfigOverwrite: SessionPlayerService.getInterfaceConfig(vm.sessionModel),
                jwt: vm.sessionModel.jwt_token
            };

            var api = new JitsiMeetExternalAPI(SessionConfigService.getDomain(), options);

            var eventListeners = {
                videoConferenceJoined: onVideoConferenceJoined,
                videoConferenceLeft: onVideoConferenceLeft,
                participantJoined: onParticipantJoined,
                participantLeft: onParticipantLeft,
                screenSharingStatusChanged: screenSharingStatusChanged,
                recorderStatusChanged: recorderStatusChanged,
                screenShotReady: screenShotReady,
                videoDimensionsReady: videoDimensionsReady,
                onStageParticipantChanged: onStageParticipantChanged,
                arAnnotationReady: arAnnotationReady,
                resizeVideoDimentions: resizeVideoDimentions,
                videoMuteStatusChanged: onVideoMuteStatusChanged,
                userSupportsRemoteControl: onUserSupportsRemoteControl,
                remoteControlPermissionUpdate: onRemoteControlPermissionUpdate,
                remoteControlStopped: onRemoteControlStopped,
                tileViewChanged: onTileViewChanged,
                audioMuteStatusChanged: onAudioMuteStatusChanged,
                mergeRealityLock: onMergeRealityLock
            };

            var displayName = generateUserDisplayName();
            var displayPictureURL = generateDisplayPictureURL();
            var subject = vm.sessionModel.topic + " - " + vm.sessionModel.session_no;

            SessionPlayerService.init(api, vm.isRemoteControlEnabled);
            SessionPlayerService.addEventListeners(eventListeners);
            SessionPlayerService.setDisplayName(displayName);
            SessionPlayerService.setSubject(subject);
            SessionPlayerService.setNotifications(false);
            SessionPlayerService.setDisplayPicture(displayPictureURL);
            SessionPlayerService.setSharedZoom(false);
            if (vm.isMobileBrowser) {
                SessionPlayerService.hideThumbnails(true);
            }

            if (vm.isHost) {
                createSessionActivity('SESSION_STARTED', null);
            } else {
                createSessionActivity('PARTICIPANT_JOINED', null);
            };
        };

        function onMergeRealityLock(message) {
            if (message.data.enable && (vm.isMobileBrowser || !vm.videoStatus)) {
                showMergeRealityDialog(message);
            } else {
                updateMergeReality(message)
            }
        }

        function showMergeRealityDialog(message) {
            $mdDialog.show({
                templateUrl: 'modules/session/session-merge-reality-dialog.tmpl.html',
                controller: 'SessionPlayerMergeRealityController',
                clickOutsideToClose: false,
                controllerAs: 'vm',
                locals: { mainData: vm, message: message },
                bindToController: true,
            }).then(function (result) {
                updateMergeReality(result);
                if (!vm.videoStatus) {
                    vm.toggleVideoOfUser(vm.userId, true, true);
                }

            });
        }

        function updateMergeReality(message) {
            vm.isMergeRealityCallInProgress = message.data.enable;
            vm.baseFeedId = vm.isMergeRealityCallInProgress ? message.data.baseId : null;
            vm.overlayId = vm.isMergeRealityCallInProgress ? message.data.overlayId : null;
            scopeApply();
        }

        function onAudioMuteStatusChanged(data) {
            if (vm.audioStatus === data.muted) {
                vm.audioStatus = !data.muted;
                updateAudioStatus(vm.userId, !data.muted);
                scopeApply()
                var toggleAudioUserMessage = {
                    subject: 'SESSION',
                    action: 'BROADCAST',
                    type: 'AUDIO_STATUS',
                    data: {
                        audioStatus: vm.audioStatus
                    }
                };
                publishMessage(toggleAudioUserMessage);
            }
        }

        function initFullScreenHandler() {
            $document.bind('fullscreenchange', handleFullScreenEvent);
            $document.bind('mozfullscreenchange', handleFullScreenEvent);
            $document.bind('webkitfullscreenchange', handleFullScreenEvent);
        };

        function initPubNubHandler() {
            vm.convChannelName = 'conversation-' + vm.sessionModel.conversation_info.id;
            PubNubService.subscribe([vm.sessionModel.id, vm.convChannelName], false);
        };

        function updateSessionSettingsFromOverwrite() {
            var sessionSettingOverwrite = SessionPlayerService.getSessionSettings();

            if (sessionSettingOverwrite != null) {
                vm.sessionModel.settings.host_video = sessionSettingOverwrite.host_video;
            };
        };

        function isBrowserSupported() {
            if (deviceDetector.browser == 'ie') {
                return false
            } else {
                return true;
            }
        };

        function jitsiOnLoad() {
            $scope.$apply(function () {
                vm.showPlayer = true;
            });
        };

        function handleFullScreenEvent() {
            var doc = $document[0];
            $scope.$apply(function () {
                vm.isFullScreen = !!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || doc.msFullscreenElement);
            });
        };

        function handleMessage(message) {
            if (message.message.type == 'PARTICIPANT_INFO') {
                addOrUpdateSessionUser(message.message.data.sessionUser);
                updateWaitingForHost();
                vm.isGuestWaitingForHost = false;
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'END') {
                createSessionActivity('PARTICIPANT_LEFT', null);
                closeSessionPlayer();
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'SCREEN_SHARE_LOCK') {
                onScreenShareChange(message.message.data.locked)

            } else if (message.message.action == 'BROADCAST' && message.message.type == 'TOGGLE_AUDIO_ALL') {
                if (vm.audioStatus != message.message.data.audioStatus) {
                    vm.toggleAudioOfUser(vm.userId, true, false);
                };
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'AUDIO_STATUS') {
                updateAudioStatus(message.publisher, message.message.data.audioStatus);
            } else if (message.message.action == 'USER' && message.message.type == 'AUDIO_STATUS') {
                if (message.message.data.userId == vm.userId && vm.audioStatus) {
                    vm.toggleAudioOfUser(vm.userId, true, false);
                };
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'VIDEO_STATUS') {
                updateVideoStatus(message.publisher, message.message.data.videoStatus);
            } else if (message.message.action == 'USER' && message.message.type == 'VIDEO_STATUS') {
                if (message.message.data.userId == vm.userId && vm.videoStatus) {
                    vm.toggleVideoOfUser(vm.userId, true, false);
                };
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'FLASH_LIGHT_ENABLED') {
                updateFlashLightEnabled(message.publisher, message.message.data.flashLightEnabled);
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'FLASH_LIGHT_STATUS') {
                updateFlashLightStatus(message.publisher, message.message.data.flashLightStatus);
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'ANNOTATION_LOCK') {
                onAnnotationLock(message.message.data);
            } else if ((message.message.action == 'ANNOTATION_CLASSIC' || message.message.action == 'ANNOTATION_FREEZE_FRAME') && message.message.type == 'SCREENSHOT_READY') {
                vm.annotationType = 'CLASSIC';
                if (vm.isCapturing) {
                    startClassicAnnotation(message.message.data, false, message.message.action);
                } else {
                    startClassicAnnotation(message.message.data, true, message.message.action);
                }
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'FREEZE_FRAME_LOCK') {
                onFreezeFrameLock(message.message.data);
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'SCENE_READY') {
                sceneReady();
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'CAPTURE_SCREEN_SHOT') {
                handleCaptureScreenShot(message);
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'SCREEN_SHOT_CAPTURED') {
                handleScreenshotSuccess(message);
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'AR_SCREEN_SHOT_SUCCESS') {
                handleARScreenshotSuccess(message);
            } else if (message.message.action == 'BROADCAST' && (message.message.type == 'SCREEN_SHOT_FAILED' || message.message.type == 'AR_SCREEN_SHOT_FAILED')) {
                if (vm.userId === message.message.data.userId) {
                    displayToast($filter('translate')('atheer.session.sessionPlayer.screenshotFailed'), 3000);
                    vm.isCapturingScreenshot = false;
                }
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'ANNOTATION_CAPTURED') {
                displayToast(getUserName(message.message.data.userId) + ' ' + $filter('translate')('atheer.session.sessionPlayer.annotationCapture'), 3000);
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'PRIVATE_ZOOM') {
                onSharedZoom(message.message.data);
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'PARTICIPANT_ZOOM') {
                onParticipantZoom(message.message.data);
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'REMOTE_CONTROL_REQUESTED') {
                onRemoteControlRequest(message.message.data);
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'REMOTE_CONTROL_REQUEST_ACCEPTED') {
                onRemoteControlRequestAccepted(message.message.data);
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'REMOTE_CONTROL_REQUEST_REJECTED') {
                onRemoteControlRequestRejected(message.message.data);
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'REMOTE_CONTROL_STOPPED') {
                handleRemoteControlStop();
            } else if (message.message.action == 'BROADCAST' && message.message.type == 'HOST_CHANGED') {
                handleHostChange();
            };
        };

        function handleARScreenshotSuccess(message) {
            if (vm.userId === message.message.data.userId) {
                logScreenshotData(message);
                displayToast($filter('translate')('atheer.session.sessionPlayer.annotationSaved'), 3000);
            } else {
                displayToast(getUserName(message.message.data.userId) + ' ' + $filter('translate')('atheer.session.sessionPlayer.annotationCapture'), 3000);
            }
        }

        function handleScreenshotSuccess(message) {
            if (vm.userId === message.message.data.userId) {
                logScreenshotData(message);
                displayToast($filter('translate')('atheer.session.sessionPlayer.screenCaptured'), 3000);
            } else {
                displayToast(getUserName(message.message.data.userId) + ' ' + $filter('translate')('atheer.session.sessionPlayer.captured') +
                    getUserName(message.message.data.targetUserId) + $filter('translate')('atheer.session.sessionPlayer.screen'), 3000);
            }
        }

        function logScreenshotData(message) {
            vm.isCapturingScreenshot = false;
            var saveAnnotationProperties = {
                file_name: message.message.data.fileName,
                media_file_name: message.message.data.mediaFileName,
                media_id: message.message.data.mediaId
            };
            createSessionActivity('ANNOTATION_SAVED', saveAnnotationProperties);
        }

        function handleHostChange() {
            $q.all([
                Session.get({
                    id: vm.sessionNo,
                    is_session_no: true,
                    for_launch: true,
                    is_web: true
                }).$promise,
                Setting.getSessionSettings().$promise
            ])
                .then(function (results) {
                    if (!results[0].id && results[0].result === 'NOT_FOUND') {
                        displayToast($filter('translate')('atheer.session.sessionPlayer.sessionExpired'), 3000);
                        navigateBack();
                    } else {
                        vm.sessionModel = results[0];
                        if (!vm.sessionModel.settings) {
                            vm.sessionModel.settings = {};
                            setSettingSessionModel(results[1]);
                        }
                        setSettingsForSurvey(results[1]);
                        vm.changeHostInSessionUsers();
                        updateSessionSettingsFromOverwrite();
                        vm.isHost = isUserHost();
                        if (vm.isHost) {
                            displayToast($filter('translate')('atheer.session.sessionPlayer.toastMessageForHost'), 2000);
                        } else {
                            var txtContent = vm.sessionModel.owner_info.first_name + ' ' + $filter('translate')('atheer.session.sessionPlayer.toastMessageForNonHost');
                            displayToast(txtContent, 2000);
                        };
                        vm.isZoomEnabled = vm.videoStatus ? true : false;
                        vm.canAnnotate = vm.videoStatus ? true : false;
                        if (vm.recordingStatus && vm.isHost) {
                            SessionPlayerService.setFollowme(true);
                        }
                    }
                });
        }

        function uploadLocalPhoto(blob, message) {
            SessionAnnotationService.uploadScreenShotBlob(vm.sessionNo, blob).then(
                function (response) {
                    var screenshotCaptureData = {
                        userId: message.message.data.userId,
                        targetUserId: message.message.data.targetUserId,
                        fileName: response.data.name,
                        mediaFileName: response.data.properties.file_name,
                        mediaId: response.data.id
                    };
                    publishCaptureMessage('SCREEN_SHOT_CAPTURED', screenshotCaptureData);
                    displayToast(getUserName(screenshotCaptureData.userId) + ' ' + $filter('translate')('atheer.session.sessionPlayer.captured') +
                        getUserName(screenshotCaptureData.targetUserId) + $filter('translate')('atheer.session.sessionPlayer.screen'), 3000);
                }
            )
        }

        function handleScreenShotError(message) {
            publishCaptureMessage('SCREEN_SHOT_FAILED', {
                userId: message.message.data.userId,
                targetUserId: message.message.data.targetUserId
            })
        }

        function onTileViewChanged(object) {
            vm.isTileView = object.enabled;
            if (vm.isTileView) {
                vm.userName = null;
            } else {
                var sessionUserId = getUserIdByJitsiId(vm.selectedUserJitsiId)
                vm.userName = vm.userId != sessionUserId ? getUserNameByJitsiId(vm.selectedUserJitsiId) : null;
            }
            scopeApply();
        }

        function generateImageFromCanvas(message) {
            var video = document.createElement('video');
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            video.addEventListener('loadeddata', function () {
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                try {
                    video.play();
                    context.drawImage(video, 0, 0, canvas.width, canvas.height);
                    canvas.toBlob(function (blob) {
                        uploadLocalPhoto(blob, message);
                        video.remove();
                        canvas.remove();
                    }, 'image/png');

                } catch (error) {
                    handleScreenShotError(message);
                    video.remove();
                    canvas.remove();
                }
            });
            video.srcObject = vm.stream;
        }

        function initializeCamera(message) {
            if (navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia(
                    {
                        video: true,
                        audio: false
                    }).then(function (localMediaStream) {
                        vm.stream = localMediaStream;
                        if ('ImageCapture' in window) {
                            var mediaStreamTrack = localMediaStream.getVideoTracks()[0];
                            vm.imageCapture = new ImageCapture(mediaStreamTrack);
                            handleCaptureScreenShot(message)
                        } else {
                            generateImageFromCanvas(message)
                        }
                    });
            }
        }

        function handleCaptureScreenShot(message) {
            if (message.message.data.targetUserId === vm.userId) {
                if (vm.stream) {
                    if (vm.imageCapture) {
                        vm.imageCapture.takePhoto()
                            .then(function (blob) {
                                uploadLocalPhoto(blob, message);
                            })
                            .catch(function (error) {
                                handleScreenShotError(message);
                            });
                    } else {
                        if ('ImageCapture' in window) {
                            handleScreenShotError(message);
                        } else {
                            generateImageFromCanvas(message);
                        }
                    }
                } else {
                    initializeCamera(message);
                }
            }

        }

        function onRemoteControlRequest(data) {
            if (!vm.jitsiUserId) return;
            vm.isRemoteControlActive = true;
            if (data.toUserId === vm.userId) {
                vm.remoteControlledUser = vm.userId;
                vm.remoteRequestedUser = data.fromUserId;
            }
            if (data.fromUserId !== vm.userId && data.toUserId !== vm.userId) {
                var fromUserName = getUserName(data.fromUserId);
                var toUserName = getUserName(data.toUserId);
                displayToast($filter('translate')('atheer.session.sessionPlayer.remoteControlRequested', { fromUserName: fromUserName, toUserName: toUserName }), 3000);
            }
        }

        function onRemoteControlRequestAccepted(data) {
            if (data.userId !== vm.userId) {
                var userName = getUserName(data.userId);
                displayToast($filter('translate')('atheer.session.sessionPlayer.remoteControlRequestAccepted', { userName: userName }), 3000);
            }
        }

        function onRemoteControlRequestRejected(data) {
            vm.isRemoteControlActive = false;
            if (data.userId !== vm.userId) {
                var userName = getUserName(data.userId);
                displayToast($filter('translate')('atheer.session.sessionPlayer.remoteControlRequestRejected', { userName: userName }), 3000);
            } else {
                createSessionActivity('REMOTE_CONTROL_ACCESS_DENIED', null)
            }
        }

        function onScreenShareChange(locked) {
            vm.shareScreenLocked = locked;
        }

        function handleRemoteControlStop() {
            vm.isRemoteControlActive = false;
            vm.shareScreenLocked = false;
            displayToast($filter('translate')('atheer.session.sessionPlayer.remoteControlStopped', 3000));
        }

        function getUserName(id) {
            var userInfo = vm.sessionUsers.filter(function (value, index, arr) {
                return value.userId == id;
            })[0];

            return userInfo ? (userInfo.firstName ? userInfo.firstName : userInfo.lastName) : '';
        };

        function getUserNameByJitsiId(id) {
            var userInfo = vm.sessionUsers.filter(function (value, index, arr) {
                return value.jitsiUserId == id;
            })[0];

            return userInfo ? (userInfo.firstName ? userInfo.firstName + ' ' + userInfo.lastName : userInfo.lastName) : '';
        };

        function sceneReady() {
            $scope.$broadcast('scene-ready', true);
            vm.isARLoaderRequired = false;
        };

        function onFreezeFrameLock(data) {
            if (data && !data.lockState) {
                vm.isImageLoaded = false;
                vm.isArAnnotating = false;
                SessionPlayerService.annotateAR();
                vm.annotationType = vm.defaultAnnotationType;
            } else {
                vm.defaultAnnotationType = vm.annotationType;
            }
        };

        function onAnnotationLock(data) {
            vm.annotationLocked = data.lockState;
            if (!data.lockState) {
                resetVariables();
                if (data.type !== annotationTypeEnum.classic) {
                    SessionPlayerService.lockArAnnotation(vm.defaultSwitchUserId, false);
                    if (!vm.isMobileBrowser) {
                        SessionPlayerService.hideThumbnails(false);
                    }
                    vm.isARLoaderRequired = true;
                }
            } else {
                var jitsiId;
                angular.forEach(vm.sessionUsers, function (sessionUser) {
                    if (sessionUser.userId === data.targetUser) {
                        jitsiId = sessionUser.jitsiUserId;
                    }
                });
                if (data.type !== annotationTypeEnum.classic) {
                    //switch the user video to target user
                    vm.defaultSwitchUserId = vm.selectedUserJitsiId;
                    SessionPlayerService.lockArAnnotation(jitsiId, true);
                    vm.annotationType = data.type;
                } else {
                    if (vm.userId === data.targetUser && vm.isNativeImageCaptureFeature) {
                        SessionPlayerService.pinParticipant(jitsiId);
                        $timeout(function () {
                            SessionPlayerService.annotate(true);
                        }, 1000);
                        vm.isCapturing = false;
                    } else {
                        vm.isCapturing = true;
                    }
                }

            }
        };

        function startClassicAnnotation(data, isEditEnabled, actionType) {
            var videoFullFrame = $element.find('.fp-iframe-player')[0];
            vm.annotationData = {
                screenShot: data.url,
                originalImageWidth: data.width,
                originalImageHeight: data.height,
                currentImageWidth: videoFullFrame.clientWidth,
                currentImageHeight: videoFullFrame.clientHeight,
                allowAnnotationEdit: isEditEnabled,
                action: actionType
            };
            vm.isAnnotating = true;
            vm.isZoomEnabled = false;
            vm.zoomValue = 100;
            vm.enableZoom = false;
            scopeApply();
        };

        function startARAnnotation(data, isEditEnabled) {
            vm.annotationData = {
                videoWidth: data.width,
                videoHeight: data.height,
                allowAnnotationEdit: isEditEnabled,
                type: vm.annotationType
            };
            vm.isArAnnotating = true;
            vm.isZoomEnabled = false;
            vm.zoomValue = 100;
            vm.isEditEnabled = isEditEnabled;
            SessionPlayerService.hideThumbnails(true);
            if (!vm.isARLoaderRequired) {
                vm.annotationData.annotationFrom = 'FREEZE_FRAME'
            }
            vm.defaultZoom = vm.zoom;
            vm.zoom = 1;
            vm.enableZoom = false;
            SessionPlayerService.applyZoom(0.5, 0.5, 1, vm.selectedUserJitsiId);
            scopeApply();
        };

        function closeSessionPlayer() {
            SessionPlayerService.hangup();
            SessionPlayerService.destroy();
            PubNubService.unsubscribe([vm.sessionModel.id]);
            PubNubService.unsubscribe([vm.convChannelName]);
            $rootScope.selectedLeftNavMenu = 'airsessions';
            if (!vm.isGuest) {
                if (vm.sessionModel.settings.enable_external_survey) {
                    $mdDialog.show({
                        templateUrl: 'modules/session/session-survey-dialog.tmpl.html',
                        clickOutsideToClose: true,
                        controller: 'SessionSurveyController',
                        controllerAs: 'vm',
                        onRemoving: closeSurvey,
                        locals: {
                            externalUrl: vm.sessionModel.settings.external_survey_url
                        }
                    });
                } else {
                    navigateBack();
                }
            } else {

                Auth.logout();
                $cookies.remove('isGuest');
                navigateBack()

            }
        };

        function closeSurvey() {
            navigateBack();
        };

        function navigateBack() {
            var context = sessionStorage.getItem('context');
            if (context === 'home') {
                sessionStorage.removeItem('context');
                var homeState = Auth.isPublicUser() ? 'public.home' : 'storm.home';
                $state.go(homeState)
            } else {
                var state = vm.isGuest ? 'account.guest-join' : 'storm.sessions.upcoming';
                $state.go(state);
            }
        }

        function publishMessage(message) {
            PubNubService.publish(vm.sessionModel.id, message);
        };

        function createSessionActivity(activityType, properties) {
            Experience.createSessionActivity({
                object: vm.sessionModel,
                user_id: Principal.getUserId(),
                activity_type: activityType,
                properties: properties
            }, function (result) {

            });
        };

        function updateSessionConversation() {
            Conversation.get({
                id: vm.sessionModel.conversation_info.id,
            }, onGetSuccess, onGetError);

            function onGetSuccess(result) {
                vm.sessionModel.conversation_info = result

            };

            function onGetError() {

            };
        };

        function onVideoConferenceJoined(object) {
            vm.userName = null;
            vm.jitsiUserId = object.id;
            vm.selectedUserJitsiId = object.id;
            addOrUpdateSessionUser(getLocalSessionUser(object.id));
            SessionPlayerService.setTileView(false);
        };

        function onVideoConferenceLeft(object) {
            removeSessionUser(object.id);
        };

        function onParticipantJoined(object) {
            addOrUpdateSessionUser(getSessionUser(object.id, object.displayName));
            updateSessionConversation();
            $timeout(function () {
                publishLocalUserInfo();
            }, 2000);
        };

        function onParticipantLeft(object) {
            var participant = getUserIdByJitsiId(object.id);
            if (vm.isRemoteControlPermissionRequested && participant === vm.remoteControlledUser) {
                onRemoteControlStopped();
            } else if (vm.isRemoteControlActive && participant === vm.remoteRequestedUser && vm.remoteControlledUser === vm.userId) {
                SessionPlayerService.toggleShareScreen();
                onRemoteControlStopped();
            }



            removeSessionUser(object.id);
            if (vm.isArAnnotating && vm.annotationType !== annotationTypeEnum.classic) {
                vm.isArAnnotating = false;
                SessionPlayerService.lockArAnnotation(vm.defaultSwitchUserId, false);
                if (!vm.isMobileBrowser) {
                    SessionPlayerService.hideThumbnails(false);
                }
            }
        };

        function screenSharingStatusChanged(object) {
            var shareScreenMessage = {
                subject: 'SESSION',
                action: 'BROADCAST',
                type: 'SCREEN_SHARE_LOCK',
                data: {
                    locked: object.on
                }
            };
            publishMessage(shareScreenMessage);

            if (object.on) {
                createSessionActivity('SCREEN_SHARE_STARTED', null);
            } else {
                createSessionActivity('SCREEN_SHARE_ENDED', null);
            };
            vm.isScreenSharing = object.on ? true : false;
        };

        function resetRecordingState() {
            vm.recordingStatus = false;
            vm.loadingRecorder = false;

            displayToast($filter('translate')('atheer.session.sessionPlayer.recordingDidntStart'), 3000);

            SessionPlayerService.stopRecording();
        }

        function recorderStatusChanged(object) {
            if (vm.jitsiRecorderStatus == null) {
                vm.jitsiRecorderStatus = object.sessionData.status;
            } else if (vm.jitsiRecorderStatus == object.sessionData.status) {
                return;
            };

            vm.jitsiRecorderStatus = object.sessionData.status;

            if (object.sessionData.status == 'on') {
                if (!vm.recordingStatus) {
                    //cancel recording state timer
                    if (vm.recordingTimer) {
                        $timeout.cancel(vm.recordingTimer);
                    }
                    vm.recordingStatus = true;
                    vm.loadingRecorder = false;
                    vm.hasRecording = true;

                    var sessionActivityProperties = {
                        jitsi_id: object.sessionData.id,
                        status: object.sessionData.status
                    };
                    if (vm.isHost) {
                        displayToast($filter('translate')('atheer.session.sessionPlayer.recordingHasStarted'), 2000);
                    }
                    else {
                        dispalyRecordingMessagePrivacy(sessionActivityProperties);
                    }

                    if (vm.isHost) {
                        createSessionActivity('RECORDING_STARTED', sessionActivityProperties);
                    }
                    if (isMergeRealityCallInProgress) {
                        SessionPlayerService.disableMergeReality();
                    }
                }
            } else if (object.sessionData.status == 'off') {
                if (vm.recordingStatus) {
                    //cancel recording state timer
                    if (vm.recordingTimer) {
                        $timeout.cancel(vm.recordingTimer);
                    }
                    vm.recordingStatus = false;
                    vm.loadingRecorder = false;

                    displayToast($filter('translate')('atheer.session.sessionPlayer.recordingHasStopped'), 2000);
                    if (vm.recordingContentDialogOpen) {
                        $mdDialog.cancel();
                        vm.recordingContentDialogOpen = false;
                    }
                    var sessionActivityProperties = {
                        jitsi_id: object.sessionData.id,
                        status: object.sessionData.status,
                    };
                    if (vm.isHost) {
                        createSessionActivity('RECORDING_ENDED', sessionActivityProperties);
                    }
                }
            } else if (object.sessionData.status == 'pending') {
                vm.recordingStatus = vm.recordingStatus;
                vm.loadingRecorder = vm.loadingRecorder;

                displayToast($filter('translate')('atheer.session.sessionPlayer.preparingToStart'), 2000);
            };
        };

        function dispalyRecordingMessagePrivacy(sessionActivityProperties) {
            if (vm.recordingContentFeature) {
                vm.recordingContentDialogOpen = true;
                $mdDialog.show({
                    templateUrl: 'modules/session/session-recording-message-privacy.tmpl.html',
                    controller: 'SessionPlayerRecordingMessageController',
                    clickOutsideToClose: false,
                    controllerAs: 'vm',
                    locals: { mainData: vm },
                    bindToController: true,
                }).then(function (result) {
                    vm.recordingContentDialogOpen = false;
                    if (result) {
                        createSessionActivity('RECORDING_CONSENTED', sessionActivityProperties);
                    }
                    else {
                        createSessionActivity('RECORDING_DECLINED', sessionActivityProperties);
                        createSessionActivity('PARTICIPANT_LEFT', null);
                        closeSessionPlayer();
                    }
                });
            }
        }

        function publishLocalUserInfo() {
            var scale = 1, tx = 0.5, ty = 0.5;
            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (vm.userId == sessionUser.userId) {
                    scale = sessionUser.scale;
                    tx = sessionUser.tx;
                    ty = sessionUser.ty;
                }
            });
            var sessionUser = {
                jitsiUserId: vm.jitsiUserId,
                userId: vm.userId,
                isHost: vm.isHost,
                firstName: vm.userInfo.first_name,
                lastName: vm.userInfo.last_name,
                shortName: vm.userShortName,
                audioStatus: vm.audioStatus,
                videoStatus: vm.videoStatus,
                hasFlashLight: false,
                flashLightStatus: false,
                hasSlam: false,
                isBrowser: true,
                isGuest: vm.isGuest ? true : false,
                pictureId: vm.userInfo.picture_id,
                aspectRatio: 1,
                scale: scale,
                tx: tx,
                ty: ty,
                isSharedZoomDisabled: vm.isSharedZoomDisabled,
                isGlasses: false,
                isRemoteControlActive: vm.isRemoteControlActive
            };

            var userJoinedMessage = {
                subject: 'SESSION',
                action: 'BROADCAST',
                type: 'PARTICIPANT_INFO',
                data: {
                    sessionUser: sessionUser
                }
            };
            publishMessage(userJoinedMessage);
        };

        function updateAudioStatus(userId, audioStatus) {
            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (userId == sessionUser.userId) {
                    sessionUser.audioStatus = audioStatus;
                };
            });
        };

        function updateAudioOfAllUsers(audioStatus) {
            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (vm.userId != sessionUser.userId) {
                    sessionUser.audioStatus = audioStatus;
                };
            });
        };

        function updateVideoStatus(userId, videoStatus) {
            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (userId == sessionUser.userId) {
                    sessionUser.videoStatus = videoStatus;

                    if (videoStatus && sessionUser.jitsiUserId === vm.baseId) {
                        vm.isWaitingForOtherUserFeed = false;
                    }

                    if (sessionUser.jitsiUserId == vm.selectedUserJitsiId) {
                        vm.canAnnotate = videoStatus ? true : false;
                        vm.isZoomEnabled = !vm.isZoomEnabled;
                        if (!vm.isZoomEnabled) {
                            vm.enableZoom = false;
                            vm.zoomValue = 100;
                        }
                        if (vm.isZoomEnabled) {
                            vm.enableZoom = true;
                        }
                    };
                };
            });
            if (vm.sessionUsers.length === 0) {
                vm.isZoomEnabled = videoStatus ? true : false;
                vm.canAnnotate = videoStatus ? true : false;
            }
        };

        function updateFlashLightEnabled(userId, flashLightEnabled) {
            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (userId == sessionUser.userId) {
                    sessionUser.hasFlashLight = flashLightEnabled;
                };
            });
        };

        function updateFlashLightStatus(userId, flashLightStatus) {
            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (userId == sessionUser.userId) {
                    sessionUser.flashLightStatus = flashLightStatus;
                };
            });
        };

        function addOrUpdateSessionUser(sessionUser) {
            if (sessionUser.isSharedZoomDisabled) {
                vm.isSharedZoomDisabled = sessionUser.isSharedZoomDisabled;
            }

            if (sessionUser.isRemoteControlActive) {
                vm.isRemoteControlActive = sessionUser.isRemoteControlActive;
            }

            //vm.isSharedZoomDisabled = sessionUser.isSharedZoomDisabled;
            var updatedSessionUsers = vm.sessionUsers.filter(function (value, index, arr) {
                return value.userId != sessionUser.userId;
            });
            updatedSessionUsers.push(sessionUser);
            vm.sessionUsers = updatedSessionUsers;

            //to set zoom for newly added participant
            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (vm.isSharedZoomDisabled) {
                    SessionPlayerService.applyZoom(sessionUser.tx, sessionUser.ty, sessionUser.scale, sessionUser.jitsiUserId)
                }
            });

            if (vm.isSharedZoomDisabled) {
                SessionPlayerService.setSharedZoom(vm.isSharedZoomDisabled);
                vm.isZoomToolsEnabled = vm.userId == getUserId() || getUserId() == undefined ? true : false;
            }

            var userExist = false;

            if (vm.sessionModel.owner == sessionUser.userId) {
                userExist = true;
            };

            if (!userExist) {
                angular.forEach(vm.sessionModel.session_users, function (sessionUserTemp) {
                    if (sessionUserTemp.user_id == sessionUser.userId) {
                        userExist = true;
                    };
                });
            };

            if (!userExist) {
                var newUser = null;
                if (sessionUser.isHost) {
                    newUser = {
                        user_id: sessionUser.userId,
                        role: "HOST",
                        is_guest: sessionUser.isGuest
                    }
                } else {
                    newUser = {
                        user_id: sessionUser.userId,
                        role: "PARTICIPANT",
                        is_guest: sessionUser.isGuest
                    }
                }
                vm.sessionModel.session_users.push(newUser);
            }
        };

        function removeSessionUser(jitsiUserId) {
            var updatedSessionUsers = vm.sessionUsers.filter(function (value, index, arr) {
                return value.jitsiUserId != jitsiUserId;
            });
            vm.sessionUsers = updatedSessionUsers;
            scopeApply();
        };

        function isUserHost() {
            var isUserHost = false;
            if (vm.sessionModel.owner == vm.userId) {
                isUserHost = true;
            } else {
                if (vm.sessionModel.session_users && vm.sessionModel.session_users != null) {
                    angular.forEach(vm.sessionModel.session_users, function (sessionUser) {
                        if (sessionUser.user_id == vm.userId && sessionUser.role == 'HOST') {
                            isUserHost = true;
                        };
                    });
                };
            };
            return isUserHost;
        };

        function updateWaitingForHost() {
            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (sessionUser.isHost) {
                    vm.waitingForHost = false;
                    return;
                };
            });
        };

        function screenShotReady(screenShotData) {
            SessionAnnotationService.uploadScreenShot(vm.sessionNo, screenShotData.imageData).then(function (response) {
                if (screenShotData.isClassicAnnotation) {
                    var data = {
                        url: response.data.properties.file_name,
                        width: screenShotData.imageData.width,
                        height: screenShotData.imageData.height
                    };
                    if (!vm.isFreezeFrame) {
                        var action = vm.isFreezeFrame ? 'ANNOTATION_FREEZE_FRAME' : 'ANNOTATION_CLASSIC';
                        if (vm.isCapturing) {
                            startClassicAnnotation(data, true, action);
                        } else {
                            startClassicAnnotation(data, false, action);
                        }
                        var receiveAnnotation = {
                            subject: 'SESSION',
                            action: action,
                            type: 'SCREENSHOT_READY',
                            data: data
                        };
                        publishMessage(receiveAnnotation);
                    }
                    vm.isFreezeFrame = false;
                    if (vm.isArAnnotating) {
                        var annotationCaptureData = {
                            userId: vm.userId
                        };
                        publishCaptureMessage('ANNOTATION_CAPTURED', annotationCaptureData);
                        saveImageInSessionActivity('ANNOTATION_SAVED', response);
                        $scope.$broadcast('show-loader', false);
                        displayToast($filter('translate')('atheer.session.sessionPlayer.annotationSaved'), 3000);
                    }
                } else {
                    var screenshotCaptureData = {
                        userId: vm.userId,
                        targetUserId: getUserId()
                    };
                    publishCaptureMessage('SCREEN_SHOT_CAPTURED', screenshotCaptureData);
                    saveImageInSessionActivity('ANNOTATION_SAVED', response);
                    displayToast($filter('translate')('atheer.session.sessionPlayer.screenCaptured'), 3000);
                    vm.isCapturingScreenshot = false;
                }
            }).catch(function (err) {
                displayToast($filter('translate')('atheer.session.sessionPlayer.screenshotFailed'), 3000);
                var closeAnnotationMessage = {
                    subject: 'SESSION',
                    action: 'BROADCAST',
                    type: 'ANNOTATION_LOCK',
                    data: {
                        lockState: false
                    }
                };
                resetVariables();
                publishMessage(closeAnnotationMessage);
                createSessionActivity('ANNOTATION_ENDED', null);
            });
        };

        function publishCaptureMessage(type, data) {
            var screenshotCaptureMessage = {
                subject: 'SESSION',
                action: 'BROADCAST',
                type: type,
                data: data
            };
            publishMessage(screenshotCaptureMessage);
        };

        function saveImageInSessionActivity(activityName, response) {
            var saveAnnotationProperties = {
                file_name: response.data.name,
                media_file_name: response.data.properties.file_name,
                media_id: response.data.id
            };

            createSessionActivity(activityName, saveAnnotationProperties);
        };

        function resetVariables() {
            vm.isArAnnotating = false;
            vm.isAnnotating = false;
            vm.isAnnotationSaved = false;
            vm.isAnnotationView = false;
            vm.isImageLoaded = false;
            vm.isZoomEnabled = true;
            vm.isFreezeFrame = false;
            vm.zoom = (vm.defaultZoom > 1) ? vm.defaultZoom : vm.zoom;
            vm.enableZoom = (vm.zoom > 1 || vm.defaultZoom > 1) ? true : false;
            vm.defaultZoom = 1;
            vm.isCapturing = false;
            vm.isCapturingScreenshot = false;
        };

        function videoDimensionsReady(videoData) {
            var annotationData = {
                lockState: true,
                targetAspectRatio: 1,
                type: vm.annotationType,
                targetUser: getUserId(),
            };

            var annotationLockMessage = {
                subject: 'SESSION',
                action: 'BROADCAST',
                type: 'ANNOTATION_LOCK',
                data: annotationData
            };
            publishMessage(annotationLockMessage);
            SessionPlayerService.setAnnotationMode(true);
            startARAnnotation(videoData.data, true);
            createSessionActivity('ANNOTATION_STARTED', null);
        };

        function onStageParticipantChanged(user) {
            vm.selectedUserJitsiId = user.id;
            //handling virtual screeshare participant
            if (vm.selectedUserJitsiId && vm.selectedUserJitsiId.indexOf("-") !== -1) {
                SessionPlayerService.applyZoom(0.5, 0.5, 1, vm.selectedUserJitsiId);
                vm.zoom = 1;
                vm.isZoomEnabled = false;
                vm.enableZoom = false;
                vm.zoomValue = 100;
                vm.canAnnotate = false;
                vm.isFakeParticipantOnStage = true;
                vm.isARSupported = false;
                vm.hasSlam = false;
                vm.userHasRemoteControlSupport = false;
                vm.isZoomToolsEnabled = false;
                scopeApply();
                return;
            }


            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (vm.selectedUserJitsiId === sessionUser.jitsiUserId) {
                    if (sessionUser.videoStatus) {
                        if (!vm.isSharedZoomDisabled || (vm.isSharedZoomDisabled && sessionUser.scale <= 1)) {
                            sessionUser.scale = 1;
                            SessionPlayerService.applyZoom(0.5, 0.5, sessionUser.scale, vm.selectedUserJitsiId)
                        } else if (vm.isSharedZoomDisabled && sessionUser.scale > 1) {
                            SessionPlayerService.applyZoom(sessionUser.tx, sessionUser.ty, sessionUser.scale, vm.selectedUserJitsiId);
                        }
                        vm.enableZoom = !vm.isSharedZoomDisabled ? false : (vm.userId == sessionUser.userId ? true : false);
                        vm.zoom = sessionUser.scale;
                        vm.zoomValue = Math.floor(sessionUser.scale * 100);
                        vm.isZoomEnabled = true;
                        vm.zoomSlider = vm.zoom * 100;
                    } else {
                        vm.zoom = 1;
                        sessionUser.scale = 1;
                        vm.isZoomEnabled = false;
                        vm.enableZoom = false;
                        vm.zoomValue = 100;
                    }

                    vm.hasSlam = sessionUser.hasSlam ? true : false;
                    vm.canAnnotate = (!sessionUser.videoStatus) ? false : true;
                    vm.isARSupported = (!sessionUser.isBrowser && vm.isLiveAnnotationFeatureEnabled) ? true : false;
                    vm.userName = vm.userId != sessionUser.userId ? sessionUser.firstName + " " + sessionUser.lastName : null;
                    vm.isZoomToolsEnabled = !vm.isSharedZoomDisabled ? true : (vm.userId == sessionUser.userId ? true : false);
                    vm.userHasRemoteControlSupport = vm.remoteControlAccessMap[vm.selectedUserJitsiId];
                    vm.isFakeParticipantOnStage = false;


                    scopeApply();
                }
            });
        };

        function arAnnotationReady(videoData) {
            startARAnnotation(videoData.data, false);
        };

        function resizeVideoDimentions(data) {
            $scope.$broadcast('resize-video', data);
        };

        function onUserSupportsRemoteControl(msg) {
            var data = msg.data;
            vm.remoteControlAccessMap[data.id] = data.supportsRemoteControl;
            scopeApply();
        }

        function onRemoteControlPermissionUpdate(msg) {
            var data = msg.data;
            var remoteControlMessage = {
                subject: 'SESSION',
                action: 'BROADCAST',
                type: (data.permisson) ? 'REMOTE_CONTROL_REQUEST_ACCEPTED' : 'REMOTE_CONTROL_REQUEST_REJECTED',
                data: {
                    userId: vm.remoteControlledUser
                }
            }

            var userName = getUserName(vm.remoteControlledUser);
            if (!data.permisson) {
                vm.remoteControlledUser = null;
                vm.isRemoteControlActive = false;
                if (vm.isRemoteControlPermissionRequested) {
                    publishMessage(remoteControlMessage);
                    displayToast($filter('translate')('atheer.session.sessionPlayer.remoteControlRequestRejected', { userName: userName }), 3000);
                }
            } else {
                createSessionActivity('REMOTE_CONTROL_STARTED', null);
                publishMessage(remoteControlMessage);
                displayToast($filter('translate')('atheer.session.sessionPlayer.remoteControlRequestAccepted', { userName: userName }), 3000);
            }
            vm.isRemoteControlPermissionRequested = false;
            vm.isRemoteControlPermissionGranted = data.permisson;
            scopeApply();
        }

        function onRemoteControlStopped() {
            var remoteControlMessage = {
                subject: 'SESSION',
                action: 'BROADCAST',
                type: 'REMOTE_CONTROL_STOPPED'
            }
            createSessionActivity('REMOTE_CONTROL_ENDED', null);
            publishMessage(remoteControlMessage);
            vm.isRemoteControlPermissionGranted = false;
            vm.remoteControlledUser = null;
            vm.remoteRequestedUser = null;
            vm.isRemoteControlActive = false;
            vm.shareScreenLocked = false;
            scopeApply();
        }

        function getUserId() {
            var userId = getUserIdByJitsiId(vm.selectedUserJitsiId);
            return userId;
        };

        function getUserIdByJitsiId(jitsiId) {
            var userId;

            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (sessionUser.jitsiUserId === jitsiId) {
                    userId = sessionUser.userId;
                }
            });
            return userId;
        };

        function getLocalSessionUser(jitsiUserId) {
            var sessionUser = {
                jitsiUserId: jitsiUserId,
                userId: vm.userId,
                firstName: vm.userInfo.first_name,
                lastName: vm.userInfo.last_name,
                shortName: vm.userShortName,
                isHost: vm.isHost,
                audioStatus: vm.audioStatus,
                videoStatus: vm.videoStatus,
                hasFlashLight: false,
                flashLightStatus: false,
                hasSlam: false,
                isBrowser: true,
                isGuest: vm.isGuest ? true : false,
                aspectRatio: 1,
                scale: 1,
                tx: 0.5,
                ty: 0.5,
                isSharedZoomDisabled: vm.isSharedZoomDisabled,
                pictureId: vm.userInfo.picture_id
            };
            return sessionUser;
        };

        function getSessionUser(jitsiUserId, jitsiDisplayName) {
            var userStatusInfo = jitsiDisplayName.split(':');

            var userId = userStatusInfo[0];
            var userInfo = userStatusInfo[1];
            var shortName = userStatusInfo[2];
            var fullName = userStatusInfo[3];
            var firstName = fullName.split(' ')[0];
            var lastName = fullName.split(' ')[1];

            var isHost = vm.sessionModel.owner === userId; //userInfo.charAt(0) == '1';
            var audioStatus = userInfo.charAt(1) == '1';
            var videoStatus = userInfo.charAt(2) == '1';
            var hasFlashLight = userInfo.charAt(3) == '1';
            var flashLightStatus = userInfo.charAt(4) == '1';
            var hasSlam = userInfo.charAt(5) == '1';
            var isBrowser = userInfo.charAt(6) == '1';
            var isGuest = userInfo.charAt(7) == '1';
            var aspectRatio = parseInt(userInfo.charAt(8));
            var scale = parseInt(userInfo.charAt(9));
            var tx = userInfo.charAt(10) == '1' ? 0.5 : 0;
            var ty = userInfo.charAt(11) == '1' ? 0.5 : 0;
            var isSharedZoomDisabled = userInfo.charAt(12) == '1';

            var sessionUser = {
                jitsiUserId: jitsiUserId,
                userId: userId,
                firstName: firstName,
                lastName: lastName,
                shortName: shortName,
                isHost: isHost,
                audioStatus: audioStatus,
                videoStatus: videoStatus,
                hasFlashLight: hasFlashLight,
                flashLightStatus: flashLightStatus,
                hasSlam: hasSlam,
                isBrowser: isBrowser,
                isGuest: isGuest,
                aspectRatio: aspectRatio,
                scale: scale,
                tx: tx,
                ty: ty,
                isSharedZoomDisabled: isSharedZoomDisabled
            };
            return sessionUser;
        };

        function getLocalUserStatusInfo() {
            var userInfo = '0000000000000';

            //host
            if (vm.isHost) {
                userInfo = replaceAt(userInfo, 0, 1);
            };

            //audio status
            if (vm.audioStatus) {
                userInfo = replaceAt(userInfo, 1, 1);
            };

            //video status
            if (vm.videoStatus) {
                userInfo = replaceAt(userInfo, 2, 1);
            };

            //has flash light
            userInfo = replaceAt(userInfo, 3, 0);

            //flash light status
            userInfo = replaceAt(userInfo, 4, 0);

            //has slam
            userInfo = replaceAt(userInfo, 5, 0);

            //is browser
            userInfo = replaceAt(userInfo, 6, 1);

            //is Guest
            if (vm.isGuest) {
                userInfo = replaceAt(userInfo, 7, 1);
            }

            //aspect ratio
            userInfo = replaceAt(userInfo, 8, 1);

            //zoom
            userInfo = replaceAt(userInfo, 9, 1);

            //tx
            userInfo = replaceAt(userInfo, 10, 1);

            //ty
            userInfo = replaceAt(userInfo, 11, 1);

            //is private zoom disabled
            userInfo = replaceAt(userInfo, 12, 0);

            return userInfo;
        };

        function replaceAt(str, index, chr) {
            if (index > str.length - 1) {
                return str;
            }
            return str.substring(0, index) + chr + str.substring(index + 1);
        };

        function generateUserDisplayName() {
            var displayName = vm.userId;
            displayName = displayName + ':' + getLocalUserStatusInfo();
            displayName = displayName + ':' + vm.userShortName;

            if (vm.userInfo.first_name) {
                displayName = displayName + ':' + vm.userInfo.first_name;
            }

            if (vm.userInfo.last_name) {
                displayName = displayName + ' ' + vm.userInfo.last_name;
            } else {
                displayName = displayName + ' ' + '.';
            }
            return displayName;
        };

        function generateDisplayPictureURL() {
            if (vm.userInfo.picture_id) {
                return 'https://' + window.location.host + '/media/pictures/' + vm.userInfo.picture_id;
            }
            return '';
        };

        function checkForGuestUsers() {
            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (sessionUser.isGuest) {
                    addOrUpdateGuestUser(sessionUser);
                }
            });
        };

        function addOrUpdateGuestUser(sessionUser) {
            angular.forEach(vm.sessionModel.session_users, function (sessionUserTemp) {
                if (sessionUserTemp.user_id == sessionUser.userId) {
                    sessionUserTemp.is_guest = sessionUser.isGuest;
                }
            });
        };

        function displayToast(textContent, delay) {
            ToastService.displayToast(textContent, delay);
        };

        function onVideoMuteStatusChanged(data) {
            if (data.muted === vm.videoStatus) {
                vm.videoStatus = !data.muted;
                updateVideoStatus(vm.userId, !data.muted)
                scopeApply();
                var toggleVideoUserMessage = {
                    subject: 'SESSION',
                    action: 'BROADCAST',
                    type: 'VIDEO_STATUS',
                    data: {
                        videoStatus: vm.videoStatus
                    }
                };
                publishMessage(toggleVideoUserMessage);
            }

        };

        function getReadMessagesCount() {
            Experience.getReadMessages({
                userId: vm.userId,
                conversationId: vm.sessionModel.conversation_info.id
            }, function (readMessage) {
                if (readMessage && readMessage.object && readMessage.object.id == vm.sessionModel.conversation_info.id) {
                    vm.messagesCount = readMessage.properties.count;
                }
            });
        };

        function setMessagesCount(event, message) {
            // var messageAlert = new Audio('/assets/audio/message-received-sound.mp3');
            if (vm.showConversation) {
                getReadMessagesCount();
                // messageAlert.play();
            } else {
                if (message.message.data.conversation_id == vm.sessionModel.conversation_info.id) {
                    vm.messagesCount = vm.messagesCount + 1;
                    // messageAlert.play();
                } else {
                    NotificationHandleService.onNotification(message);
                }
            }
        };

        function getUnReadMessageCount() {
            Experience.getUnReadMessageCount({
                id: vm.userId
            }, function (unReadMessages) {
                angular.forEach(unReadMessages, function (unReadMessage) {
                    if (unReadMessage && unReadMessage.object && unReadMessage.object.id == vm.sessionModel.conversation_info.id) {
                        vm.messagesCount = unReadMessage.properties.count;
                    }
                });
            });
        };

        function getDefaultLanaguage() {
            var jitsiSupportedLanguageCodes = [
                { stormCode: "en_US", jitsiCode: "en" },
                { stormCode: "en_GB", jitsiCode: "enGB" },
                { stormCode: "fr_FR", jitsiCode: "fr" },
                { stormCode: "de_DE", jitsiCode: "de" },
                { stormCode: "el_GR", jitsiCode: "el" },
                { stormCode: "it_IT", jitsiCode: "it" },
                { stormCode: "ja_JP", jitsiCode: "ja" },
                { stormCode: "pt_BR", jitsiCode: "ptBR" },
                { stormCode: "es_CL", jitsiCode: "es" },
                { stormCode: "zh_TW", jitsiCode: "zhTW" },
                { stormCode: "zh_CN", jitsiCode: "zhCN" },
                { stormCode: "nl_NL", jitsiCode: 'nl' },
                { stormCode: "da_DK", jitsiCode: 'da' },
                { stormCode: "no_NO", jitsiCode: 'en' },//JITSI Doesnt support Norwegein
                { stormCode: "pl_PL", jitsiCode: 'pl' },
                { stormCode: "ru_RU", jitsiCode: 'ru' },
                { stormCode: "sv_SE", jitsiCode: 'sv' },
                { stormCode: "tr_TR", jitsiCode: 'tr' }
            ];
            var language = $cookies.getObject('NG_TRANSLATE_LANG_KEY');
            var defaultLanguage = jitsiSupportedLanguageCodes.filter(function (lang) {
                return lang.stormCode == language;
            })[0];

            return defaultLanguage.jitsiCode;
        };

        function publishSharedZoomDisabled() {
            var privateZoomEnabledMessage = {
                subject: 'SESSION',
                action: 'BROADCAST',
                type: 'PRIVATE_ZOOM',
                data: {
                    userId: getUserId(),
                    disable: vm.isSharedZoomDisabled
                }
            };
            publishMessage(privateZoomEnabledMessage);
        };

        function publishParticipantZoomData() {
            if (vm.isSharedZoomDisabled && vm.userId == getUserId()) {
                var scale, tx, ty;
                angular.forEach(vm.sessionUsers, function (sessionUser) {
                    if (vm.userId == sessionUser.userId) {
                        scale = sessionUser.scale;
                        tx = sessionUser.tx;
                        ty = sessionUser.ty;
                    }
                });
                var participantZoomMessage = {
                    subject: 'SESSION',
                    action: 'BROADCAST',
                    type: 'PARTICIPANT_ZOOM',
                    data: {
                        userId: vm.userId,
                        scale: scale,
                        tx: tx,
                        ty: ty
                    }
                };
                publishMessage(participantZoomMessage);
            }
        };

        function resetZoom() {
            angular.forEach(vm.sessionUsers, function (sessionUser) {
                sessionUser.scale = 1;
                sessionUser.tx = 0.5;
                sessionUser.ty = 0.5;
                SessionPlayerService.applyZoom(0.5, 0.5, 1, sessionUser.jitsiUserId);
                if (vm.selectedUserJitsiId == sessionUser.jitsiUserId) {
                    vm.enableZoom = vm.userId == sessionUser.userId ? true : false;
                    vm.zoom = sessionUser.scale;
                    vm.zoomValue = Math.floor(sessionUser.scale * 100);
                    vm.zoomSlider = vm.zoom * 100;
                }
            });
            vm.isZoomToolsEnabled = !vm.isSharedZoomDisabled ? true : (vm.userId == getUserId() ? true : false);
        };

        function onParticipantZoom(data) {
            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (sessionUser.userId === data.userId) {
                    sessionUser.scale = data.scale;
                    sessionUser.tx = data.tx;
                    sessionUser.ty = data.ty;
                    SessionPlayerService.applyZoom(data.tx, data.ty, data.scale, sessionUser.jitsiUserId);
                }
            });
        };

        function onSharedZoom(data) {
            vm.isSharedZoomDisabled = data.disable;
            SessionPlayerService.setSharedZoom(data.disable);
            var message = data.disable ? $filter('translate')('atheer.session.sessionPlayer.sharedZoom.disabledToastMessage') : $filter('translate')('atheer.session.sessionPlayer.sharedZoom.enabledToastMessage');
            vm.isZoomToolsEnabled = !vm.isSharedZoomDisabled ? true : (vm.userId == getUserId() ? true : false);
            displayToast(message);
            resetZoom();
        };

        function updateRolesforSessionUsers(upcomingHostData) {
            var users = vm.sessionModel.session_users;
            for (var i = 0; i < users.length; i++) {
                if (users[i].user_id === upcomingHostData.userId) {
                    vm.sessionModel.session_users.splice(i, 1);
                    break;
                }
            }

            var user = {
                user_id: vm.userId,
                role: "PARTICIPANT",
                is_guest: vm.isGuest ? true : false
            }
            vm.sessionModel.session_users.push(user);
            vm.sessionModel.owner = upcomingHostData.userId;
        }

        vm.startLocalImageShareAnnotation = function (data) {
            startClassicAnnotation(data, true, "ANNOTATION_CLASSIC");
            var receiveAnnotation = {
                subject: 'SESSION',
                action: 'ANNOTATION_CLASSIC',
                type: 'SCREENSHOT_READY',
                data: data
            };
            publishMessage(receiveAnnotation);
        };

        vm.sendAnnotationLockMessageForLocalImage = function () {
            if (!vm.isFreezeFrame) {
                var annotationData = {
                    lockState: true,
                    targetAspectRatio: 1.0,
                    type: 'CLASSIC',
                    targetUser: vm.userId,
                };

                var annotationLockMessage = {
                    subject: 'SESSION',
                    action: 'BROADCAST',
                    type: 'ANNOTATION_LOCK',
                    data: annotationData
                };
                publishMessage(annotationLockMessage);
                createSessionActivity('ANNOTATION_STARTED', null);
                vm.annotationType = annotationTypeEnum.classic;
                vm.isAnnotationView = true;
                vm.isAnnotationSaved = false;
            }
        };

        vm.makeAlternateUserHost = function (userData) {
            $mdDialog.show({
                templateUrl: 'modules/session/session-player-make-alternate-host-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: 'SessionPlayerMakeAlternateHostController',
                controllerAs: 'vm',
                locals: {
                    userId: userData.userId,
                    userName: userData.firstName,
                    isMobileBrowser: vm.isMobileBrowser,
                }
            }).then(function (stat) {
                vm.updateHostAndInitiatePubnum(userData);
            });
        };

        vm.updateHostAndInitiatePubnum = function (userData) {
            updateRolesforSessionUsers(userData);
            Session.update(vm.sessionModel, function (result) {
                if (!result.id && result.result === 'NOT_FOUND') {
                    displayToast($filter('translate')('atheer.session.sessionPlayer.sessionExpired'), 3000);
                    // $state.go('storm.sessions.upcoming');
                    navigateBack();
                } else {
                    SessionPlayerService.grantHostPermission(userData.jitsiUserId);
                    var alternateHostMessage = {
                        subject: 'SESSION',
                        action: 'BROADCAST',
                        type: 'HOST_CHANGED',
                        data: result
                    };
                    publishMessage(alternateHostMessage);
                    Session.get({
                        id: vm.sessionNo,
                        is_session_no: true,
                        for_launch: true,
                        is_web: true
                    },function(response) {
                        vm.sessionModel = response;
                    }, function(error) {
                        vm.sessionModel = result;
                        console.error(error);
                    })
                    if (!vm.sessionModel.settings) {
                        vm.sessionModel.settings = {};
                        setSettingSessionModel(result);
                    }
                    setSettingsForSurvey(result);
                    vm.changeHostInSessionUsers();
                    updateSessionSettingsFromOverwrite();
                    vm.isHost = isUserHost();
                    if (vm.isHost) {
                        displayToast($filter('translate')('atheer.session.sessionPlayer.toastMessageForHost'), 2000);
                    } else {
                        var txtContent = vm.sessionModel.owner_info.first_name + ' ' + $filter('translate')('atheer.session.sessionPlayer.toastMessageForNonHost');
                        displayToast(txtContent, 2000);
                    };
                    vm.isZoomEnabled = vm.videoStatus ? true : false;
                    vm.canAnnotate = vm.videoStatus ? true : false;
                }
            });
        }

        vm.changeHostInSessionUsers = function () {
            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (sessionUser.isHost) {
                    sessionUser.isHost = false;
                }
            });
            angular.forEach(vm.sessionUsers, function (sessionUser) {
                if (sessionUser.userId === vm.sessionModel.owner) {
                    sessionUser.isHost = true;
                }
            });
        }

        closeAnnotationListener = $scope.$on('close-annotation', closeAnnotation);
        imageLoadedListener = $scope.$on('image-loaded', imageLoaded);
        annotationImageSavedListener = $scope.$on('annotation-image-saved', saveAnnotation);
        saveAnnotationImageListener = $scope.$on('save-annotation-image', updateAnnotationStatus);
        freezeFrameListener = $scope.$on('freeze-frame', onFreezeFrame);
        sessionMessagesCount = $scope.$on('unReadMessages', setMessagesCount);

        $scope.$watch('vm.zoomSlider', function (zoomSliderValue) {
            vm.zoom = zoomSliderValue / 100;
            if (vm.zoom > 1) {
                vm.enableZoom = !vm.isSharedZoomDisabled ? true : (vm.userId == getUserId() ? true : false);
            }
        });

        $scope.$on('$destroy', function () {
            closeAnnotationListener = null;
            imageLoadedListener = null;
            annotationImageSavedListener = null;
            saveAnnotationImageListener = null;
            freezeFrameListener = null;
            pubNubSessionListener = null;
            sessionMessagesCount = null;

            if (vm.isGuest) {
                Auth.logout();
            }

            $rootScope.isSessionStarted = false;
            if (vm.stream) {
                var tracks = vm.stream.getTracks();

                angular.forEach(tracks, function (track) {
                    track.stop();
                });
                vm.stream = null;
            }
            vm.imageCapture = null;
        });

        pubNubSessionListener = $scope.$on('sessionMessagePubNub', function (event, message) {
            $scope.$apply(function () {
                if (message.message.subject == 'SESSION' && message.publisher != vm.userId) {
                    handleMessage(message);
                };
            });
        });

        loadData();
    };
})();
