(function () {
  'use strict';

  angular.module('atheer').config(moduleConfig);

  /* @ngInject */
  function moduleConfig($stateProvider, triMenuProvider) {
    triMenuProvider.addMenu({
      id: 'home',
      name: 'global.menu.home',
      icon: 'zmdi zmdi-home',
      type: 'link',
      state: 'storm.home',
      url: '/home',
      active: false,
      priority: 0.1,
    });

    triMenuProvider.addMenu({
      id: 'conversations',
      name: 'global.menu.conversations',
      icon: 'fa fa-comments',
      type: 'link',
      state: 'storm.conversations',
      permission: 'access-conversation',
      feature: 'feature_conversation_enable',
      url: '/conversations',
      active: false,
      priority: 0.11,
    });

    triMenuProvider.addMenu({
      id: 'airsessions',
      name: 'global.menu.airSession',
      icon: 'zmdi zmdi-comment-video',
      type: 'link',
      state: 'storm.sessions.upcoming',
      permission: 'access-session',
      feature: 'feature_airsession_enable',
      url: '/sessions/upcoming',
      active: false,
      priority: 0.12,
    });

    triMenuProvider.addMenu({
      id: 'jobs',
      name: 'global.menu.jobs',
      icon: 'zmdi zmdi-assignment',
      state: 'storm.jobs',
      permission: 'access-job',
      feature: 'feature_airform_enable',
      url: '/jobs',
      type: 'link',
      active: true,
      priority: 0.13,
    });

    triMenuProvider.addMenu({
      id: 'tickets',
      name: 'global.menu.tickets',
      icon: 'flp ath-tickets',
      state: 'storm.tickets',
      feature: 'feature_ticket_enable_ticket',
      url: '/tickets',
      type: 'link',
      active: true,
      priority: 0.13,
    });

    triMenuProvider.addMenu({
      id: 'airforms',
      name: 'global.menu.airForms',
      icon: 'flp ath-smart-content',
      state: 'storm.admin-airforms',
      permission: 'view-applet',
      feature: 'feature_airform_enable',
      url: '/airforms',
      type: 'link',
      active: true,
      priority: 0.14,
    });

    triMenuProvider.addMenu({
      id: 'library',
      name: 'global.menu.content',
      icon: 'zmdi zmdi-library',
      type: 'menu',
      state: 'storm.admin-contents',
      permission: 'manage-content',
      feature: 'feature_content_enable',
      url: '/content',
      active: true,
      priority: 0.15,
      children: [
        {
          id: 'library-content-all',
          name: 'global.menu.allContent',
          type: 'link',
          state: 'storm.admin-contents',
          permission: 'manage-content',
          active: true,
          priority: 0.22,
        },
        {
          id: 'library-content-documents',
          name: 'global.menu.document',
          type: 'link',
          state: 'storm.content-documents',
          permission: 'manage-content',
          active: true,
          priority: 0.22,
        },
      ],
    });

    triMenuProvider.addMenu({
      id: 'topics',
      name: 'global.menu.topics',
      icon: 'zmdi zmdi-widgets',
      state: 'storm.admin-topics',
      feature: 'feature_topic_enable',
      permission: 'manage-topic-content',
      url: '/topics',
      type: 'link',
      active: true,
      priority: 0.16,
    });

    triMenuProvider.addMenu({
      id: 'anchors',
      name: 'global.menu.anchors',
      icon: 'zmdi zmdi-flare',
      state: 'storm.admin-anchors',
      permission: 'manage-anchor',
      feature: 'feature_anchor_enable',
      url: '/anchors',
      type: 'link',
      active: true,
      priority: 0.18,
    });

    triMenuProvider.addMenu({
      id: 'smartflows',
      name: 'global.menu.smartflows',
      icon: 'zmdi zmdi-device-hub',
      state: 'storm.admin-smartflows',
      feature: 'feature_smartflow_enable',
      permission: 'manage-workflow',
      url: '/smartflows',
      type: 'link',
      active: true,
      priority: 0.19,
    });

    triMenuProvider.addMenu({
      id: 'users',
      name: 'global.menu.users',
      icon: 'zmdi zmdi-accounts-list',
      type: 'menu',
      state: 'storm.admin-users',
      permission: 'manage-user',
      url: '/users',
      active: true,
      priority: 0.2,
      children: [
        {
          id: 'users-users',
          name: 'global.menu.users',
          type: 'link',
          state: 'storm.admin-users',
          permission: 'manage-user',
          active: true,
          priority: 0.22,
        },
        {
          id: 'users-teams',
          name: 'global.menu.teams',
          type: 'link',
          state: 'storm.admin-teams',
          permission: 'manage-user',
          active: true,
          priority: 0.22,
        },
      ],
    });

    triMenuProvider.addMenu({
      id: 'insights',
      name: 'global.menu.insights',
      icon: 'zmdi zmdi-chart',
      type: 'menu',
      state: 'storm.insights',
      permission: 'view-report',
      url: '/insights/dashboards',
      active: true,
      priority: 0.21,
      children: [
        {
          id: 'insights-adv-dashboards',
          name: 'atheer.insights.title',
          type: 'link',
          state: 'storm.insights.adv-dashboards',
          permission: 'view-report',
          active: true,
          priority: 0.22,
        },
        {
          id: 'insights-analyze',
          name: 'atheer.insights.analyze',
          type: 'link',
          state: 'storm.insights.adv-analyze',
          feature: 'feature_adv-insights_designer',
          permission: 'manage_adv_insights-report',
          active: true,
          priority: 0.22,
        },
      ],
    });

    triMenuProvider.addMenu({
      id: 'airsdk',
      name: 'global.menu.airSdk',
      icon: 'zmdi zmdi-code-setting',
      type: 'menu',
      state: 'storm.admin-airsdk-data-export',
      feature: 'feature_airsdk_enable',
      permission: 'manage-airsdk',
      url: '/sdk/api-reference',
      active: true,
      priority: 0.22,
      children: [
        {
          id: 'airsdk-data-export',
          name: 'atheer.airSdk.dataExport',
          type: 'link',
          state: 'storm.admin-airsdk-data-export',
          feature: 'feature_airsdk_data_export',
          permission: 'manage-webhooks-airsdk',
          url: '/sdk/api-reference',
          active: true,
          priority: 0.22,
        },
        {
          id: 'airsdk-webhooks',
          name: 'atheer.airSdk.webHooks',
          type: 'link',
          state: 'storm.admin-airsdk-web-hooks',
          feature: 'feature_airsdk_webhooks',
          permission: 'manage-data-export-airsdk',
          url: '/sdk/api-reference',
          active: true,
          priority: 0.22,
        },
        {
          id: 'airsdk-api-reference',
          name: 'atheer.airSdk.apiDoc',
          type: 'link',
          state: 'storm.admin-airsdk-api-doc',
          feature: 'feature_airsdk_api',
          permission: 'access-api-airsdk',
          url: '/sdk/api-reference',
          active: true,
          priority: 0.22,
        },
        {
          id: 'airsdk-api-key',
          name: 'atheer.airSdk.apiKey',
          type: 'link',
          state: 'storm.admin-airsdk-api-key',
          feature: 'feature_airsdk_api',
          permission: 'access-api-airsdk',
          url: '/sdk/api-reference',
          active: true,
          priority: 0.22,
        },
      ],
    });

    triMenuProvider.addMenu({
      id: 'settings',
      name: 'global.menu.settings',
      icon: 'zmdi zmdi-settings',
      type: 'menu',
      state: 'storm.admin-setting.workspace-info',
      permission: 'manage-setting-system',
      url: '/settings/workspace',
      active: true,
      priority: 0.23,
      children: [
        {
          id: 'settings-workspace-info',
          name: 'atheer.setting.workspaceInfo',
          icon: 'fa fa-building',
          type: 'link',
          state: 'storm.admin-setting.workspace-info',
          permission: 'manage-setting-system',
          active: true,
          priority: 0.22,
        },
        {
          id: 'settings-logo-color',
          icon: 'zmdi zmdi-brush',
          name: 'atheer.setting.logoAndColor',
          state: 'storm.admin-setting.skin',
        },
        {
          id: 'settings-dictionary',
          icon: 'zmdi zmdi-font',
          name: 'atheer.setting.dictionary.mainTitle',
          state: 'storm.admin-setting.dictionary',
          feature: 'feature_system_label_dictionary',
        },
        {
          id: 'settings-user-signup',
          icon: 'zmdi zmdi-account-add',
          name: 'atheer.setting.signup',
          state: 'storm.admin-setting.user-signup',
          feature: 'feature_user_signup',
        },
        {
          id: 'settings-security-roles',
          icon: 'zmdi zmdi-shield-security',
          name: 'atheer.setting.securityRoles',
          state: 'storm.admin-setting.security-roles',
          permission: 'manage-security-role-system',
          feature: 'feature_security_role_enable',
        },
        {
          id: 'settings-authentication-set-up',
          icon: 'zmdi zmdi-lock-outline',
          name: 'atheer.setting.authentication',
          state: 'storm.admin-setting.authentication.studio',
        },
        {
          id: 'settings-notifications',
          icon: 'zmdi zmdi-notifications',
          name: 'atheer.setting.notifications',
          state: 'storm.admin-setting.notifications',
          permission: 'manage-notification-system',
          feature: 'feature_system_notification_edit',
        },
        {
          id: 'settings-session',
          icon: 'zmdi zmdi-comment-video',
          name: 'atheer.setting.session',
          state: 'storm.admin-setting.session',
          feature: 'feature_airsession_enable',
        },
        {
          id: 'settings-airform',
          icon: 'flp ath-smart-content',
          name: 'atheer.setting.airform',
          state: 'storm.admin-setting.airform',
          feature: 'feature_airform_enable',
        },
        {
          id: 'settings-lens-camera',
          icon: 'zmdi zmdi-camera',
          name: 'atheer.setting.camera',
          state: 'storm.admin-setting.camera'
        },
        {
          id: 'settings-features',
          icon: 'zmdi zmdi-view-quilt',
          name: 'atheer.setting.features',
          state: 'storm.admin-setting.features',
          feature: 'feature_studio-settings_manage_features',
        },
        {
          id: 'settings-homepageconfig',
          icon: 'flp ath-home-config',
          name: 'atheer.setting.homepageconfig',
          state: 'storm.admin-setting.homepageconfig',
        },
        {
          id: 'settings-jobpdfconfig',
          icon: 'flp ath-jobpdf-config',
          name: 'atheer.setting.pdfconfig',
          state: 'storm.admin-setting.jobpdfconfig',
        },
        {
          id: 'settings-jobfileextconfig',
          icon: 'flp ath-jobfileext-config',
          name: 'atheer.setting.fileExtConfig',
          state: 'storm.admin-setting.jobfileextconfig',
        },
      ],
    });

    triMenuProvider.addMenu({
      id: 'sys-admin',
      name: 'global.menu.systemAdmin',
      icon: 'zmdi zmdi-developer-board',
      type: 'menu',
      state: 'storm.system-admin.dashboard',
      permission: 'manage-admin-cloud',
      url: '/system/dashboard',
      active: true,
      priority: 0.24,
      children: [
        {
          id: 'sys-admin-dashboard',
          icon: 'zmdi zmdi-chart',
          name: 'atheer.system.dashboard',
          type: 'link',
          state: 'storm.system-admin.dashboard',
        },
        {
          id: 'sys-admin-locales',
          icon: 'fa fa-globe',
          name: 'atheer.system.locales',
          type: 'link',
          state: 'storm.system-admin.locales',
        },
        {
          id: 'sys-admin-languages',
          icon: 'zmdi zmdi-globe-alt',
          name: 'atheer.system.languages',
          type: 'link',
          state: 'storm.system-admin.languages',
        },
        {
          id: 'sys-admin-timezones',
          icon: 'fa fa-clock-o',
          name: 'atheer.system.timezones',
          type: 'link',
          state: 'storm.system-admin.timezones',
        },
      ],
    });

    triMenuProvider.addMenu({
      id: 'workspaces',
      name: 'global.menu.workspaces',
      icon: 'fa fa-building',
      type: 'link',
      state: 'storm.admin-workspaces',
      permission: 'manage-admin-cloud',
      active: true,
      priority: 0.25,
    });

    triMenuProvider.addMenu({
      id: 'ops-admin',
      name: 'global.menu.operationsAdmin',
      icon: 'zmdi zmdi-cloud',
      type: 'link',
      state: 'storm.cloud-admin.job-streams',
      permission: 'manage-admin-cloud',
      active: true,
      priority: 0.26,
    });

  }
})();
