(function() {
    'use strict';

    angular
        .module('atheer')
        .directive('fpAutoComplete', fpAutoComplete);

    function fpAutoComplete() {
        var directive = {
            restrict: 'E',
            replace: 'true',
            scope: {
                'component': '@',
                'placeholder': '@?',
                'autoCompleteModel': '=',
                'multiple': '@?',
                'specialFilter': '&?',
                'queryObjs': '=', //  array of query fields with [{'fieldName' : 'name', operator=''}]
                'required': '@?',
                'isSession': '=',
                'isScheduledSession': '=',
                'isAdmin': '=',
                'schedulerUserInfo': '=?',
                'errorModal': '=?',
                'inputFormFieldName': '@?',
                'skipCurrentUser': '@?'
            },
            controller: autocompleteDirectiveController,
            templateUrl: 'core/components/autocomplete/autocomplete-directive.tmpl.html'
        };
        return directive;
    }

    /* @ngInject */
    function autocompleteDirectiveController($scope, $injector, $filter, $q, $mdDialog, Principal) {
        var componentToInject = $scope.component == "UserInvite" || $scope.component == "SessionSingleUserInvite" ? "User" : $scope.component;
        var service = $injector.get(componentToInject);
        $scope.required = $scope.required == 'true';

        if(!!$scope.inputFormFieldName) {
            $scope.inputFieldName = $scope.inputFormFieldName;
        }
        
        $scope.allowMultiSelect = false;
        if ($scope.multiple == 'true') {
            $scope.allowMultiSelect = true;
        }

        $scope.isCancelDisabled = function () {
            return $scope.component === 'SessionSingleUserInvite' && !$scope.allowMultiSelect && $scope.required;            
        }

        var pickerDialogMeta = {
            'Content': {
                controller: 'ContentPickerController',
                templateUrl: 'modules/content/content-picker.html'
            },
            'Provider': {
                controller: 'ProviderPickerController',
                templateUrl: 'modules/content/provider/provider-picker.tmpl.html'
            },
            'Team': {
                controller: 'TeamPickerController',
                templateUrl: 'modules/team/team-picker.tmpl.html'
            },
            'Badge': {
                controller: 'BadgePickerController',
                templateUrl: 'modules/system/badge/badge-picker.tmpl.html'
            },
            'Folder': {
                controller: 'FolderPickerController',
                templateUrl: 'modules/content/folder/folder-picker.tmpl.html'
            },
            'Topic': {
                controller: 'TopicPickerController',
                templateUrl: 'modules/topic/topic-picker.tmpl.html'
            },
            'SecurityRole': {
                controller: 'SecurityRolePickerController',
                templateUrl: 'modules/system/security-role/security-role-picker.tmpl.html'
            },
            'User': {
                controller: 'UserPickerController',
                templateUrl: 'modules/user/user-picker.tmpl.html'
            },
            'EmailLayout': {
                controller: 'EmailLayoutPickerController',
                templateUrl: 'modules/system/email-layout/email-layout-picker.tmpl.html'
            },
            'UserInvite': {
                controller: 'SessionUserInviteController',
                templateUrl: 'modules/session/session-user-invite.tmpl.html'
            },
            'SessionSingleUserInvite': {
                controller: 'SessionUserInviteController',
                templateUrl: 'modules/session/session-user-invite.tmpl.html'
            },
        };

        var query = '';

        $scope.placeholder = $scope.placeholder ? $scope.placeholder : $filter('translate')('autoComplete.placeholder.' + $filter('lowercase')($scope.component));


        function formQuery(searchText) {
            if (!searchText) {
                return '';
            };

            var searchQuery = "(";

            searchQuery += 'first_name=re="' + searchText + '"';
            searchQuery += ',last_name=re="' + searchText + '"';
            searchQuery += ',email=re="' + searchText + '"';

            searchQuery += ")";

            return searchQuery
        }

        angular.forEach($scope.queryObjs, function(queryObj, index) {
            if (index > 0) {
                query += ';';
            }
            query += (queryObj.fieldName + queryObj.operator + (queryObj.value ? queryObj.value : '{querySearchText}'))
        });

        $scope.itemChanged = function(item) {
            if ($scope.component == 'Topic') {
                $scope.autoCompleteModel = item;
            }
            if ($scope.component == 'Team' && item) {
                $scope.autoCompleteModel = item;
            }
            if ($scope.component == 'SecurityRole' && item) {
                $scope.autoCompleteModel = item;
            }
            if ($scope.component == 'SessionSingleUserInvite' && item) {
                $scope.autoCompleteModel = item;
                $scope.$parent.isHostUpdated(item);
            }
        };

        $scope.onBlur = function () {
            if ($scope.component === "SessionSingleUserInvite") {
                // while search if user not clearing search then on blur clear seach
                // and add current selected host as it is required field
                var currentElement = angular.element(this) ? Array.from(angular.element(this)) : []
                if(currentElement && currentElement.length) {
                    currentElement[0].autoCompleteModel = $scope.autoCompleteModel;
                }
            }
        };

        $scope.isCacheEnabled = function () {
            return ["UserInvite", "User", "SessionSingleUserInvite"].includes($scope.component).toString();
        };


        function isMasterSchedulerPresent() { 
            return $scope.schedulerUserInfo && $scope.schedulerUserInfo.hasOwnProperty('id');
        }

        $scope.querySearch = function(searchText) {
            if (searchText.length >= 2) {
                var deferred = $q.defer();
                var userComponent = ["UserInvite", "User", "SessionSingleUserInvite"].includes(
                    $scope.component
                ) && !angular.isDefined($scope.queryObjs);
                var userQuery = formQuery(searchText);
                
                if (userComponent) {
                    if (
                        $scope.autoCompleteModel.length ||
                        ($scope.component === "SessionSingleUserInvite" &&
                            Object.keys($scope.autoCompleteModel).length)
                    ) {
                        if (!!userQuery) {
                            userQuery += ";";
                        }
                        if ($scope.component === "SessionSingleUserInvite") {
                            userQuery +=
                                "id=out=(" + $scope.autoCompleteModel.id + ")";
                        } else {
                            userQuery +=
                                "id=out=(" +
                                $scope.autoCompleteModel
                                    .map(function (user) {
                                        return user.id;
                                    })
                                    .toString() +
                                "," +
                                (isMasterSchedulerPresent()
                                    ? $scope.schedulerUserInfo.id
                                    : Principal.getUserId()) +
                                ")";
                        }
                    } else if($scope.component === "UserInvite") {
                        userQuery +=
                            ";id=out=(" +
                            (isMasterSchedulerPresent()
                                ? $scope.schedulerUserInfo.id
                                : Principal.getUserId()) +
                            ")";
                    } else {
                        userQuery +=
                            ";id=out=(" + Principal.getUserId() + ")";
                    }
                }
                
                service.query({
                    'query': (userComponent ? userQuery : query).replaceAll(/{querySearchText}/g, searchText),
                    check_admin: $scope.isAdmin
                }, function(result) {
                    if ($scope.specialFilter) {
                      deferred.resolve($scope.specialFilter({
                          result: result
                      }));
                    } else {
                        deferred.resolve(result);
                    }
                    setTimeout(function () {
                        var popups = document.querySelectorAll(
                            ".md-autocomplete-suggestions-container"
                        );

                        Array.from(popups).map(function (popup) {
                            var styles = popup.getAttribute("style");

                            if (
                                !popup.classList.contains("ng-hide") &&
                                result.length && 
                                (!styles.includes(" height:") || (popup.clientHeight > 48 * result.length ))
                            ) {
                                // Reference: 48px Item height 
                                // https://github.com/angular/material/blob/952c06705a7eddaa02fcbb2fd931e06740702404/src/components/autocomplete/js/autocompleteController.js#L5
                                popup.setAttribute(
                                    "style",
                                    styles +
                                        "height: " +
                                        (48 * result.length).toString() +
                                        "px !important;"
                                );
                            }
                        });
                    });
                }, function(result) {
                    deferred.reject([]);
                });
                return deferred.promise;
            } else {
                return [];
            }
        };

        $scope.openPickerDialog = function(ev) {
            var pickerDialogMetaData = pickerDialogMeta[$scope.component];
            var filter = '';

            if ($scope.component == 'Content') {
                filter = 'embedded==false;enabled==true';
            }

            var users = [];
            if ($scope.component == 'User') {
                for (var i = 0; i < $scope.autoCompleteModel.length; i++) {
                    $scope.autoCompleteModel[i].is_guest ? users.push($scope.autoCompleteModel[i].guest_invite_value)
                        : users.push($scope.autoCompleteModel[i].id);
                }
            }

            var topics = []
            if ($scope.allowMultiSelect && $scope.component == 'Topic') {
                for (var i = 0; i < $scope.autoCompleteModel.length; i++) {
                    topics.push($scope.autoCompleteModel[i].id)
                }
            }

            angular.forEach($scope.queryObjs, function(queryObj, index) {
                if (angular.isDefined(queryObj.value)) {
                    if (filter !== '') {
                        filter += ';' + queryObj.fieldName + queryObj.operator + queryObj.value;
                    } else {
                        filter += queryObj.fieldName + queryObj.operator + queryObj.value;
                    }
                }
            });

            var selectedItemIds = [];
            if($scope.allowMultiSelect && $scope.autoCompleteModel) {
                angular.forEach($scope.autoCompleteModel, function(data) {
                    if(data && data.id) {
                        selectedItemIds.push(data.id);
                    }
                });
            } else if ($scope.autoCompleteModel) {
                selectedItemIds.push($scope.autoCompleteModel.id);
            }

            if ($scope.component === "UserInvite") {
                filter = angular.copy(selectedItemIds);
                if (
                    angular.isDefined($scope.schedulerUserInfo) &&
                    $scope.schedulerUserInfo
                ) {
                    filter.push($scope.schedulerUserInfo.id);
                }
            } else if (
                $scope.component === "SessionSingleUserInvite"
            ) {
                if($scope.schedulerUserInfo && $scope.schedulerUserInfo.hasOwnProperty("id")) {
                    filter = [$scope.schedulerUserInfo.id];
                } else {
                    filter = [Principal.getUserId()];
                }
            }

            $mdDialog.show({
                multiple: true,
                templateUrl: pickerDialogMetaData.templateUrl,
                controller: pickerDialogMetaData.controller,
                controllerAs: 'vm',
                locals: {
                    filter: filter,
                    allowMultiSelect: $scope.allowMultiSelect,
                    userslist: users,
                    topics: topics,
                    isSession: $scope.isSession,
                    isScheduledSession: $scope.isScheduledSession,
                    isAdmin: $scope.isAdmin,
                    isConversationSession: false,
                    isSmartScan: false,
                    isWeb: true,
                    isNotFilter: false,
                    selectedItemIds: $scope.component === "SessionSingleUserInvite" ? [$scope.schedulerUserInfo.id] : selectedItemIds,
                    schedulerUserInfo: $scope.schedulerUserInfo,
                    isSessionSingleUserInvite: $scope.component === "SessionSingleUserInvite",
                    autoCompleteModel: $scope.autoCompleteModel
                }
            }).then(function(selectedItems) {
                if ($scope.allowMultiSelect) {
                    if($scope.isScheduledSession) {
                        $scope.autoCompleteModel = selectedItems;
                        return
                    }
                    var deltaItems = [];
                    angular.forEach(selectedItems, function(selectedItem) {
                        if (!$filter('filter')($scope.autoCompleteModel, { id: selectedItem.id, guest_invite_value: selectedItem.guest_invite_value })[0]) {
                            deltaItems.push(selectedItem)
                        }
                    });
                    if (!$scope.autoCompleteModel) {
                        $scope.autoCompleteModel = [];
                    }
                    $scope.autoCompleteModel = $scope.autoCompleteModel.concat($scope.specialFilter ? $scope.specialFilter({result : deltaItems}) : deltaItems)
                } else {
                    if ($scope.component === "SessionSingleUserInvite") {
                        $scope.autoCompleteModel = selectedItems[0];
                        $scope.$$childTail.autoCompleteModel = selectedItems[0];
                        $scope.$parent.isHostUpdated(selectedItems[0]);
                    } else {
                        $scope.autoCompleteModel = $scope.specialFilter
                            ? $scope.specialFilter({ result: selectedItems })[0]
                            : selectedItems[0];
                    }
                }
            });
        }

        $scope.transformChip = function(chip) {
            if (angular.isObject(chip)) {
                return chip;
            }
        }

        $scope.clear = function(){
            $scope.autoCompleteModel = null;
        }
    }
})();
