(function () {
    'use strict';

    angular
        .module('atheer.user')
        .controller('UserImportController', UserImportController);

    /* @ngInject */
    function UserImportController($rootScope, $scope, $state, Principal, User, $location, ParseLinks, AlertService, $mdDialog, $stateParams, $timeout, poller, $resource, $filter, ToastService, $http) {
        var vm = this;
        var uploadTypeEnum = {
            CSV: 'CSV',
            SPREADSHEET: 'SPREADSHEET'
        }

        vm.currentStep = 'upload';
        vm.uploadStatus = 'idle';
        vm.importStatus = 'idle';
        vm.fileImportInfo = null;
        vm.jobStream = null;
        vm.excelErrors = [];
        vm.jobData = null;
        vm.uploadType = uploadTypeEnum.CSV;
        vm.illustartionEmptyScreenId = "user_import";
        vm.jobStreamId = null;
        vm.responseObj = null;

        var downloadFile = function (url, fileName) {
            $http.get(url, { responseType: 'blob' }).then(function (response) {
                var objectUrl = URL.createObjectURL(response.data);
                var link = document.createElement('a');
                link.download = fileName,
                    link.href = objectUrl;
                link.click();
            })
        }

        vm.handleMediaUpload = function (mediaId, responseObj) {
            vm.responseObj = responseObj;
            if (responseObj.content_type === 'text/csv') {
                vm.uploadType = uploadTypeEnum.CSV;
            } else {
                vm.uploadType = uploadTypeEnum.SPREADSHEET;
            }
            vm.validateCSV(mediaId, responseObj);

        };

        vm.validateCSV = function (mediaId, responseObj) {
            vm.currentStep = 'validating';
            if (responseObj.content_type === 'text/csv') {
                User.validateImportFile({
                    fileId: responseObj.id
                }, function (response, headers) {
                    vm.fileImportInfo = response;
                    if (vm.fileImportInfo.is_valid) {
                        vm.currentStep = 'validate';
                    } else {
                        vm.currentStep = 'upload';
                        vm.uploadStatus = 'idle';
                        vm.importStatus = 'idle';
                        vm.fileImportInfo = null;
                        vm.jobStream = null;
                        vm.excelErrors = [];
                        vm.jobData = null;
                        ToastService.displayToast($filter('translate')('atheer.userImport.invalidFile'), 3000);
                    }
                });
            } else {
                User.validateImportExcelFile({
                    fileId: responseObj.id
                }, function (response, headers) {
                    vm.fileImportInfo = response;
                    if (vm.fileImportInfo.is_valid) {
                        vm.currentStep = 'validate';
                    } else {
                        vm.currentStep = 'upload';
                        vm.uploadStatus = 'idle';
                        vm.importStatus = 'idle';
                        vm.fileImportInfo = null;
                        vm.jobStream = null;
                        vm.excelErrors = [];
                        vm.jobData = null;
                        ToastService.displayToast($filter('translate')('atheer.userImport.invalidFile'), 3000);
                    }
                });
            }
            
        };

        vm.moveNext = function () {
            if (vm.currentStep == 'validate') {
                vm.currentStep = 'import';
                vm.startImport();
            } else if (vm.currentStep == 'import-summary') {
                $state.go('storm.admin-users');
            }
        };

        vm.downloadLogs = function () {
            var url = '/api/system/job-streams/pdf/' + vm.jobStreamId;
            downloadFile(url, 'user_import.xlsx');
        }

        vm.downloadSample = function (type) {
            var url = (type === 'CSV') ? '/api/users/import/template/download-csv' : '/api/users/import/template/download-xls';
            var fileName = (type === 'CSV') ? 'user_import_template.csv' : 'user_import_template.xlsx';
            downloadFile(url, fileName)
        }

        vm.startImport = function () {
            vm.importStatus = 'importing';
            if (vm.uploadType === uploadTypeEnum.CSV) {
                User.importUsers(vm.fileImportInfo, function (response, headers) {
                    vm.jobStream = response;
                    vm.jobStreamId = vm.jobStream.id;
                    vm.getImportJobStatus();
                });
            } else {
                 User.importUsersFromSpreadSheet(
                     vm.responseObj,
                     function (response) {
                         vm.jobStream = response;
                         if (vm.jobStream.job_data.valid) {
                             vm.getImportJobStatus();
                         }
                     }
                 );
            }
        };

        vm.getImportJobStatus = function () {
            // Get poller.
            var jobStreamStatusPoller = poller.get(User, {
                action: (vm.uploadType === uploadTypeEnum.CSV) ? 'getImportStatus' : 'getSpreadSheetImportStatus',
                delay: 3000,
                argumentsArray: [{
                    id: vm.jobStream.id
                }],
                smart: true
            });

            jobStreamStatusPoller.promise.then(null, null, function (result) {
                vm.jobStream = result;
                if (vm.uploadType === uploadTypeEnum.CSV) {
                    $rootScope.isBulkUploadInProgress = true;
                    vm.jobStreamId = vm.jobStream.id;
                    if (result.status == 'COMPLETED') {
                        $rootScope.isBulkUploadInProgress = false;
                        poller.stopAll();
                        vm.currentStep = 'import-summary';
                        vm.importStatus = 'complete';
                    }
                } else {
                    vm.jobData = vm.jobStream.job_data;
                    vm.jobStreamId = vm.jobStream.import_id;
                    if (result.status == 'COMPLETED') {
                        poller.stopAll();
                        vm.currentStep = 'import-summary';
                        vm.importStatus = 'complete';
                        vm.excelErrors = result.errors;
                    }
                }

            });
        };

        vm.resetImport = function () {
            if (vm.importStatus === 'idle') {
                $state.go('storm.admin-users');
            }
            vm.currentStep = 'upload';
            vm.uploadStatus = 'idle';
            vm.importStatus = 'idle';
            vm.fileImportInfo = null;
            vm.jobStream = null;
            vm.excelErrors = [];
            vm.jobData = null;
        };
    }
})();
