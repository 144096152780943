(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ForgotController', ForgotController);

    /* @ngInject */
    function ForgotController($rootScope, $scope, $state, $mdToast, $filter, $http, triSettings, $timeout, Auth) {
        var vm = this;

        vm.useDarkBackGroundLogos = true;
        vm.triSettings = triSettings;
        vm.buildInfoSettings = $rootScope.buildInfo;

        vm.resetAccount = {
            username: ''
        };

        vm.resetClick = resetClick;
        vm.status = null;

        $timeout(function() {
            angular.element('[ng-model="resetAccount.email"]').focus();
        });

        vm.loadData = function() {
            vm.useDarkBackGroundLogos = triSettings.getSkinModel().use_dark_logos;
        }

        function resetClick() {
            vm.error = null;
            vm.errorEmailNotExists = null;
            Auth.resetPasswordInit(vm.resetAccount.username).then(function() {
                vm.status = 'OK';
            }).catch(function(response) {
                vm.status = 'FAIL';
            });
        }

        vm.loadData();
    }
})();
