(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionsDetailToolbarController', SessionsDetailToolbarController);

    /* @ngInject */
    function SessionsDetailToolbarController($rootScope, $scope, $state, $mdDialog, ParseLinks, AlertService, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, $translate, sessionType, Feature, ToastService, SessionsService) {
        var vm = this;

        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.sessionType = sessionType;
        vm.nonEditmode = false;

        $scope.$watch("sessionDetailForm", function () {
            vm.sessionDetailForm = $rootScope.sessionDetailForm;            
        });

        vm.query = {
            filter: ''
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        vm.filter.show = false;

        vm.permissions = {
            create: 'create-session',
            update: 'update-session',
            delete: 'delete-session',
            updatePersonalSession: 'update-personal-session'
        };

        vm.toggleFilter = function() {
            vm.filter.show = !vm.filter.show;
        };

        vm.refreshAll = function() {
            $scope.$emit('refreshSessions', vm.query.filter);
        };

        vm.removeFilter = function() {
            vm.filter.show = false;
            vm.query.filter = '';
            $scope.$emit('refreshSessions', vm.query.filter);
        };

        vm.startInstantSession = function() {
            SessionsService.startInstantSession();
        };

        vm.joinSession = function() {
            SessionsService.joinSession();
        };

        vm.scheduleSession = function() {
            $state.go('storm.sessions.upcoming-detail');
        };

        vm.goToSessions = function() {
            $state.go('storm.sessions.upcoming');
        };

        vm.updatePersonalSession = function() {
            $scope.$emit('updatePersonalSession');
        };

        vm.cancelSaveSession = function() {
            if(vm.sessionType == 'PERSONAL') {
                $scope.$emit('cancelUpdatePersonalSession');
            } else {
                $state.go('storm.sessions.upcoming');
            }
        };

        vm.saveSession = function() {
            $scope.$emit('saveSession');
        };

        vm.saveAsTemplate = function() {
            $scope.$emit('saveAsTemplate');
        };

        vm.editPersonalSession = function() {
            $scope.$emit('editPersonalSession');
        };

        vm.startPersonalSession = function() {
            $scope.$emit('startPersonalSession');
        };
    }
})();
