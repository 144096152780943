(function () {
    'use strict';

    angular
        .module('atheer')
        .run(masterRun);

    function masterRun($rootScope, $window, $urlRouter, $q, $cookies, $state, $location, $log, Setting, PermPermissionStore, PermRoleStore, PermissionService, MetaData, Principal, AuthServerProvider, Feature, Experience, $mdTheming, triMenu, triSettings, triSkins, triTheming, ATHEER_FEATURES, paginationConstants, toastConstants) {
        var host = $location.host();
        //console.log('in master run');
        $rootScope.atheer_features = ATHEER_FEATURES;
        $rootScope.isLightTheme = false;
        $rootScope.isDesktopApp = false;
        $rootScope.environment = 'dev';

        $rootScope.isJobExecutionActive = false;
        $rootScope.isJobBoardActive = false;

        if (window.todesktop) {
            $rootScope.isDesktopApp = true;
        };
        //console.log('in master run 2');
        var skinModel = {
            logo_small: null,
            logo_big: null,
            logo_small_dark: null,
            logo_big_dark: null,
            favorite_icon: null,
            use_dark_logos: false,
            primary_color: null,
            primary_color_hex: null,
            secondary_color: null,
            secondary_color_hex: null,
            side_menu: 'DARK',
            side_menu_state: 'CLOSED',
            widget_size: 'SMALL'
        };

        if (angular.isDefined(host) && (host.startsWith("master.") || host.startsWith("master-eu.") || host.startsWith("master-jp.") || host.startsWith("app."))) {
            $rootScope.isMasterWorkspace = true;
        } else {
            $rootScope.isMasterWorkspace = false;
        };
        //console.log('in master run 3');
        if (angular.isDefined(host) && host.endsWith(".dev")) {
            $rootScope.environment = 'dev';
        } else if (angular.isDefined(host) && host.endsWith(".qa")) {
            $rootScope.environment = 'qa';
        } else if (angular.isDefined(host) && host.endsWith(".si")) {
            $rootScope.environment = 'si';
        } else if (angular.isDefined(host) && host.endsWith(".io")) {
            $rootScope.environment = 'io';
        } else {
            $rootScope.environment = 'dev';
        };

        //console.log('in master run 4');

        $q.all([
            Setting.getSkinSettings().$promise,
            Setting.getFeaturesSettings().$promise,
            MetaData.getPermissionMeta().$promise,
            Setting.getBuildInfoSettings().$promise,
            Setting.getAccessConfigSettings().$promise
        ])
            .then(function (results) {
                setSkinModel(results[0]);
                setUpTheme(results[0]);
                Feature.setFeatures(results[1]);
                Feature.setDeviceFeaturePermissions(results[4]);

                if (Principal.isAuthenticated() || AuthServerProvider.hasValidToken()) {
                    Principal.identity().then(function (user) {
                        setupLanguage(user);
                        setUpPermissionStore(results[2], results[1]);
                    });

                    setCompanySettings();

                } else {
                    if(!AuthServerProvider.hasValidToken() && localStorage.getItem('Authorization')) {
                        localStorage.removeItem('Authorization')
                    }
                    if (Feature.isActive('feature_system_public_access') && ($location.path() === '/' || $location.path() === '/public/home')) {
                        $rootScope.baseHome = '/public/home';
                        $location.path('/public/home');
                    } else if ($location.path() === '/') {
                        $location.path('/signin');
                    }
                    setUpPermissionStore(results[2], results[1]);
                }
                $rootScope.buildInfo = results[3];
            });

        function setCompanySettings() {
            Setting.getCompanySettings(function (data) {
                angular.forEach(data[0].fields, function (field) {
                    if (field.name == 'default_page_size') {
                        paginationConstants.itemsPerPage = field.value;
                    } else if (field.name == 'default_toast_position') {
                        toastConstants.position = field.value.split("-").join(" ").toLowerCase();
                    }
                });
            });
        }

        function setupLanguage(user) {
            var supportedLanguages = [
                { name: 'english_us', displayName: 'English (US)', value: 'en_US', serverKey: 'en_US' },
                { name: 'english_uk', displayName: 'English (UK)', value: 'en_GB', serverKey: 'en_GB' },
                { name: 'french', displayName: 'French', value: 'fr_FR', serverKey: 'fr_FR' },
                { name: 'german', displayName: 'German', value: 'de_DE', serverKey: 'de_DE' },
                { name: 'greek', displayName: 'Greek', value: 'el_GR', serverKey: 'el_GR' },
                { name: 'italian', displayName: 'Italian', value: 'it_IT', serverKey: 'it_IT' },
                { name: 'japanese', displayName: 'Japanese', value: 'ja_JP', serverKey: 'ja_JP' },
                { name: 'portuguese', displayName: 'Portuguese', value: 'pt_BR', serverKey: 'pt_BR' },
                { name: 'spanish', displayName: 'Spanish', value: 'es_CL', serverKey: 'es_CL' },
                { name: 'chinese', displayName: 'Chinese (Traditional)', value: 'zh_TW', serverKey: 'zh_TW' },
                { name: 'chinese_cn', displayName: 'Chinese (Simplified)', value: 'zh_CN', serverKey: 'zh_CN' },
                { name: 'dutch', displayName: 'Dutch', value: 'nl_NL', serverKey: 'nl_NL' },
                { name: 'danish', displayName: 'Danish (Denmark)', value: 'da_DK', serverKey: 'da_DK' },
                { name: 'norwegian', displayName: 'Norwegian', value: 'no_NO', serverKey: 'no_NO' },
                { name: 'polish', displayName: 'Polish (Poland)', value: 'pl_PL', serverKey: 'pl_PL' },
                { name: 'russian', displayName: 'Russian', value: 'ru_RU', serverKey: 'ru_RU' },
                { name: 'swedish', displayName: 'Swedish (Sweden)', value: 'sv_SE', serverKey: 'sv_SE' },
                { name: 'turkish', displayName: 'Turkish (Turkey)', value: 'tr_TR', serverKey: 'tr_TR' }
            ];
            angular.forEach(supportedLanguages, function (lang) {
                if (lang.serverKey == user.language_id) {
                    if (user.language_id != 'system_default') {
                        $rootScope.userSelectedLanguage = user.language_id;
                    } else {
                        var lang = ($window.navigator.language || $window.navigator.userLanguage).replace('-', '_');
                        if (lang == 'el') {
                            lang = 'el_GR';
                        } else if (lang == 'ja') {
                            lang = 'ja_JP';
                        } else if (lang.startsWith('zh')) {
                            lang = 'zh_TW';
                        } else if (lang.startsWith('nl')) {
                            lang = 'nl_NL';
                        }
                        $rootScope.userSelectedLanguage = lang;
                    }
                }
            });
        }

        function resolveUserAndNavigateToHome() {
            Principal.identity(true).then(function (user) {
                $state.go('storm.home');
            });
        }

        function setSkinModel(skinSettings) {
            angular.forEach(skinSettings, function (setting) {
                if (setting.category == 'skin_info') {
                    angular.forEach(setting.fields, function (settingField) {
                        if (settingField.name == 'logo_small') {
                            skinModel.logo_small = settingField.value;
                        }
                        if (settingField.name == 'logo_big') {
                            skinModel.logo_big = settingField.value;
                        }
                        if (settingField.name == 'logo_small_dark') {
                            skinModel.logo_small_dark = settingField.value;
                        }
                        if (settingField.name == 'logo_big_dark') {
                            skinModel.logo_big_dark = settingField.value;
                        }
                        if (settingField.name == 'favorite_icon') {
                            skinModel.favorite_icon = settingField.value;
                        }
                        if (settingField.name == 'use_dark_logos') {
                            if (settingField.value == 'true') {
                                skinModel.use_dark_logos = true;
                            } else {
                                skinModel.use_dark_logos = false;
                            }
                        }
                        if (settingField.name == 'primary_color') {
                            skinModel.primary_color = settingField.value;
                        }
                        if (settingField.name == 'primary_color_hex') {
                            skinModel.primary_color_hex = settingField.value;
                        }
                        if (settingField.name == 'secondary_color') {
                            skinModel.secondary_color = settingField.value;
                        }
                        if (settingField.name == 'secondary_color_hex') {
                            skinModel.secondary_color_hex = settingField.value;
                        }
                        if (settingField.name == 'side_menu') {
                            skinModel.side_menu = settingField.value;
                        }
                        if (settingField.name == 'side_menu_state') {
                            skinModel.side_menu_state = settingField.value;
                        }
                        if (settingField.name == 'logo_right') {
                            skinModel.logo_right = settingField.value;
                        }
                        if (settingField.name == 'widget_size') {
                            skinModel.widget_size = settingField.value;
                        }
                    });
                }
            });

            if (skinModel.use_dark_logos) {
                triSettings.setLogoSmall('assets/images/ath-sm-white.png');
                triSettings.setLogoBig('assets/images/ath-white.png');

                if (skinModel.logo_small_dark != null) {
                    triSettings.setLogoSmall('/media/images/' + skinModel.logo_small_dark);
                }

                if (skinModel.logo_big_dark != null) {
                    triSettings.setLogoBig('/media/images/' + skinModel.logo_big_dark);
                }
            } else {
                triSettings.setLogoSmall('assets/images/ath-sm-color.png');
                triSettings.setLogoBig('assets/images/ath-color.png');

                if (skinModel.logo_small != null) {
                    triSettings.setLogoSmall('/media/images/' + skinModel.logo_small);
                }

                if (skinModel.logo_big != null) {
                    triSettings.setLogoBig('/media/images/' + skinModel.logo_big);
                }
            }
            triSettings.setSkinModel(skinModel);
        };

        function setUpTheme(skinSettings) {
            if (skinModel.primary_color == null && skinModel.secondary_color == null) {
                skinModel.primary_color = 'teal';
                skinModel.secondary_color = 'orange';
            };

            triTheming.registerTheme('primary-theme')
                .primaryPalette(skinModel.primary_color)
                .accentPalette(skinModel.secondary_color)
                .warnPalette('deep-orange');

            triTheming.registerTheme('white-theme')
                .primaryPalette('white')
                .accentPalette(skinModel.primary_color, {
                    'default': '400'
                })
                .warnPalette('deep-orange');

            if (skinModel.side_menu == 'DARK') {
                triSkins.registerSkin('atheer', 'Atheer')
                    .sidebarTheme('primary-theme')
                    .toolbarTheme('white-theme')
                    .contentTheme('primary-theme');
            } else {
                triSkins.registerSkin('atheer', 'Atheer')
                    .sidebarTheme('white-theme')
                    .toolbarTheme('white-theme')
                    .contentTheme('primary-theme');
                $rootScope.isLightTheme = true;
            }

            triSkins.setSkin('atheer');
            $rootScope.triSkin = triSkins.getCurrent();

            $mdTheming.generateTheme('primary-theme');
            $mdTheming.generateTheme('white-theme');

            if (skinModel.favorite_icon != null) {
                var link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                link.href = '/media/images/' + skinModel.favorite_icon;
            } else {
                var link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                link.href = 'assets/images/ath-sm-color.png';
            }
        };

        function setUpPermissionStore(permissionMeta, features) {
            var permissions = [];
            var roles = [];

            angular.forEach(permissionMeta.permissions, function (permission) {
                var objectName = permission.object_name;
                var permissionName = permission.permission_name;
                permissions.push(permissionName + "-" + objectName);
            });

            angular.forEach(features, function (feature) {
                if (feature.category == 'features') {
                    var features = [];
                    angular.forEach(feature.fields, function (settingField) {
                        permissions.push('feature' + '_' + settingField.group_name + '_' + settingField.name)
                    });
                }
            });

            PermPermissionStore.defineManyPermissions(permissions, checkPermission);

            angular.forEach(permissionMeta.roles, function (role) {
                var roleName = role.name;
                var rolePermissions = [];

                angular.forEach(role.permissions, function (permission) {
                    var objectName = permission.object_name;
                    var permissionName = permission.permission_name;

                    rolePermissions.push(permissionName + "-" + objectName);
                });
                PermRoleStore.defineRole(roleName, rolePermissions);
            });

            // kick-off router and start the application rendering
            $urlRouter.sync();
            // Also enable router to listen to url changes
            $urlRouter.listen();
        };

        // default redirect if access is denied
        function accessDenied() {
            $state.go('401');
        };

        // function that calls user service to check if permission is allowed
        function checkPermission(permission, transitionProperties) {
            return PermissionService.hasPermission(permission, transitionProperties);
        };


        function initializeProductFeedback() {
            if (angular.isDefined(host) && (host.startsWith("atheer."))) {
                // Requires jQuery!
                jQuery.ajax({
                    url: "https://atheerlabs.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/sb53l8/b/24/e73395c53c3b10fde2303f4bf74ffbf6/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US&collectorId=dcf657d8",
                    type: "get",
                    cache: true,
                    dataType: "script"
                });
            }
        }

        initializeProductFeedback()

        // watches
        // redirect all denied permissions to 401
        var deniedHandle = $rootScope.$on('$stateChangePermissionDenied', accessDenied);

        // remove watch on destroy
        $rootScope.$on('$destroy', function () {
            deniedHandle();
        });

        $rootScope.$on('identityResolved', function (event, identity) {
            if ($rootScope.isMasterWorkspace) {
                $rootScope.baseHome = '/system/dashboard';
                $rootScope.baseLeftNavMenu = 'cloud-insights';
                $rootScope.selectedLeftNavMenu = 'cloud-insights';
                $rootScope.showForYou = false;
            } else if (Principal.isWorkspaceAdmin()) {
                var path = $location.path();

                $rootScope.baseHome = '/home';
                $rootScope.baseLeftNavMenu = 'home';
                $rootScope.selectedLeftNavMenu = 'home';

                var menuArray = triMenu.menu;
                for (var i = 0; i < menuArray.length - 1; i++) {
                    if (path.startsWith(menuArray[i].url)) {
                        $rootScope.baseHome = menuArray[i].url;
                        $rootScope.baseLeftNavMenu = menuArray[i].id;
                        $rootScope.selectedLeftNavMenu = menuArray[i].id;
                        break;
                    };
                };

                if (path.startsWith('/settings')) {
                    $rootScope.selectedLeftNavMenu = 'settings';
                };
            } else {
                var path = $location.path();
                var menuArray = triMenu.menu;
                var menuToShow = null;

                for (var i = 0; i < menuArray.length - 1; i++) {
                    var hasAccess = false;
                    var featureEnabled = true;

                    if (angular.isDefined(menuArray[i].feature)) {
                        featureEnabled = Feature.isActive(menuArray[i].feature);
                    }

                    if (featureEnabled && angular.isDefined(menuArray[i].permission)) {
                        hasAccess = PermissionService.hasAccess(menuArray[i].permission);
                    };

                    if (menuArray[i].id == 'home') {
                        hasAccess = true;
                    }

                    if (hasAccess) {
                        menuToShow = menuArray[i];
                        break;
                    }
                };

                if (menuToShow != null) {
                    $rootScope.baseHome = menuToShow.url;
                    $rootScope.baseLeftNavMenu = menuToShow.id;
                    $rootScope.selectedLeftNavMenu = menuToShow.id;
                } else {
                    $rootScope.baseHome = '/noaccess';
                }

                for (var i = 0; i < menuArray.length - 1; i++) {
                    if (path.startsWith(menuArray[i].url)) {
                        $rootScope.baseHome = menuArray[i].url;
                        $rootScope.baseLeftNavMenu = menuArray[i].id;
                        $rootScope.selectedLeftNavMenu = menuArray[i].id;
                        break;
                    };
                };

                if (path.startsWith('/settings')) {
                    $rootScope.selectedLeftNavMenu = 'settings';
                };
                if ((path.startsWith('/conversation') && !(PermissionService.hasAccess('access-conversation') && Feature.isActive('feature_conversation_enable'))) ||
                    (path.startsWith('/jobs') && !(PermissionService.hasAccess('access-job') && Feature.isActive('feature_airform_enable'))) ||
                    (path.startsWith('/sessions') && !(PermissionService.hasAccess('access-session') && Feature.isActive('feature_airsession_enable')) && !$cookies.get('isGuest'))) {
                    $rootScope.baseHome = '/home';
                    $rootScope.baseLeftNavMenu = 'home';
                    $rootScope.selectedLeftNavMenu = 'home';
                    $rootScope.showAlertMessageFor = path.startsWith('/sessions') ? 'session' : (path.startsWith('/jobs') ? 'jobs' : 'conversation')
                    $location.path('/home');
                };
            }
            var featureForcedPassword = Feature.isActive('feature_user_initial_login_forced_password_required');
            if (featureForcedPassword && identity.forced_password_enabled) {
                $location.path('/forced-password');
            }
        });

        function handleNgxEvent(event) {
            var hostUrl =
                (location.hostname === 'localhost' || location.hostname === 'demo.atheer.my')
                    ? 'https://' + location.hostname + ':4200'
                    : location.origin;
            if (event.origin !== hostUrl) return;
            if (event.data.suggestionConfig) {
                $rootScope.suggestionConfig = event.data.suggestionConfig;
            } else if (event.data.jobExecutionStatus == 'active') {
                $rootScope.isJobExecutionActive = true;
            } else if (event.data.jobExecutionStatus == 'done') {
                $rootScope.isJobExecutionActive = false;
            } else if (event.data.isJobBoardActive === 'true') {
                $rootScope.isJobBoardActive = true;
            } else if (event.data.isJobBoardActive === 'false') {
                $rootScope.isJobBoardActive = false;
            } else if (event.data.appletEditStatus == 'active') {
                $rootScope.isAppletEditActive = true;
            } else if (event.data.appletEditStatus == 'done') {
                $rootScope.isAppletEditActive = false;
            } else if (event.data.openUrl) {
                window.location.href = event.data.openUrl.href;
            } else if(event.data.hasOwnProperty('isDocumentProcessing')) {
                $rootScope.isBulkUploadInProgress = event.data.isDocumentProcessing
            } else if(event.data.hasOwnProperty('isCopilotApiProcessing')) {
                $rootScope.isCopilotApiProcessing = event.data.isCopilotApiProcessing
            } else if (event.data.navigate) {
                var ngState = event.data.navigate.toState;
                var stateParams = event.data.navigate.params;
                if (stateParams) {
                    if (stateParams.id) {
                        if (stateParams.dashboardId) {
                            //console.log('In master run with stateparams: ' + JSON.stringify(stateParams));
                            $state.go(ngState, stateParams);
                        } else if (stateParams.contentType) {
                            $state.go(ngState, stateParams);
                        } else {
                            $state.go(ngState, { id: stateParams.id });
                        }
                    } else if (stateParams.session_no) {
                        $state.go(ngState, { session_no: stateParams.session_no });
                    }
                } else {
                    $state.go(ngState);
                }

            } else if (event.data.resolveUser) {
                //localStorage.setItem('Authorization', event.data.resolveUser.token);
                resolveUserAndNavigateToHome();
                setCompanySettings();

            } else if (event.data.navigateBack) {
                var selectedMenu = {}
                selectedMenu = triMenu.menu.filter(function (value, index) {
                    return value.state == event.data.menuState
                })[0];
                var params = selectedMenu && selectedMenu.params ? params : {};
                $rootScope.selectedLeftNavMenu = selectedMenu ? selectedMenu.id : $rootScope.selectedLeftNavMenu;
                $rootScope.isJobExecutionActive = false;
                $rootScope.isJobBoardActive = false;
                $rootScope.isAppletEditActive = false;
                if (event.data.menuState == 'storm.profile.overview') {
                    params = {
                        id: 'me',
                        launchQrCode: $rootScope.isQR ? true : false
                    }
                };
                $state.go(event.data.menuState, params)
            };
        }

        $window.addEventListener('message', handleNgxEvent, false);
    }
})();
