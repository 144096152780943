(function () {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Setting', Setting);

    /* @ngInject */
    function Setting($resource) {
        var resourceUrl = 'api/system/settings/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                url: 'api/system/settings',
                method: 'GET',
                isArray: true
            },
            'getSkinSettings': {
                url: 'api/system/settings/skin',
                method: 'GET',
                isArray: true
            },
            'getLabelDictionarySettings': {
                url: 'api/system/settings/label-dictionary',
                method: 'GET',
                isArray: true
            },
            'getBuildInfoSettings': {
                url: 'api/system/settings/build-info',
                method: 'GET',
                isArray: false
            },
            'getEmailSettings': {
                url: 'api/system/settings/email',
                method: 'GET',
                isArray: true
            },
            'getUserSignupSettings': {
                url: 'api/system/settings/user-signup',
                method: 'GET',
                isArray: true
            },
            'getUserSignupMetadata': {
                url: 'api/signup/metadata',
                method: 'GET',
                isArray: false
            },
            'getSessionSettings': {
                url: 'api/system/settings/session',
                method: 'GET',
                isArray: true
            },
            'getAirformSettings': {
                url: '/api/system/settings?query=category==AiRForm',
                method: 'GET',
                isArray: true
            },
            'getCameraSettings': {
                url: '/api/system/settings?query=category=="Lens Camera"',
                method: 'GET',
                isArray: true
            },
            'getAuthenticationSetupSettings': {
                url: 'api/system/settings/authentication-setup',
                method: 'GET',
                isArray: true
            },
            'getAuthenticationSamlMetadata': {
                url: 'api/system/settings/authentication-sp-metadata',
                method: 'GET',
                isArray: false
            },
            'getAuthenticationConfigSettings': {
                url: 'api/system/settings/authentication-config',
                method: 'GET',
                isArray: true
            },
            'getFeaturesSettings': {
                url: 'api/system/settings/features',
                method: 'GET',
                isArray: true
            },
            'getAccessConfigSettings': {
                url: '/api/system/settings/feature-access-config',
                method: 'GET',
                isArray: true
            },
            'getCompanySettings': {
                url: 'api/system/settings/company',
                method: 'GET',
                isArray: true
            },
            'getAPIKeySettings': {
                url: 'api/system/settings/api-key',
                method: 'GET',
                isArray: true
            },
            'generateAPIKeySettings': {
                url: 'api/system/settings/api-key',
                method: 'PUT',
                isArray: true
            },
            'deactivateAPIKeySettings': {
                url: 'api/system/settings/api-key',
                method: 'DELETE'
            },
            'getWidgetSSOSettings': {
                url: 'api/system/settings/widget-sso',
                method: 'GET',
                isArray: true
            },
            'generateWidgetSSOSettings': {
                url: 'api/system/settings/widget-sso',
                method: 'PUT',
                isArray: true
            },
            'deactivateWidgetSSOSettings': {
                url: 'api/system/settings/widget-sso',
                method: 'DELETE'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getSystemTimezones': {
                method: 'GET',
                isArray: true,
                url: 'api/system/timezones'
            },
            'getSystemLanguages': {
                method: 'GET',
                isArray: true,
                url: 'api/system/languages'
            },
            'getActivityTemplates': {
                method: 'GET',
                isArray: true,
                url: 'api/activityTemplates?query=status==Active&sort=name,asc'
            },
            'getJobPDFSettings': {
                url: '/api/system/settings?query=category=="Job_Report_Configuration"',
                method: 'GET',
                isArray: true
            },
            'getJobFileExtSettings': {
                url: '/api/system/settings?query=category=="Job_FileExtensions_Configuration"',
                method: 'GET',
                isArray: true
            },
            'update': {
                method: 'PUT'
            }
        });
    }
})();
