(function () {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionDetailController', SessionDetailController)

    /* @ngInject */
    function SessionDetailController($rootScope, $mdDialog, $q, $scope, $filter, $location, $timeout, $sce, ParseLinks, $state, pagingParams, paginationConstants, Experience, AlertService, ContentAction, $mdToast, $mdSidenav, Principal, Session, Setting, User, id, sessionType, Feature, Auth, ToastService, PermissionService) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.timezones = [];
        vm.templates = [];
        vm.hrs = [];
        vm.minutes = [0, 15, 30, 45];
        vm.repeats = [];
        vm.possible_recurring_session = ['Daily', 'Weekly', 'Monthly', 'No Fixed Time'];
        vm.timeslots = [];

        $scope.$watch("vm.sessionDetailForm", function () {
            $rootScope.sessionDetailForm = vm.sessionDetailForm;
        });

        // TODO :: check create/edit mode in next ticket
        vm.userHasSchedulerPermission = PermissionService.hasAccess(
            "master_scheduler_session-session"
        );
        vm.host = vm.userHasSchedulerPermission
            ? Principal.getUserInfo()
            : null;

        vm.days = [{
            name: 'Sun',
            isSelected: false
        },
        {
            name: 'Mon',
            isSelected: false
        },
        {
            name: 'Tue',
            isSelected: false
        },
        {
            name: 'Wed',
            isSelected: false
        },
        {
            name: 'Thu',
            isSelected: false
        },
        {
            name: 'Fri',
            isSelected: false
        },
        {
            name: 'Sat',
            isSelected: false
        }
        ];
        vm.weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        vm.frequencyDays = ['First', 'Second', 'Third', 'Fourth', 'Last'];
        vm.AMPM = ['AM', 'PM'];
        vm.sessionSetting = [];
        vm.numOfdays = [];
        vm.video_host = false;
        vm.video_participant = false;
        vm.audio_host = false;
        vm.audio_participant = false;
        vm.personalSessionType = false;
        vm.recurring_session = false;
        vm.daily = false;
        vm.nonEditmode = false;
        vm.sessionType = sessionType;
        vm.personalSessionNumber = null;
        vm.personalSessionId = null;
        vm.recurrsionSessionOn = false;
        vm.enableWaitingRoom = false;
        vm.recurringSessionFeature = Feature.isActive('feature_airsession_recurring_session');
        vm.sessionTemplate = Feature.isActive('feature_airsession_session_template');
        vm.isInsightsLoaded = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.goBackAfterSave = false;
        var alreadyExist = [];

        vm.querySessionActivity = {
            filter: '',
            limit: '10',
            order: '-id',
            page: 1,
            filters: {
                folder: vm.defaultFolder,
                metaType: 'all',
                featured: false,
                status: null,
            }
        };

        vm.permissions = {
            create: 'create-session',
            update: 'update-session',
            delete: 'delete-session',
            updatePersonalSession: 'update-personal-session'
        };

        vm.sessionModel = {
            id: null,
            topic: null,
            agenda: null,
            session_no: null,
            use_passcode: false,
            passcode: null,
            url: null,
            timezone_id: null,
            hrs: 1,
            minutes: 0,
            date: new Date(),
            host_video: false,
            participant_video: false,
            join_before_host: false,
            mute_upon_entry: false,
            record_session: false,
            enable_waiting_room: false,
            sign_in_required: false,
            owner: null,
            sessionType: null,
            created_by: null,
            created_on: null,
            last_modified_by: null,
            last_modified_on: null,
            type: null,
            status: 'OPEN',
            alternate_hosts: [],
            session_users: [],
            guest_users: [],
            templates: [],
            recurring_settings: {
                recurring_type: null,
                repeat: 0,
                end_date: null,
                weekly_occurs: null,
                monthly_occurs: null
            },
            monthlyFreq: null,
            monthly_occurs: {
                type: null,
                day_nth_of_month: 1,
                nth_week_day: 'First',
                day_of_week: 'Monday'
            }
        };

        vm.sessionObject = {
            id: null,
            created_by: null,
            created_on: null,
            last_modified_by: null,
            last_modified_on: null,
            topic: null,
            session_no: null,
            passcode: null,
            url: null,
            duration: null,
            timezone: null,
            start_time: null,
            type: null,
            status: null,
            owner: null,
            settings: {
                host_video: false,
                participant_video: false,
                join_before_host: false,
                mute_upon_entry: false,
                sign_in_required: false,
                enable_waiting_room: false,
                record_session: false
            },
            recurring_settings: {
                recurring_type: null,
                repeat: 0,
                end_date: null,
                weekly_occurs: null,
                monthly_occurs: {
                    type: null,
                    day_nth_of_month: 0,
                    nth_week_day: null,
                    day_of_week: null
                }
            },
            session_users: [],
            guest_users: []
        }

        vm.onSaveSuccess = function (result) {
            setSessionModel(result);
            vm.isSaving = false;
            AlertService.displayToast($mdToast);
            if (vm.goBackAfterSave) {
                vm.goToSessions();
            }
        };

        vm.getHost = function () {
            return vm.host;
        };

        vm.isSchedulerVisible = function () {
            return (
                vm.sessionType != "PERSONAL" &&
                vm.sessionType != "TEMPLATE" &&
                vm.userHasSchedulerPermission
            );
        };

        $scope.isHostUpdated = function (host) {
            vm.host = host;
            var hostAsParticipantIndex =
                vm.sessionModel.session_users &&
                vm.sessionModel.session_users.length
                    ? vm.sessionModel.session_users.findIndex(function (user) {
                          return user.id === host.id;
                      })
                    : -1;
            if (hostAsParticipantIndex != -1) {
                vm.sessionModel.session_users.splice(hostAsParticipantIndex, 1);
            }
        };

        vm.onSaveError = function () {
            vm.isSaving = false;
        };

        vm.isNotInHost = function (result) {
            var dups = [];
            vm.sessionModel.alternate_hosts.forEach(function (item1) {
                result.forEach(function (item2) {
                    if (item1.id == item2.id) {
                        dups.push(item2);
                    }
                });
            });

            dups.forEach(function (item) {
                result.splice(result.indexOf(item), 1);
            });

            return result;
        }

        vm.isNotInParticipant = function (result) {
            var dups = [];
            vm.sessionModel.session_users.forEach(function (item1) {
                result.forEach(function (item2) {
                    if (item2.is_guest) {
                        if (item1.guest_invite_value == item2.guest_invite_value) {
                            dups.push(item2);
                        }
                    } else {
                        if (item1.id == item2.id) {
                            dups.push(item2);
                        } else if ((vm.userHasSchedulerPermission && vm.host.id === item2.id) ||
                            (!vm.userHasSchedulerPermission && vm.sessionModel.owner == item2.id)
                        ) {
                            ToastService.displayToast($filter('triTranslate')('atheer.session.detail.alreadyOwner'), 3000);
                            dups.push(item2);
                        }
                    }
                });
            });

            dups.forEach(function (item) {
                result.splice(result.indexOf(item), 1);
            });

            return result;
        }

        vm.save = function () {
            vm.isSaving = true;
            vm.sessionObject.id = vm.sessionModel.id;
            vm.sessionObject.topic = vm.sessionModel.topic;
            vm.sessionObject.agenda = vm.sessionModel.agenda;

            if (vm.personalSessionType) {
                vm.sessionObject.session_no = vm.personalSessionNumber;
            } else {
                vm.sessionObject.session_no = vm.sessionModel.session_no;
            }

            vm.sessionObject.use_passcode = vm.sessionModel.use_passcode;
            if (vm.sessionModel.use_passcode) {
                vm.sessionObject.passcode = vm.passcode1 + vm.passcode2 + vm.passcode3 + vm.passcode4 + vm.passcode5 + vm.passcode6;
                if (!vm.passcode1 || !vm.passcode2 || !vm.passcode3 || !vm.passcode4 || !vm.passcode5 || !vm.passcode6) {
                    ToastService.displayToast($filter('triTranslate')('atheer.session.detail.invalidPasscode'), 3000);
                    return;
                }
            }

            vm.sessionObject.duration = vm.sessionModel.hrs * 60 + vm.sessionModel.minutes;
            vm.sessionObject.timezone = vm.sessionModel.timezone_id;
            vm.sessionObject.status = vm.sessionModel.status;
            vm.sessionObject.settings.host_video = vm.sessionModel.host_video;
            vm.sessionObject.settings.participant_video = vm.sessionModel.participant_video;
            vm.sessionObject.settings.join_before_host = vm.sessionModel.join_before_host;
            vm.sessionObject.settings.mute_upon_entry = vm.sessionModel.mute_upon_entry;
            vm.sessionObject.settings.sign_in_required = vm.sessionModel.sign_in_required;
            vm.sessionObject.settings.enable_waiting_room = vm.sessionModel.enable_waiting_room;
            vm.sessionObject.settings.record_session = vm.sessionModel.record_session;
            vm.sessionObject.owner = vm.userHasSchedulerPermission ? vm.host.id : vm.sessionModel.owner;
            vm.sessionObject.created_by = vm.sessionModel.created_by;
            vm.sessionObject.created_on = vm.sessionModel.created_on;
            vm.sessionObject.last_modified_by = vm.sessionModel.last_modified_by;
            vm.sessionObject.last_modified_on = vm.sessionModel.last_modified_on;
            var currentUser = Principal.getUserInfo();
            delete vm.sessionObject.scheduler;
            if (vm.userHasSchedulerPermission && currentUser && vm.host && currentUser.id !== vm.host.id) {
                vm.sessionObject.scheduler = {
                    id: currentUser.id,
                    first_name: currentUser.first_name,
                    last_name: currentUser.last_name,
                };
            }
            if (sessionType == 'SCHEDULED') {
                vm.sessionObject.type = 'SCHEDULED';
            } else if (sessionType == 'TEMPLATE') {
                vm.sessionObject.type = 'TEMPLATE';
            } else if (sessionType == 'PERSONAL') {
                vm.sessionObject.type = 'PERSONAL';
            }

            if (vm.sessionModel.session_users != null) {
                vm.sessionObject.guest_users = checkForUpdatedGuestUsers();
                
                if (vm.userHasSchedulerPermission) {
                    // remove extra participant present in sessionObject but not present in sessionModel
                    if (!vm.sessionModel.session_users.length) {
                        vm.sessionObject.session_users = [];
                    } else {
                        vm.sessionObject.session_users.map(function (user) {
                            var userIndex =
                                vm.sessionModel.session_users.findIndex(
                                    function (u) { return user.user_id === u.id; }
                                );
                            if (userIndex === -1) {
                                vm.sessionObject.session_users.splice(userIndex, 1);
                            }
                        });
                    }
                }
                for (var i = 0; i < vm.sessionModel.session_users.length; i++) {
                    var isExist = false;
                    for (var j = 0; j < alreadyExist.length; j++) {
                        if (vm.sessionModel.session_users[i].id == alreadyExist[j]) {
                            isExist = true;
                        } else if (vm.sessionModel.session_users[i].guest_invite_value == alreadyExist[j]) {
                            isExist = true;
                        }
                    }
                    if (!isExist) {
                        if (vm.sessionModel.session_users[i].is_guest) {
                            var obj = {
                                guest_id: vm.sessionModel.session_users[i].guest_invite_value,
                                guest_invite_type: vm.sessionModel.session_users[i].guest_invite_type,
                                guest_language: vm.sessionModel.session_users[i].guest_invite_language
                            }
                            vm.sessionObject.guest_users.push(obj);
                            alreadyExist.push(vm.sessionModel.session_users[i].guest_invite_value);
                        } else {
                            var obj = {
                                user_id: vm.sessionModel.session_users[i].id,
                                role: 'PARTICIPANT'
                            };
                            vm.sessionObject.session_users.push(obj);
                            alreadyExist.push(vm.sessionModel.session_users[i].id);
                        }
                    }
                }
            }

            if (vm.sessionModel.alternate_hosts != null) {
                for (var i = 0; i < vm.sessionModel.alternate_hosts.length; i++) {
                    var obj = {
                        user_id: vm.sessionModel.alternate_hosts[i].id,
                        role: 'HOST'
                    };

                    vm.sessionObject.session_users.push(obj);
                }
            }

            vm.sessionObject.start_time = vm.sessionModel.date;

            if (vm.sessionModel.recurring_settings.recurring_type) {
                vm.sessionObject.recurring_settings.recurring_type = vm.sessionModel.recurring_settings.recurring_type;
                vm.sessionObject.recurring_settings.repeat = vm.sessionModel.recurring_settings.repeat;

                if (vm.sessionModel.recurring_settings.recurring_type == 'Weekly') {
                    var selectedDays = [];
                    angular.forEach(vm.days, function (sDay) {
                        if (sDay.isSelected) {
                            selectedDays.push(sDay.name);
                        }
                    });

                    vm.sessionObject.recurring_settings.weekly_occurs = selectedDays;
                }

                if (vm.sessionModel.recurring_settings.recurring_type == 'Monthly' && vm.sessionModel.monthlyFreq) {
                    if (vm.sessionModel.monthlyFreq == 'monthlyWithNumOfDays') {
                        vm.sessionObject.recurring_settings.monthly_occurs.type = 'monthlyWithNumOfDays';
                        vm.sessionObject.recurring_settings.monthly_occurs.day_nth_of_month = vm.sessionModel.monthly_occurs.day_nth_of_month;
                    }

                    if (vm.sessionModel.monthlyFreq == 'nthDaysFreq') {
                        vm.sessionObject.recurring_settings.monthly_occurs.type = 'nthDaysFreq';
                        vm.sessionObject.recurring_settings.monthly_occurs.nth_week_day = vm.sessionModel.monthly_occurs.nth_week_day;
                        vm.sessionObject.recurring_settings.monthly_occurs.day_of_week = vm.sessionModel.monthly_occurs.day_of_week;
                    }
                }

                if (vm.sessionModel.recurring_settings.end_date) {
                    vm.sessionObject.recurring_settings.end_date = vm.sessionModel.recurring_settings.end_date.getTime() / 1000;
                }

            }

            if (vm.sessionModel.id) {
                vm.goBackAfterSave = false;
                Session.updateScheduled(vm.sessionObject, vm.onSaveSuccess, vm.onSaveError);
            } else {
                vm.goBackAfterSave = true;
                Session.save(vm.sessionObject, vm.onSaveSuccess, vm.onSaveError);
            }
        };

        vm.goToSessions = function () {
            $state.go('storm.sessions.upcoming');
        };

        vm.isNew = function () {
            if (vm.sessionModel == undefined || vm.sessionModel.id == null) {
                return true;
            } else {
                return false;
            }
        };

        vm.filterTemplate = function () {
            if (vm.sessionModel.templates) {
                if (vm.sessionModel.templates.duration) {
                    vm.sessionModel.hrs = parseInt(vm.sessionModel.templates.duration / 60);
                    vm.sessionModel.minutes = vm.sessionModel.templates.duration % 60;
                }

                vm.sessionModel.timezone_id = vm.sessionModel.templates.timezone;
                vm.sessionModel.host_video = vm.sessionModel.templates.settings.host_video;
                vm.sessionModel.participant_video = vm.sessionModel.templates.settings.participant_video;
                vm.sessionModel.join_before_host = vm.sessionModel.templates.settings.join_before_host;
                vm.sessionModel.mute_upon_entry = vm.sessionModel.templates.settings.mute_upon_entry;
                vm.sessionModel.enable_waiting_room = vm.sessionModel.templates.settings.enable_waiting_room;
                vm.sessionModel.record_session = vm.sessionModel.templates.settings.record_session;
            }
        };

        vm.toggleTemplate = function () {
            if (!vm.sessionModel.use_template) {
                vm.sessionModel.templates = null;
            }
        }

        vm.checkPasscode = function (modelVal, code) {
            if (modelVal != '' && modelVal != null && code < 6) {
                $timeout(function () {
                    var nextInput = '[ng-model="vm.passcode' + (code + 1) + '"]';
                    angular.element(nextInput).focus();
                });
            };
        };

        vm.onNumberInputKeydown = function (e) {
            if (
                ![
                    "1", "2", "3", "4", "5", "6", "7","8", "9", "0",
                    "Backspace",
                    "Tab",
                    "ArrowLeft",
                    "ArrowRight",
                    "ArrowUp",
                    "ArrowDown",
                ].includes(e.key)
            ) {
                e.stopPropagation();
                e.preventDefault();
            }
        };

        vm.copyInvitation = function () {
            $mdDialog.show({
                templateUrl: 'modules/session/session-copy-invitation-dialog.tmpl.html',
                controller: 'SessionCopyInvitationDialogController',
                clickOutsideToClose: true,
                controllerAs: 'vm',
                locals: {
                    session: vm.sessionModel
                }
            }).then(function (result) {

            });
        };

        var startPersonalSession = $rootScope.$on('startPersonalSession', function (event) {
            $state.go('storm.sessions.player', {
                'session_no': vm.sessionModel.session_no
            });
        });

        var editPersonalSession = $rootScope.$on('editPersonalSession', function (event) {
        });

        var updatePersonalSession = $rootScope.$on('updatePersonalSession', function (event) {
            vm.save();
        });

        var cancelUpdatePersonalSession = $rootScope.$on('cancelUpdatePersonalSession', function (event) {
        });

        var saveSession = $rootScope.$on('saveSession', function (event) {
            vm.save();
        });

        var saveAsTemplate = $rootScope.$on('saveAsTemplate', function (event) {
            var template = angular.copy(vm.sessionModel);
            template.id = null;
            template.type = "TEMPLATE";
            Session.save(template, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                ToastService.displayToast($filter('translate')('atheer.session.upcomingSessions.templateCreated'), 3000);
            };

            function onSaveError() {
                vm.isSaving = false;
            };
        });

        // Unregister
        $scope.$on('$destroy', function () {
            startPersonalSession();
            editPersonalSession();
            updatePersonalSession();
            saveSession();
            saveAsTemplate();
        });

        function loadData() {
            vm.loadingData = true;
            if (sessionType == 'PERSONAL') {
                $q.all([
                    Setting.getSystemTimezones({
                        size: 100
                    }).$promise,
                    User.getPersonalSession().$promise,
                    Setting.getSessionSettings().$promise
                ])
                    .then(function (results) {
                        vm.timezones = results[0];
                        setSettingSessionModel(results[2]);
                        setSessionModel(results[1]);
                        setDefaultTimeZone(results[1]);
                        vm.personalSessionId = results[1].id;
                        vm.loadingData = false;
                    });
            } else if (sessionType == 'SCHEDULED' || sessionType == 'TEMPLATE') {
                if (id) {
                    $q.all([
                        Setting.getSystemTimezones({
                            size: 100
                        }).$promise,
                        Session.get({
                            id: id
                        }).$promise,
                        User.getPersonalSession().$promise,
                        Session.query({
                            query: getTemplateQueryString()
                        }).$promise
                    ])
                        .then(function (results) {
                            vm.timezones = results[0];
                            vm.sessionModel.timezone_id = vm.timezones[0].timezone_no;
                            vm.personalSessionNumber = results[2].session_no;
                            setSessionModel(results[1]);
                            vm.templates = results[3];
                            vm.loadingData = false;
                        });
                } else {
                    $q.all([
                        Setting.getSystemTimezones({
                            size: 100
                        }).$promise,
                        User.getPersonalSession().$promise,
                        Session.query({
                            query: getTemplateQueryString()
                        }).$promise,
                        Setting.getSessionSettings().$promise
                    ])
                        .then(function (results) {
                            vm.timezones = results[0];
                            setDefaultTimeZone(results[1]);
                            vm.personalSessionNumber = results[1].session_no;
                            setDefaultDate();
                            vm.templates = results[2];
                            setSettingSessionModel(results[3]);
                            generateDefualtPasscode();
                            vm.loadingData = false;
                            setSessionModel(results[1]);                      
                        });
                }
            };

            for (var i = 0; i < 25; i++) {
                vm.hrs.push(i);
            }

            for (var i = 1; i < 15; i++) {
                vm.repeats.push(i);
            }

            for (var i = 1; i <= 31; i++) {
                vm.numOfdays.push(i);
            }

            var x = 30;
            var tt = 0;

            for (var i = 0; tt < 12 * 60; i++) {
                var hh = Math.floor(tt / 60);
                var mm = (tt % 60);
                if (('0' + (hh % 12)).slice(-2) == '00') {
                    vm.timeslots.push('12:' + ('0' + mm).slice(-2));
                } else {
                    vm.timeslots.push(('0' + (hh % 12)).slice(-2) + ':' + ('0' + mm).slice(-2));
                }
                tt = tt + x;
            }
        };

        function setDefaultTimeZone(userSettings) {
            if (userSettings && userSettings.owner_info && userSettings.owner_info.timezone_id) {
                vm.sessionModel.timezone_id = userSettings.owner_info.timezone_id;
            } else {
                //for localhost
                // var workspace = $location.host() == 'localhost' ? 'demo' : $location.host().split('.')[0];
                var workspace = $location.host().split('.')[0];
                Auth.validateWorkspaceName(workspace).then(function (result) {
                    vm.sessionModel.timezone_id = result.data.timezone_id;
                });
            }
        };

        function setSettingSessionModel(settings) {
            angular.forEach(settings, function (setting) {
                if (setting.category == 'session_setting') {
                    vm.sessionSetting = setting;

                    angular.forEach(setting.fields, function (settingField) {
                        if (settingField.name == 'host_video' && settingField.value == 'true') {
                            vm.sessionModel.host_video = true;
                        } else if (settingField.name == 'participant_video' && settingField.value == 'true') {
                            vm.sessionModel.participant_video = true;
                        } else if (settingField.name == 'join_before_host' && settingField.value == 'true') {
                            vm.sessionModel.join_before_host = true;
                        } else if (settingField.name == 'mute_upon_entry' && settingField.value == 'true') {
                            vm.sessionModel.mute_upon_entry = true;
                        } else if (settingField.name == 'sign_in_required' && settingField.value == 'true') {
                            vm.sessionModel.sign_in_required = true;
                        } else if (settingField.name == 'enable_waiting_room' && settingField.value == 'true') {
                            vm.sessionModel.enable_waiting_room = true;
                        } else if (settingField.name == 'record_session' && settingField.value == 'true') {
                            vm.sessionModel.record_session = true;
                        } else if (settingField.name == 'recurring_Session' && settingField.value == 'true') {
                            vm.sessionModel.recurring_Session = true;
                        };
                    });
                }
            });
        }

        function setSessionModel(sessionObj) {
            vm.sessionModel.id = sessionObj.id;
            vm.sessionModel.topic = sessionObj.topic;
            vm.sessionModel.agenda = sessionObj.agenda;
            vm.sessionModel.session_no = sessionObj.session_no;
            vm.sessionModel.use_passcode = sessionObj.use_passcode;

            if (
                angular.isDefined(sessionObj.scheduler) &&
                angular.isDefined(sessionObj.scheduler.id)
            ) {
                vm.host = sessionObj.owner_info;
                vm.sessionModel.scheduler = sessionObj.scheduler;
            }
            if (vm.sessionModel.use_passcode) {
                vm.sessionModel.passcode = sessionObj.passcode;
                vm.passcode1 = sessionObj.passcode.charAt(0);
                vm.passcode2 = sessionObj.passcode.charAt(1);
                vm.passcode3 = sessionObj.passcode.charAt(2);
                vm.passcode4 = sessionObj.passcode.charAt(3);
                vm.passcode5 = sessionObj.passcode.charAt(4);
                vm.passcode6 = sessionObj.passcode.charAt(5);
            } else {
                generateDefualtPasscode();
            }

            if ($location.host().endsWith('atheer.my')) {
                vm.sessionModel.url = 'https://' + $location.host() + ':' + $location.port() + '/sessions/player/' + sessionObj.session_no;
            } else {
                vm.sessionModel.url = 'https://' + $location.host() + '/sessions/player/' + sessionObj.session_no;
            }

            vm.sessionModel.owner = sessionObj.owner;
            vm.sessionModel.created_by = sessionObj.created_by;
            vm.sessionModel.created_on = sessionObj.created_on;
            vm.sessionModel.last_modified_by = sessionObj.last_modified_by;
            vm.sessionModel.last_modified_on = sessionObj.last_modified_on;
            vm.sessionModel.type = sessionObj.type;
            vm.sessionModel.timezone_id = sessionObj.timezone;
            vm.sessionModel.status = sessionObj.status;

            if (sessionObj.session_no == vm.personalSessionNumber) {
                vm.personalSessionType = true;
            }

            if (sessionObj.duration) {
                vm.sessionModel.hrs = parseInt(sessionObj.duration / 60);
                vm.sessionModel.minutes = sessionObj.duration % 60;
            }

            if (sessionObj.start_time) {
                resolveDateAndTime(sessionObj.start_time);
            }

            vm.sessionModel.repeats = vm.repeats;
            vm.sessionModel.days = vm.days;
            vm.sessionModel.numOfdays = vm.numOfdays;
            vm.sessionModel.frequencyDays = vm.frequencyDays;
            vm.sessionModel.weekDays = vm.weekDays;

            if (sessionObj.settings) {
                vm.sessionModel.host_video = sessionObj.settings.host_video;
                vm.sessionModel.participant_video = sessionObj.settings.participant_video;
                vm.sessionModel.join_before_host = sessionObj.settings.join_before_host;
                vm.sessionModel.mute_upon_entry = sessionObj.settings.mute_upon_entry;
                vm.sessionModel.sign_in_required = sessionObj.settings.sign_in_required;
                vm.sessionModel.enable_waiting_room = sessionObj.settings.enable_waiting_room;
                vm.sessionModel.record_session = sessionObj.settings.record_session;
            }

            if (sessionType != 'PERSONAL' && sessionType != 'TEMPLATE') {
                if (sessionObj.session_users) {
                    if (sessionObj.session_users.length != 0) {
                        vm.sessionModel.session_users = [];
                        vm.sessionModel.alternate_hosts = [];
                        resolvePartcipants(sessionObj.session_users);
                    }
                }
                if (sessionObj.guest_users) {
                    if (sessionObj.guest_users.length != 0) {
                        vm.sessionModel.guest_users = [];
                        resolveGuestUsers(sessionObj.guest_users);
                    }
                }
            }

            if (sessionObj.recurring_settings) {
                vm.recurring_session = true;
                vm.sessionModel.recurring_settings.recurring_type = sessionObj.recurring_settings.recurring_type;
                vm.sessionModel.recurring_settings.repeat = sessionObj.recurring_settings.repeat;

                if (sessionObj.recurring_settings.recurring_type == 'Weekly') {
                    angular.forEach(vm.days, function (sDay) {
                        if (sessionObj.recurring_settings.weekly_occurs.includes(sDay.name)) {
                            sDay.isSelected = true;
                        }
                    });
                }

                if (sessionObj.recurring_settings.recurring_type == 'Monthly') {
                    if (sessionObj.recurring_settings.monthly_occurs.type == 'monthlyWithNumOfDays') {
                        vm.sessionModel.monthlyFreq = 'monthlyWithNumOfDays';
                        vm.sessionModel.monthly_occurs.day_nth_of_month = sessionObj.recurring_settings.monthly_occurs.day_nth_of_month;
                    }

                    if (sessionObj.recurring_settings.monthly_occurs.type == 'nthDaysFreq') {
                        vm.sessionModel.monthlyFreq = 'nthDaysFreq';
                        vm.sessionModel.monthly_occurs.nth_week_day = sessionObj.recurring_settings.monthly_occurs.nth_week_day;
                        vm.sessionModel.monthly_occurs.day_of_week = sessionObj.recurring_settings.monthly_occurs.day_of_week;
                    }
                }


                if (sessionObj.recurring_settings.end_date) {
                    vm.sessionModel.recurring_settings.end_date = new Date(sessionObj.recurring_settings.end_date);
                }

            }
        };

        function resolveDateAndTime(session_start_time) {
            vm.sessionModel.date = new Date(session_start_time);
        };

        function resolvePartcipants(session_users) {
            var participantIds = [];
            var hostIds = [];

            angular.forEach(session_users, function (session_user) {
                if (session_user.role == 'PARTICIPANT') {
                    participantIds.push(session_user.user_id);
                } else if (session_user.role == 'HOST') {
                    hostIds.push(session_user.user_id);
                }

            });

            getSessionUsers(participantIds, 'PARTICIPANT');
            getSessionUsers(hostIds, 'HOST');
        };

        function getSessionUsers(user_ids, user_type) {
            if (!user_ids.length) return;
            User.query({
                query: 'id=in=(' + user_ids + ')',
            }, function (response, headers) {
                response.forEach(function (item) {
                    if (user_type == 'PARTICIPANT') {
                        vm.sessionModel.session_users.push(item);
                    } else if (user_type == 'HOST') {
                        vm.sessionModel.alternate_hosts.push(item);
                    }
                })
            });
        }

        function setDefaultDate() {
            vm.sessionModel.date = new Date();

            var hrsValue = vm.sessionModel.date.getHours();

            if (vm.sessionModel.date.getMinutes() > 30) {
                hrsValue = hrsValue + 1;
                if (hrsValue >= 12) {
                    if (hrsValue == 24) vm.sessionModel.ampm = 'AM';
                    else vm.sessionModel.ampm = 'PM';
                    if (hrsValue > 12) {
                        hrsValue = hrsValue - 12;
                    }
                }
                if (hrsValue == 0) hrsValue = 12;
                if (hrsValue < 10) {
                    hrsValue = '0' + hrsValue + ':00';
                } else {
                    hrsValue = hrsValue + ':00'
                }
            } else {
                if (hrsValue >= 12) {
                    vm.sessionModel.ampm = 'PM';
                    hrsValue = hrsValue - 12;
                }
                if (hrsValue == 0) hrsValue = 12;
                if (hrsValue < 10) {
                    hrsValue = '0' + (hrsValue) + ':30';
                } else {
                    hrsValue = (hrsValue) + ':30';
                }
            }
            vm.sessionModel.timeslots = hrsValue;
        };

        function generateDefualtPasscode() {
            var randomString = '';
            var chars = '0123456789';
            for (var i = 6; i > 0; i--) {
                randomString +=
                    chars[Math.floor(Math.random() * chars.length)];
            }

            vm.passcode1 = randomString.charAt(0);
            vm.passcode2 = randomString.charAt(1);
            vm.passcode3 = randomString.charAt(2);
            vm.passcode4 = randomString.charAt(3);
            vm.passcode5 = randomString.charAt(4);
            vm.passcode6 = randomString.charAt(5);
        }

        function getTemplateQueryString() {
            var query = 'type==' + 'TEMPLATE';

            return query;
        };

        function resolveGuestUsers(guest_users) {
            angular.forEach(guest_users, function (guest_user) {
                
                if (guest_user.guest_id) {
                    guest_user.is_guest = true;
                    guest_user.guest_invite_value = guest_user.guest_id;

                    //to avoid duplicates
                    var isExist = false;
                    angular.forEach(alreadyExist, function (ele) {
                        if (guest_user.guest_id == ele) {
                            isExist = true;
                        }
                    });

                    if (!isExist) {
                        vm.sessionModel.session_users.push(guest_user);
                    }
                    
                    if(!!id && isExist) {
                        vm.sessionModel.session_users.push(guest_user);
                    }
                }
            });
        };

        function checkForUpdatedGuestUsers() {
            var updatedGuestUsers = [];
            angular.forEach(vm.sessionModel.session_users, function (sessionUser) {
                angular.forEach(vm.sessionObject.guest_users, function (guestUser) {
                    if (guestUser.guest_id == sessionUser.guest_invite_value) {
                        updatedGuestUsers.push(guestUser);
                    }
                })
            });
            return updatedGuestUsers;
        }
        loadData();
    }
})();
