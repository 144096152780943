(function() {
    'use strict';

    angular
        .module('atheer.conversation')
        .service('ConversationModelService', ConversationModelService);

    /* @ngInject */
    function ConversationModelService($rootScope, $mdPanel, $mdDialog, $filter, $mdToast, PubNubService, Message, AlertService) {

        var initialized = false;
        var conversations = null;
        var sessionSettings;

        var service = {
            init: init,
            destroy: destroy,
            isInitialized: isInitialized,
            getConversations: getConversations,
            deleteMessage: deleteMessage,
            pinMessage: pinMessage,
            unPinMessage: unPinMessage,
            addMessageReaction: addMessageReaction,
            addOrRemoveMessageReaction: addOrRemoveMessageReaction,
            publishNewConversation: publishNewConversation,
            subscribe: subscribe,
            unSubscribe: unSubscribe,
            publishConvMessage: publishConvMessage
        };

        return service;

        function init(conversationsList) {
            conversations = conversationsList;
            initialized = true;
        };

        function destroy() {
            initialized = null;
            conversations = null;
        };

        function isInitialized() {
            return initialized;
        };

        function getConversations() {
            return conversations;
        };

        function deleteMessage(message) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.conversation.deleteMessage'))
                .ariaLabel($filter('translate')('atheer.conversation.deleteMessage'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Message.delete({
                    id: message.id
                }, function() {

                    $rootScope.$broadcast('conversationMessageInternal', {
                        type: 'MESSAGE_DELETED',
                        message: message
                    });

                    publishConvMessage(message.conversation_id, 'MESSAGE_DELETED', message);
                    AlertService.displayToast($mdToast);
                });
            });
        };

        function pinMessage(message) {
            Message.pin({
                id: message.id
            }, function(updatedMessage) {

                transformMessage(message, updatedMessage);

                $rootScope.$broadcast('conversationMessageInternal', {
                    type: 'MESSAGE_UPDATED',
                    message: updatedMessage
                });

                publishConvMessage(updatedMessage.conversation_id, 'MESSAGE_UPDATED', updatedMessage);
            });
        };

        function unPinMessage(message) {
            Message.pin({
                id: message.id
            }, function(updatedMessage) {

                transformMessage(message, updatedMessage);

                $rootScope.$broadcast('conversationMessageInternal', {
                    type: 'MESSAGE_UPDATED',
                    message: updatedMessage
                });

                publishConvMessage(updatedMessage.conversation_id, 'MESSAGE_UPDATED', updatedMessage);
            });
        };

        function addMessageReaction(ev, message) {
            var position = $mdPanel.newPanelPosition()
                .relativeTo('#msg-' + message.id)
                .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.BELOW);

            var config = {
                attachTo: angular.element(document.body),
                controller: 'ConversationMessageReactionPanelController',
                controllerAs: 'vm',
                templateUrl: 'modules/conversation/conversation-message-reaction-panel.tmpl.html',
                position: position,
                openFrom: ev,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: false,
                zIndex: 999,
                onCloseSuccess: function(panelRef, reason) {
                    window.removeEventListener('message', panelRef.listenerFn, false);
                    if (panelRef.emoji) {
                        Message.reaction({
                            id: message.id,
                            type: panelRef.emoji.native,
                            unified: panelRef.emoji.unified
                        }, function(updatedMessage) {

                            transformMessage(message, updatedMessage);

                            $rootScope.$broadcast('conversationMessageInternal', {
                                type: 'MESSAGE_UPDATED',
                                message: updatedMessage
                            });

                            publishConvMessage(updatedMessage.conversation_id, 'MESSAGE_UPDATED', updatedMessage);
                        });
                    }
                }
            };
            $mdPanel.open(config);
        };

        function addOrRemoveMessageReaction(message, reactionType, unified) {
            Message.reaction({
                id: message.id,
                type: reactionType,
                unified: unified
            }, function(updatedMessage) {

                transformMessage(message, updatedMessage);


                $rootScope.$broadcast('conversationMessageInternal', {
                    type: 'MESSAGE_UPDATED',
                    message: updatedMessage
                });

                publishConvMessage(updatedMessage.conversation_id, 'MESSAGE_UPDATED', updatedMessage);
            });
        }

        function publishConvMessage(conversationId, type, message) {
            var channelName = 'conversation-' + conversationId;

            var pubNubMessage = {
                subject: 'CONVERSATION',
                action: 'BROADCAST',
                type: type,
                data: message
            };
            PubNubService.publish(channelName, pubNubMessage);
        };

        function publishNewConversation(userId, conversation, type) {
            if (conversation.users && conversation.users.length > 0) {
                var message = {
                    id: conversation.id
                };

                angular.forEach(conversation.users, function(user) {

                    var channelName = 'user-' + user.user_id;

                    var pubNubMessage = {
                        subject: 'CONVERSATION',
                        action: 'BROADCAST',
                        type: type,
                        data: message
                    };
                    PubNubService.publish(channelName, pubNubMessage);

                });
            }
        };

        function subscribe(conversation) {
            var channelName = 'conversation-' + conversation.id;
            PubNubService.subscribe([channelName], true);
        };

        function unSubscribe(conversation) {
            var channelName = 'conversation-' + conversation.id;
            PubNubService.unsubscribe([channelName]);
        };

        function transformMessage(message, updatedMessage) {
            if(message.type == 'CONTENT') {
                updatedMessage.content = message.content;
            }
        }
    }
})();
