(function() {
    'use strict';

    angular
        .module('atheer.sdk')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, CONTENT_INSIGHT_META) {
        $stateProvider
            .state('storm.admin-airsdk-data-export', {
                url: '/sdk/data-export',
                templateUrl: 'modules/sdk/sdk-data-export.tmpl.html',
                controller: 'SdkDataExportController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.airSdk.dataExport',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                }
            })
            .state('storm.admin-airsdk-api-doc', {
                url: '/sdk/api-reference',
                templateUrl: 'modules/sdk/sdk-doc.tmpl.html',
                controller: 'SdkDocumentationController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.airSdk.apiDoc',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                }
            })
            .state('storm.admin-airsdk-api-key', {
                url: '/sdk/api-key',
                templateUrl: 'modules/sdk/sdk-key.tmpl.html',
                controller: 'SdkKeyController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.airSdk.apiKey',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                }
            })
            .state('storm.admin-airsdk-web-hooks', {
                url: '/sdk/webhooks',
                templateUrl: 'modules/sdk/sdk-webhooks.tmpl.html',
                controller: 'SdkWebHooksController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.webHook.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-airsdk-mobile-sdk', {
                url: '/sdk/mobile-sdk',
                templateUrl: 'modules/sdk/sdk-mobile-sdk.tmpl.html',
                controller: 'SdkMobileSdkController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.airSdk.mobileSdk',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                }
            })
            .state('storm.admin-airsdk-web-hook-detail', {
                url: '/sdk/webhooks/:id',
                templateUrl: 'modules/sdk/sdk-webhook-detail.tmpl.html',
                controller: 'SdkWebHookDetailController as vm',
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                }
            })
            .state('storm.admin-airsdk-web-hook-detail-logs', {
                url: '/sdk/webhooks/:id/logs',
                templateUrl: 'modules/sdk/sdk-webhook-detail-logs.tmpl.html',
                controller: 'SdkWebHookDetailLogsController as vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'created_on,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            });
    }
})();
