(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('SecurityRoleAssignController', SecurityRoleAssignController);

    /* @ngInject */
    function SecurityRoleAssignController($scope, $state, $mdDialog, $q, $filter, $timeout, securityRole, userId, users, Experience, User, SecurityRole, ToastService) {
        var vm = this;

        vm.asyncUsers = users;
        vm.existingUsers = [];
        if (vm.asyncUsers.length != 0) {
            angular.forEach(vm.asyncUsers, function(user) {
                vm.existingUsers.push(user.id);
            });
        }
        vm.asyncSecurityRoles = [];
        vm.securityRole = securityRole;
        vm.fromProfilePage = userId != undefined;
        vm.searchUser = searchUser;

        vm.searchSecurityRole = searchSecurityRole;

        vm.transformChip = function(chip) {
            if (angular.isObject(chip)) {
                return chip;
            }
        }

        function getSecurityRoleQueryString(criteria) {
            if (criteria.length <= 0) {
                return null;
            } else {
                return 'name=re=' + criteria;
            }
        }

        function searchSecurityRole(criteria) {
            var d = $q.defer();
            var result = SecurityRole.query({
                query: getSecurityRoleQueryString(criteria)
            }, function(result) {
                d.resolve(result);
            });
            return d.promise;
        }

        function getQueryString(criteria) {
            if (criteria.length <= 0) {
                return null;
            } else {
                return '(first_name=re="{searchString}",last_name=re="{searchString}",email=re="{searchString}")'.replaceAll("{searchString}", criteria);
            }
        }

        function searchUser(criteria) {
            var d = $q.defer();
            var result = User.query({
                query: getQueryString(criteria)
            }, function(result) {
                d.resolve(result);
            });
            return d.promise;
        }

        vm.close = function() {
            $mdDialog.cancel();
        }

        vm.submit = function() {
            var userSecurityRoles = [];
            if (!vm.fromProfilePage) {
                angular.forEach(vm.asyncUsers, function(user) {
                    userSecurityRoles.push({
                        user_id: user.id,
                        object: vm.securityRole,
                        assigned_on: new Date()
                    });
                });
            } else {
                angular.forEach(vm.asyncSecurityRoles, function(securityRole) {
                    userSecurityRoles.push({
                        user_id: userId,
                        object: securityRole,
                        assigned_on: new Date()
                    });
                });
            };

            Experience.bulkSecurityRole(userSecurityRoles, function(result) {
                ToastService.displayToast($filter('triTranslate')("assignSecurityRole.assignedSuccessfull"), 3000);
                $mdDialog.hide();
            });
        }
    }
})();
