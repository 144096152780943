(function () {
    'use strict';

    angular
        .module('atheer')
        .factory('apiInterceptor', apiInterceptor);

    /* @ngInject */
    function apiInterceptor($rootScope, $q) {
        if(typeof $rootScope.isApiProcessing === 'undefined')
            $rootScope.isApiProcessing = 0;
        return {
              'request': function(config) {
                  if(config.url.startsWith('api') || 
                    config.url.startsWith('/api') || 
                    config.url.startsWith('media') || 
                    config.url.startsWith('/meida')) {
                    $rootScope.isApiProcessing++;
                  }
                return config;
              },
        
              'response': function(response) {
                if(response.config.url.startsWith('api') || 
                    response.config.url.startsWith('/api') || 
                    response.config.url.startsWith('media') || 
                    response.config.url.startsWith('/meida')) {
                    $rootScope.isApiProcessing--;
                  }
                return response;
              },

             'responseError': function(rejection) {
                if($rootScope.isApiProcessing>0)
                    $rootScope.isApiProcessing--;
                return $q.reject(rejection);
              }
            };
    }
})();
