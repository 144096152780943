(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ResetFinishController', ResetFinishController);

    /* @ngInject */
    function ResetFinishController($stateParams, $timeout, $filter, Auth, LoginService, triSettings, ToastService) {
        var vm = this;

        vm.triSettings = triSettings;
        vm.useDarkBackGroundLogos = true;
        vm.keyMissing = angular.isUndefined($stateParams.key);
        vm.confirmPassword = null;
        vm.doNotMatch = null;
        vm.finishReset = finishReset;
        vm.login = LoginService.open;
        vm.resetAccount = {};
        vm.success = null;
        vm.passwordVisible = false;
        vm.confirmPasswordVisible = false;

        vm.togglePasswordVisibility = function() {
            vm.passwordVisible = !vm.passwordVisible;
        };

        vm.toggleConfirmPasswordVisibility = function() {
            vm.confirmPasswordVisible = !vm.confirmPasswordVisible;
        };

        vm.loadData = function() {
            vm.useDarkBackGroundLogos = triSettings.getSkinModel().use_dark_logos;
        }

        function finishReset() {
            Auth.resetPasswordFinish({
                key: $stateParams.key,
                newPassword: vm.password
            }).then(function() {
                vm.success = 'OK';
            }).catch(function() {
                ToastService.displayToast($filter('translate')('atheer.reset.finish.messages.error'), 3000);
                vm.success = null;
            });
        }

        vm.loadData();
    }
})();
